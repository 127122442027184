import {CourseProgress, EMPTY_DATE, successItemStatusses} from "../constants/ektron.js";
import {courseItemURL} from "../constants/routing.js";
export const getCourseProgress = (course, allProgress) => allProgress.find((courseProgress) => courseProgress.courseId === course?.id) ?? null;
export const computeProgress = (course, courseProgress) => {
  if (courseProgress.progressionStatus === CourseProgress.Finished || (courseProgress.finished ?? EMPTY_DATE !== EMPTY_DATE))
    return 1;
  const countedItemIds = new Set();
  let successItemCount = 0;
  for (const {status, courseItemId} of courseProgress?.courseItemRegistrations ?? []) {
    if (!successItemStatusses.includes(status))
      continue;
    if (countedItemIds.has(courseItemId))
      continue;
    countedItemIds.add(courseItemId);
    successItemCount += 1;
  }
  return successItemCount / course.itemCount;
};
export const didStartCourse = (course) => !!course.courseProgress?.startDate || course.userProgress > 0;
export const hasCompletedCourse = (course) => course.courseProgress?.progressionStatus === CourseProgress.Finished || (course.courseProgress?.finished ?? EMPTY_DATE) !== EMPTY_DATE;
export const startItemURL = (course) => courseItemURL(course.slug, course.chapters[0].slug, course.chapters[0].items[0].slug);
export const courseItems = (course) => course.chapters.flatMap((chapter) => chapter.items.map((item) => ({chapter, item})));
export const continueLastItemUrl = (course) => {
  if (!course.courseProgress)
    return startItemURL(course);
  const {chapter, item} = courseItems(course).find(({item: item2}) => !course.courseProgress?.lastOpenedItem || item2.id === course.courseProgress.lastOpenedItem) ?? {};
  return chapter && item ? courseItemURL(course.slug, chapter.slug, item.slug) : "";
};
export const getItemRegistration = (course, item) => course.courseProgress?.courseItemRegistrations.find((courseItemRegistration) => courseItemRegistration.courseItemId === item.id);
