import React from "../../../_snowpack/pkg/react.js";
import {useForm} from "../../../_snowpack/pkg/react-hook-form.js";
import {Button, cssStack, DefaultError, DefaultLoading, Heading, Input} from "../index.js";
import {
  useMutateParticipantAPI,
  useParticipantAPI
} from "../../resources/ockb/index.js";
import {useAlert} from "../../state/alert.js";
export const ParticipantForm = ({userId, submitLabel = "Opslaan", onSuccess}) => {
  const {setAlert} = useAlert();
  const {
    register,
    handleSubmit,
    getValues,
    formState: {errors}
  } = useForm();
  const {data, isError, isLoading} = userId === null ? {
    data: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      day: "",
      month: "",
      year: ""
    },
    isError: false,
    isLoading: false
  } : useParticipantAPI(userId);
  const mutation = useMutateParticipantAPI({
    userId,
    onSuccess: () => {
      setAlert({variant: "success", message: "Wijzigingen opgeslagen", open: true});
      if (onSuccess)
        onSuccess();
    },
    onError: (error) => {
      if (error.includes("already exists")) {
        return setAlert({
          variant: "error",
          message: "Dit e-mailadres is bekend als SeniorWeb-lid, volg de online cursussen via seniorweb.nl",
          open: true
        });
      }
      setAlert({
        variant: "error",
        message: "Er is iets misgegaan, probeer het later opnieuw",
        open: true
      });
    }
  });
  if (isError)
    return /* @__PURE__ */ React.createElement(DefaultError, null);
  if (isLoading || !data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  const onSubmit = async (data2) => {
    await mutation.mutate(data2);
  };
  return /* @__PURE__ */ React.createElement("form", {
    className: cssStack(),
    onSubmit: handleSubmit(onSubmit)
  }, /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Voornaam",
    type: "text",
    required: true,
    ...register("firstName", {required: true}),
    defaultValue: data.firstName
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Tussenvoegsel",
    type: "text",
    ...register("middleName"),
    defaultValue: data.middleName ?? ""
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Achternaam",
    type: "text",
    required: true,
    ...register("lastName", {required: true}),
    defaultValue: data.lastName
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "E-mailadres",
    type: "email",
    required: true,
    ...register("email", {required: true}),
    defaultValue: data.email
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Herhaal e-mailadres",
    type: "email",
    required: true,
    ...register("emailCheck", {
      required: true,
      validate: {
        matchesPreviousEmail: (value) => {
          const {email} = getValues();
          return email === value || "Email adressen komen niet overeen";
        }
      }
    }),
    defaultValue: data.email
  }), errors.emailCheck && /* @__PURE__ */ React.createElement("p", {
    style: {color: "var(--color-error)"}
  }, errors.emailCheck.message), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    level: "5"
  }, "Geboortedatum"), /* @__PURE__ */ React.createElement("div", {
    className: cssStack({horizontal: true})
  }, /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Dag",
    ...register("day", {valueAsNumber: true}),
    pattern: "^(0?[1-9]|[12]\\d|3[01])?$",
    defaultValue: data.day === 0 ? "" : data.day
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Maand",
    ...register("month", {valueAsNumber: true}),
    pattern: "^(0?[1-9]|1[012])?$",
    defaultValue: data.month === 0 ? "" : data.month
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: `Jaar`,
    ...register("year", {valueAsNumber: true}),
    pattern: "^((19|20)\\d{2})?$",
    defaultValue: data.year === 0 ? "" : data.year
  }))), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    primary: true,
    loading: mutation.isLoading
  }, submitLabel), mutation.isError ? /* @__PURE__ */ React.createElement(DefaultError, null) : null, /* @__PURE__ */ React.createElement("i", null, "Velden met een ", /* @__PURE__ */ React.createElement("span", {
    style: {color: "var(--sw-rood)"}
  }, "*"), " zijn verplicht"));
};
