import React, {useEffect} from "../../../_snowpack/pkg/react.js";
import classNames from "../../../_snowpack/pkg/classnames/bind.js";
import {MdCheckCircle, MdInfo, MdOutlineError, MdWarning} from "../../../_snowpack/pkg/react-icons/md.js";
import {cssRule, Text} from "../index.js";
import {useAlert} from "../../state/alert.js";
const wrapper = classNames.bind({
  default: cssRule({
    position: "fixed",
    bottom: "var(--s0)",
    left: "50%",
    transform: "translate(-50%, 0%)",
    display: "flex",
    padding: "var(--s0)",
    gap: "var(--s0)",
    alignItems: "center",
    borderRadius: "var(--s-3)",
    color: "var(--color-light)",
    transition: "transform .3s ease",
    zIndex: 2,
    '[data-open="false"]': {
      transform: "translate(-50%, 130%)"
    }
  }),
  info: cssRule({
    backgroundColor: "var(--color-info)"
  }),
  success: cssRule({
    backgroundColor: "var(--color-success)"
  }),
  warning: cssRule({
    backgroundColor: "var(--color-warning)"
  }),
  error: cssRule({
    backgroundColor: "var(--color-error)"
  })
}, "default");
export const Alert = (props) => {
  const {icon, open, message = "", variant = "info", timeout = 3e3} = props;
  const {setAlert} = useAlert();
  useEffect(() => {
    if (!open || !timeout)
      return;
    const handle = setTimeout(() => setAlert({...props, open: false}), timeout);
    return () => clearTimeout(handle);
  }, [open, timeout]);
  const getIcon = () => {
    if (icon)
      return icon;
    switch (variant) {
      case "success":
        return /* @__PURE__ */ React.createElement(MdCheckCircle, {
          size: "1.5rem"
        });
      case "warning":
        return /* @__PURE__ */ React.createElement(MdWarning, {
          size: "1.5rem"
        });
      case "error":
        return /* @__PURE__ */ React.createElement(MdOutlineError, {
          size: "1.5rem"
        });
      default:
        return /* @__PURE__ */ React.createElement(MdInfo, {
          size: "1.5rem"
        });
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    "data-open": String(open),
    className: wrapper({
      info: variant === "info",
      success: variant === "success",
      warning: variant === "warning",
      error: variant === "error"
    })
  }, getIcon(), /* @__PURE__ */ React.createElement(Text, null, message));
};
