import * as React from "../../../_snowpack/pkg/react.js";
import {MdSwapVert} from "../../../_snowpack/pkg/react-icons/md.js";
import {
  Card,
  CourseListItem,
  NoResultsCard,
  cssRule,
  cssStack,
  DefaultError,
  DefaultLoading,
  Heading,
  MultiSelect,
  Select
} from "../index.js";
import {CourseProgress, sCourseProgress, UserType} from "../../constants/ektron.js";
import {useAllCourses} from "../../hooks/index.js";
import {useContentOnlinecourses} from "../../resources/ockb/index.js";
import {useUserType} from "../../state/index.js";
import {useCourseFilterState} from "../../state/course-filter.js";
import {generateStack} from "../../styling/every-layout/stack.js";
import {fuzzyContains} from "../../utils/search.js";
import {ucFirst} from "../../utils/string.js";
export const CourseList = ({}) => {
  const filter = useCourseFilterState();
  const {data, error} = useContentOnlinecourses();
  const allCourses = useAllCourses();
  const userType = useUserType();
  const courses = React.useMemo(() => {
    if (!data)
      return [];
    return data.courses.filter((course) => {
      if (filter.levels.length === 0)
        return true;
      return filter.levels.includes(course.level);
    }).filter((course) => {
      if (filter.devices.length === 0)
        return true;
      const devices = (course.devices || "").split(/,\s/g);
      return devices.some((device) => filter.devices.includes(device));
    }).filter((course) => {
      if (filter.subjects.length === 0)
        return true;
      const subjects = (course.subjects || "").split(/,\s/g);
      return subjects.some((subject) => filter.subjects.includes(subject));
    }).filter((course) => {
      if (filter.progress.length === 0)
        return true;
      const courseWithInfo = allCourses.find((c) => c.id === course.id);
      return filter.progress.includes(courseWithInfo?.courseProgress?.progressionStatus ?? CourseProgress.NotStarted);
    }).filter((course) => {
      if (filter.text.length === 0)
        return true;
      return fuzzyContains(`${course.title} ${course.content} ${course.devices ?? ""} ${course.subjects ?? ""}`, filter.text);
    }).sort((a, b) => {
      const cmp = (a2, b2) => (filter.sortAscending ? 1 : -1) * (a2 < b2 ? -1 : a2 === b2 ? 0 : 1);
      if (filter.sort === "Titel")
        return cmp(a.title || "", b.title || "");
      if (filter.sort === "Niveau")
        return cmp(a.level || 0, b.level || 0);
      return 0;
    });
  }, [
    data?.courses,
    filter.levels,
    filter.devices,
    filter.subjects,
    filter.progress,
    filter.sort,
    filter.sortAscending,
    filter.text
  ]);
  if (error)
    return /* @__PURE__ */ React.createElement(DefaultError, null);
  if (!data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  const availableFilters = data.filters;
  const onDeviceChange = (checked, value) => checked ? filter.addDevice(value) : filter.removeDevice(value);
  const onSubjectChange = (checked, value) => checked ? filter.addSubject(value) : filter.removeSubject(value);
  const onLevelChange = (checked, value) => checked ? filter.addLevel(parseInt(value, 10)) : filter.removeLevel(parseInt(value, 10));
  const onProgressChange = (checked, value) => checked ? filter.addProgress(parseInt(value, 10)) : filter.removeProgress(parseInt(value, 10));
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: main
  }, /* @__PURE__ */ React.createElement("div", {
    "data--titel-area": true
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "2"
  }, "Alle cursussen")), /* @__PURE__ */ React.createElement("div", {
    "data--sorteer-area": true
  }, "Sorteer op:", /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(MdSwapVert, null), /* @__PURE__ */ React.createElement(Select, {
    title: "Sorteer op",
    options: [
      {
        value: JSON.stringify(["Titel", true]),
        title: "Alfabet (a-z)"
      },
      {
        value: JSON.stringify(["Titel", false]),
        title: "Alfabet (z-a)"
      },
      {
        value: JSON.stringify(["Niveau", true]),
        title: "Niveau (oplopend)"
      },
      {
        value: JSON.stringify(["Niveau", false]),
        title: "Niveau (aflopend)"
      }
    ],
    value: JSON.stringify([filter.sort, filter.sortAscending]),
    onChange: (value) => filter.setSort(...JSON.parse(value))
  }))), /* @__PURE__ */ React.createElement("div", {
    "data--filter-area": true
  }, /* @__PURE__ */ React.createElement(Card, null, /* @__PURE__ */ React.createElement("div", {
    className: cssStack({space: "var(--s4)"})
  }, /* @__PURE__ */ React.createElement(MultiSelect, {
    title: "Apparaat",
    options: availableFilters.devices.filter((device) => !["Overige apparaten"].includes(device)).map((device) => ({
      value: device
    })),
    selection: filter.devices.map((n) => String(n)),
    onChange: onDeviceChange,
    showMoreThreshold: 5
  }), /* @__PURE__ */ React.createElement(MultiSelect, {
    title: "Onderwerp",
    options: availableFilters.subjects.filter((subject) => ![
      "Bank & geldzaken",
      "DigiD & overheid",
      "Domotica",
      "Gezondheid",
      "Onderweg & reizen"
    ].includes(subject)).map((subject) => ({
      value: subject
    })),
    selection: filter.subjects.map((n) => String(n)),
    onChange: onSubjectChange
  }), /* @__PURE__ */ React.createElement(MultiSelect, {
    title: "Niveau",
    options: availableFilters.courseLevels.filter(({label}) => !["Moeilijk"].includes(label)).map(({value, label}) => ({
      value: String(value),
      title: label
    })),
    selection: filter.levels.map((n) => String(n)),
    onChange: onLevelChange
  }), userType === UserType.Guest ? void 0 : /* @__PURE__ */ React.createElement(MultiSelect, {
    title: "Voortgang",
    options: Object.entries(CourseProgress).filter(([, value]) => value > 0).map(([, value]) => ({
      value: String(value),
      title: ucFirst(sCourseProgress(value))
    })),
    selection: filter.progress.map((n) => String(n)),
    onChange: onProgressChange
  })))), /* @__PURE__ */ React.createElement("div", {
    "data--resultaat-area": true
  }, courses.length > 0 ? courses.map((course) => /* @__PURE__ */ React.createElement(CourseListItem, {
    key: course.id,
    horizontal: true,
    source: course
  })) : /* @__PURE__ */ React.createElement(NoResultsCard, {
    body: "Er zijn geen cursussen gevonden met de geselecteerde filters. Probeer de filters anders in te stellen voor een beter resultaat."
  }))));
};
const main = cssRule({
  display: "grid",
  gridTemplateColumns: "calc((100% + var(--sgutter)) / 12 * 3) 1fr",
  gridTemplateRows: "auto 1fr",
  rowGap: "var(--s6)",
  columnGap: "calc((100% + var(--sgutter)) / 12)",
  gridAutoFlow: "row",
  gridTemplateAreas: `
    "titel sorteer"
    "filter resultaat"`,
  " > [data--titel-area]": {
    gridArea: "titel",
    alignSelf: "center"
  },
  " > [data--sorteer-area]": {
    gridArea: "sorteer",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: "var(--s0)",
    color: "var(--ockb-text-light)",
    " > div": {
      display: "flex",
      alignItems: "center",
      background: "var(--sw-wit)",
      padding: "var(--s-1)",
      borderRadius: "var(--s-3)"
    },
    "@media (max-width: 600px)": {
      justifyContent: "flex-start"
    }
  },
  " > [data--filter-area]": {
    gridArea: "filter"
  },
  " > [data--resultaat-area]": {
    gridArea: "resultaat",
    ...generateStack({space: "var(--sgutter)"})
  },
  "@media (max-width: 600px)": {
    gridAutoFlow: "column",
    gridTemplateColumns: "100%",
    gridTemplateAreas: `
      "titel"
      "sorteer"
      "resultaat"`
  }
});
