import * as React from "../../../_snowpack/pkg/react.js";
import {Suspense} from "../../../_snowpack/pkg/react.js";
import {Link} from "../../../_snowpack/pkg/react-router-dom.js";
import {Footer, Header} from "./index.js";
import {cssRule, xdGridLayout} from "../index.js";
import {commonLinks} from "../../constants/footer.js";
import {Heading, Section, Spinner} from "../atoms/index.js";
const mimicHomeLayout = cssRule({
  ...xdGridLayout({
    leftColumnCount: 7,
    leftColumnMinWidth: "calc(var(--measure) * 2 / 3)",
    rightColumnCount: 4
  })
});
const skeleton = cssRule({
  " > div": {
    height: "15em",
    backgroundColor: "var(--color-light)",
    display: "grid",
    placeItems: "center"
  }
});
const bottom = cssRule({
  display: "flex",
  justifyContent: "space-between",
  color: "var(--ockb-text-light)"
});
const commonLinksCSS = cssRule({
  display: "flex",
  gap: "var(--s-1)",
  whiteSpace: "nowrap"
});
const commonLink = cssRule({
  color: "var(--ockb-text-light)",
  textDecoration: "none"
});
export const Layout = ({children}) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Header, null), /* @__PURE__ */ React.createElement(Suspense, {
  fallback: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement("div", {
    className: `${mimicHomeLayout} ${skeleton}`
  }, /* @__PURE__ */ React.createElement("div", {
    className: "shimmer"
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "1",
    className: cssRule({color: "var(--sw-grijs-20)"})
  }, /* @__PURE__ */ React.createElement(Spinner, null))), /* @__PURE__ */ React.createElement("div", {
    className: "shimmer"
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "1",
    className: cssRule({color: "var(--sw-grijs-20)"})
  }, /* @__PURE__ */ React.createElement(Spinner, null))))), /* @__PURE__ */ React.createElement("div", {
    className: "shimmer"
  }, /* @__PURE__ */ React.createElement(Section, {
    color: "light",
    style: {opacity: "0.5", height: "15em"}
  })))
}, children), /* @__PURE__ */ React.createElement("footer", null, /* @__PURE__ */ React.createElement(Suspense, {
  fallback: /* @__PURE__ */ React.createElement(React.Fragment, null)
}, /* @__PURE__ */ React.createElement(Footer, null)), /* @__PURE__ */ React.createElement(Section, {
  color: "light",
  scale: "small",
  style: {padding: "var(--s3)"}
}, /* @__PURE__ */ React.createElement("div", {
  className: bottom
}, /* @__PURE__ */ React.createElement("span", null, "©", new Date().getFullYear(), " SeniorWeb / de Bibliotheek"), /* @__PURE__ */ React.createElement("div", {
  className: commonLinksCSS
}, commonLinks.map(({label, link}) => /* @__PURE__ */ React.createElement(Link, {
  key: label,
  to: link,
  className: commonLink
}, label)))))));
