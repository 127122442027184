import {useHistory} from "../../_snowpack/pkg/react-router.js";
export const useNavigation = () => {
  const history = useHistory();
  return {
    isExternal(url) {
      return !!url.match(/^https?:\/\//i);
    },
    follow(url) {
      this.isExternal(url) ? window.open(url, "_blank") : history.push(url);
    }
  };
};
