import React from "../../../_snowpack/pkg/react.js";
import {cssRule, cssStack, Select} from "../index.js";
const stack = cssStack({});
const faqFilter = cssRule({
  padding: "var(--s2)",
  backgroundColor: "var(--ockb-teal-light)",
  "> p": {
    fontWeight: "bold",
    color: "var(--sw-teal)"
  }
});
const selectField = cssRule({
  backgroundColor: "var(--color-default-bg)",
  border: "1px solid var(--ockb-input-border-color)"
});
export const FAQFilter = ({subjects, selectedSubject, onChange}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: `${stack} ${faqFilter}`
  }, /* @__PURE__ */ React.createElement("p", null, "Ik heb een vraag over:"), /* @__PURE__ */ React.createElement(Select, {
    className: selectField,
    title: selectedSubject,
    options: subjects.map((subject) => ({
      value: subject,
      title: subject
    })),
    value: selectedSubject,
    onChange
  }));
};
