import * as React from "../../../_snowpack/pkg/react.js";
import classNames from "../../../_snowpack/pkg/classnames/bind.js";
import {cssRule} from "../index.js";
const main = cssRule({
  display: "flex",
  alignItems: "center",
  gap: "var(--smin)"
});
const levelCSS = classNames.bind({
  default: cssRule({
    fontSize: "var(--font-size-copy)",
    color: "var(--ockb-text-light)"
  }),
  small: cssRule({
    fontSize: "var(--font-size-copy-s)"
  }),
  large: cssRule({
    fontSize: "var(--font-size-copy-l)"
  })
}, "default");
const Indicator = ({color}) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 24 24",
  height: "1em",
  width: "1em",
  xmlns: "http://www.w3.org/2000/svg"
}, /* @__PURE__ */ React.createElement("rect", {
  stroke: color ?? "#0029447F",
  strokeWidth: 2,
  fill: color ?? "none",
  x: (24 - 2 * 9) / 2,
  y: (24 - 2 * 9) / 2,
  width: 18,
  height: 18,
  rx: 3 / 12 * 18,
  ry: 3 / 12 * 18
}));
export const Level = ({niveau, size = "default"}) => {
  const color = niveau === 1 ? "#8dc73f" : niveau === 2 ? "#ffb400" : niveau === 3 ? "#d72221" : void 0;
  return /* @__PURE__ */ React.createElement("div", {
    className: main
  }, /* @__PURE__ */ React.createElement("span", {
    className: levelCSS({
      small: size === "small",
      large: size === "large"
    })
  }, "Niveau:"), /* @__PURE__ */ React.createElement(Indicator, {
    color: 0 < niveau ? color : void 0
  }), /* @__PURE__ */ React.createElement(Indicator, {
    color: 1 < niveau ? color : void 0
  }), /* @__PURE__ */ React.createElement(Indicator, {
    color: 2 < niveau ? color : void 0
  }));
};
