import React from "../../../_snowpack/pkg/react.js";
import {useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import {Button, CourseListItem, Heading} from "../index.js";
import {UserType} from "../../constants/ektron.js";
import {coursesURL} from "../../constants/routing.js";
import {useAllCourses} from "../../hooks/index.js";
import {useUserType} from "../../state/index.js";
import {cssRule, cssStack} from "../css.js";
import {hasCompletedCourse} from "../../hooks/modelLogic.js";
const stack = cssStack();
const kolommen = cssRule({
  display: "flex",
  gap: "var(--s0)",
  " > *": {
    flex: "1 1 0px",
    minWidth: 0
  },
  "@media (max-width: 600px)": {
    flexDirection: "column"
  }
});
const actions = cssRule({
  display: "flex",
  justifyContent: "center",
  padding: "var(--s7) 0"
});
export const FeaturedCourses = ({}) => {
  const history = useHistory();
  const userType = useUserType();
  const allCourses = useAllCourses();
  const courses = userType === UserType.Participant ? allCourses.filter((course) => course.userProgress != null && !hasCompletedCourse(course)).sort((a, b) => {
    return (a.courseProgress?.lastActive ?? "") > (b.courseProgress?.lastActive ?? "") ? 1 : -1;
  }).slice(0, 3) : allCourses.map((course) => ({r: Math.random(), course})).sort(({r: a}, {r: b}) => a - b).slice(0, 3).map(({course}) => ({...course}));
  return courses.length === 0 ? null : /* @__PURE__ */ React.createElement("div", {
    className: stack
  }, userType === UserType.Participant ? /* @__PURE__ */ React.createElement(Heading, {
    level: "2"
  }, "Verder gaan met deze cursussen") : /* @__PURE__ */ React.createElement(Heading, {
    level: "2"
  }, "Een greep uit onze cursussen"), /* @__PURE__ */ React.createElement("div", {
    className: kolommen
  }, courses.map((featuredCourse) => /* @__PURE__ */ React.createElement(CourseListItem, {
    key: featuredCourse.id,
    source: featuredCourse
  })), courses.length < 2 ? /* @__PURE__ */ React.createElement("div", null) : null, courses.length < 3 ? /* @__PURE__ */ React.createElement("div", null) : null), userType === UserType.Participant ? null : /* @__PURE__ */ React.createElement("div", {
    className: actions
  }, /* @__PURE__ */ React.createElement(Button, {
    inverted: true,
    onClick: () => history.push(coursesURL())
  }, "Bekijk alle cursussen")));
};
