import React from "../../_snowpack/pkg/react.js";
import {ContentModelPage, DefaultError, DefaultLoading} from "../components/index.js";
import {useContentPageData} from "../resources/ockb/index.js";
export const PrivacyPolicyPage = () => {
  const {data, error} = useContentPageData();
  if (error)
    /* @__PURE__ */ React.createElement(DefaultError, null);
  if (!data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  return /* @__PURE__ */ React.createElement(ContentModelPage, {
    model: data.footer.privacyPolicy
  });
};
