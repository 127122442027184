import React from "../../../_snowpack/pkg/react.js";
import {MdChevronRight} from "../../../_snowpack/pkg/react-icons/md.js";
import {useHistory} from "../../../_snowpack/pkg/react-router.js";
import {cssRule, LinkButton} from "../index.js";
const label = cssRule({});
const wrapper = cssRule({
  display: "flex",
  gap: "var(--s-2)",
  color: "var(--ockb-text-light)",
  " *, * > span": {
    fontSize: "var(--font-size-copy-s) !important"
  }
});
export const Breadcrumbs = ({crumbs}) => {
  const history = useHistory();
  if (!crumbs.length)
    return null;
  return /* @__PURE__ */ React.createElement("div", {
    className: wrapper
  }, /* @__PURE__ */ React.createElement("span", {
    className: label
  }, "U bent hier:"), crumbs.map(({name, path}, index) => {
    const isLast = index + 1 === crumbs.length;
    return isLast ? /* @__PURE__ */ React.createElement("span", {
      key: index,
      className: label,
      dangerouslySetInnerHTML: {__html: name}
    }) : /* @__PURE__ */ React.createElement(LinkButton, {
      key: index,
      label: name,
      onClick: () => history.push(path),
      icon: MdChevronRight,
      iconOnRight: true
    });
  }));
};
