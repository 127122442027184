import {QueryClient} from "../../_snowpack/pkg/react-query.js";
import {useHistory} from "../../_snowpack/pkg/react-router-dom.js";
import create from "../../_snowpack/pkg/zustand.js";
import {combine} from "../../_snowpack/pkg/zustand/middleware.js";
import {UserType} from "../constants/ektron.js";
import {rootURL} from "../constants/routing.js";
import {session} from "../resources/ockb/session.js";
export const __PRIVATE__queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
    }
  }
});
export const useAppState = create(combine({
  user: session.user
}, (_set) => ({})));
session.onUser((user) => {
  useAppState.setState({user});
});
export const useUserType = () => {
  const user = useAppState((s) => s.user);
  return user?.userType ?? UserType.Guest;
};
export const useGuardUserType = (userType) => {
  const history = useHistory();
  if (userType !== useUserType())
    history.replace(rootURL());
};
