import React from "../../../_snowpack/pkg/react.js";
import {MdChevronRight} from "../../../_snowpack/pkg/react-icons/md.js";
import {cssRule} from "../index.js";
const navigationItemListCSS = cssRule({
  " > button": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "var(--s1)",
    width: "100%",
    padding: "var(--s5) var(--s1)",
    cursor: "pointer",
    borderTop: "var(--border-thin) solid var(--color-line)",
    fontWeight: "bold",
    ":last-child": {
      borderBottom: "var(--border-thin) solid var(--color-line)"
    },
    ":hover": {
      backgroundColor: "var(--ockb-teal-light)"
    },
    '[data-state="selected"]': {
      backgroundColor: "var(--ockb-teal-light)"
    },
    " > div": {
      display: "flex",
      alignItems: "center",
      gap: "var(--s0)"
    }
  }
});
export const NavigationList = ({navigationItems, onClick, selected}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: navigationItemListCSS
  }, navigationItems.map((navigationItem) => /* @__PURE__ */ React.createElement("button", {
    key: navigationItem.label,
    onClick: () => onClick(navigationItem.tab),
    "data-state": selected === navigationItem.tab ? "selected" : ""
  }, /* @__PURE__ */ React.createElement("div", null, navigationItem.icon, navigationItem.label), /* @__PURE__ */ React.createElement(MdChevronRight, null))));
};
