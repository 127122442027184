export const generateImposter = ({
  margin = "0px",
  fixed,
  breakout
}) => ({
  position: fixed ? "fixed" : "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  ...breakout ? {} : {
    overflow: "auto",
    maxWidth: `calc(100% - (${margin} * 2))`,
    maxHeight: `calc(100% - (${margin} * 2))`
  }
});
