import React, {useEffect, useRef, useState} from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {MdInfo, MdOutlineArrowBack, MdOutlineArrowForward} from "../../_snowpack/pkg/react-icons/md.js";
import {useQueryClient} from "../../_snowpack/pkg/react-query.js";
import {useHistory, useParams} from "../../_snowpack/pkg/react-router.js";
import {
  BannerPrimary,
  Breadcrumbs,
  Button,
  ChapterItem,
  CourseInfoProgress,
  CourseNavigation,
  CourseQuestion,
  cssRule,
  cssSidebar,
  cssStack,
  DefaultError,
  DefaultLoading,
  Level,
  Section
} from "../components/index.js";
import {navigate} from "../components/utils/ScrollToTop.js";
import {SENIORWEB_CDN} from "../constants/assets.js";
import {courseItemURL, coursesURL, courseURL, rootURL} from "../constants/routing.js";
import {useCourse} from "../hooks/index.js";
import {courseItems} from "../hooks/modelLogic.js";
import {useScrollMargin} from "../hooks/useScrollMargin.js";
import {useContentPageData} from "../resources/ockb/index.js";
import {useAlert} from "../state/alert.js";
import {generateImposter} from "../styling/every-layout/imposter.js";
const actions = cssRule({
  display: "flex",
  justifyContent: "space-between"
});
const courseQuestionContainer = cssSidebar({sideWidth: "240px", space: "var(--s7)"});
const whiteLine = cssRule({display: "block", background: "transparant", height: "var(--s2)"});
export const CourseChapterPage = () => {
  const {setAlert} = useAlert();
  const partRef = useRef(null);
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [showQuestionFormResult, setShowQuestionFormResult] = useState("none");
  const handleQuestionFormResult = (result) => {
    setShowQuestionFormResult(result);
    ((hideQuestionForm) => setShowQuestionForm(false))();
  };
  const {courseId, chapterSlug, partSlug} = useParams();
  const course = useCourse(courseId);
  const {data, error} = useContentPageData();
  const history = useHistory();
  {
    const queryClient = useQueryClient();
    React.useEffect(() => () => {
      queryClient.invalidateQueries(["ockb", "onlinecourses", "progress"]);
    }, [course?.id ?? ""]);
  }
  useEffect(() => {
    if (showQuestionFormResult === "success") {
      return setAlert({
        open: true,
        message: "Uw vraag is succesvol verstuurd!",
        variant: "success"
      });
    }
    if (showQuestionFormResult === "error") {
      return setAlert({
        open: true,
        message: "Er is iets misgegaan, probeer het later opnieuw",
        variant: "error"
      });
    }
  }, [showQuestionFormResult]);
  const scrollMargin = useScrollMargin();
  if (error)
    return /* @__PURE__ */ React.createElement(DefaultError, null);
  if (!course || !data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  const crumbs = [
    {name: "Home", path: rootURL()},
    {name: "Cursussen", path: coursesURL()},
    {name: `Cursus: ${course?.title}`, path: `/cursussen/${course?.slug}`}
  ];
  const items = courseItems(course);
  const currentIndex = items.findIndex(({chapter, item}) => chapter.slug === chapterSlug && item.slug === partSlug);
  if (currentIndex === -1)
    return /* @__PURE__ */ React.createElement(React.Fragment, null, "Cursus onderdeel niet gevonden");
  const currentPart = items[currentIndex].item;
  const prevIndex = currentIndex > 0 ? currentIndex - 1 : -1;
  const nextIndex = currentIndex < items.length - 1 ? currentIndex + 1 : -1;
  const handlePreviousPart = () => {
    if (prevIndex < 0)
      return;
    const {chapter, item} = items[prevIndex];
    navigate(courseItemURL(course.slug, chapter.slug, item.slug), partRef.current);
  };
  const handleNextPart = () => {
    if (nextIndex < 0)
      return;
    const {chapter, item} = items[nextIndex];
    navigate(courseItemURL(course.slug, chapter.slug, item.slug), partRef.current);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, currentPart.title.replace(/&#39;/g, "'"), " - ", course.title?.replace(/&#39;/g, "'"), " | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement(Section, {
    color: "light",
    className: cssStack()
  }, /* @__PURE__ */ React.createElement(Level, {
    niveau: course.level,
    size: "large"
  }), /* @__PURE__ */ React.createElement(BannerPrimary, {
    source: {
      image: SENIORWEB_CDN(course.icon ?? ""),
      title: course.title,
      body: course.content
    }
  }, /* @__PURE__ */ React.createElement("div", {
    className: proceedButtonInfo
  }, /* @__PURE__ */ React.createElement(Button, {
    inverted: true,
    onClick: () => history.push(courseURL(course.slug))
  }, /* @__PURE__ */ React.createElement(MdInfo, null), "Cursusinformatie"), /* @__PURE__ */ React.createElement(CourseInfoProgress, {
    course
  })))), /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement("div", {
    ref: partRef,
    style: scrollMargin
  }, /* @__PURE__ */ React.createElement(Breadcrumbs, {
    crumbs
  }))), /* @__PURE__ */ React.createElement(Section, {
    className: sidebar
  }, /* @__PURE__ */ React.createElement(CourseNavigation, {
    course
  }), /* @__PURE__ */ React.createElement(ChapterItem, {
    course,
    part: currentPart,
    toggleQuestionForm: () => {
      setShowQuestionForm(!showQuestionForm);
      setShowQuestionFormResult("none");
    }
  })), showQuestionForm && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Section, {
    color: "light",
    className: courseQuestionContainer
  }, /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement(CourseQuestion, {
    courseId: course.id,
    courseItemId: currentPart.id,
    handleAfterSend: handleQuestionFormResult
  })), /* @__PURE__ */ React.createElement("span", {
    className: whiteLine
  })), /* @__PURE__ */ React.createElement(Section, {
    color: "light",
    className: sidebar
  }, /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement("div", {
    className: actions
  }, prevIndex < 0 ? /* @__PURE__ */ React.createElement("div", null) : /* @__PURE__ */ React.createElement(Button, {
    inverted: true,
    onClick: handlePreviousPart
  }, /* @__PURE__ */ React.createElement(MdOutlineArrowBack, null), "Vorige"), nextIndex < 0 ? /* @__PURE__ */ React.createElement("div", null) : /* @__PURE__ */ React.createElement(Button, {
    onClick: handleNextPart
  }, "Volgend onderdeel: ", nextIndex + 1, " ", /* @__PURE__ */ React.createElement(MdOutlineArrowForward, null)))));
};
const sidebar = cssSidebar({sideWidth: "240px", space: "calc(var(--s7) * 2)"});
const proceedButtonInfo = cssStack({horizontal: true, space: "var(--s5)"}, {
  " > div": {
    display: "flex",
    gap: "var(--s0)",
    color: "var(--ockb-text-light)",
    alignItems: "center",
    " > div": {
      position: "relative",
      " > svg:last-child": {
        ...generateImposter({}),
        color: "var(--sw-teal)"
      },
      lineHeight: 1
    }
  }
});
