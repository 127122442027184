import React from "../../../_snowpack/pkg/react.js";
import {cssRule, cssStack, Heading, Text} from "../index.js";
const wrapper = cssRule({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  columnGap: "var(--s0)",
  rowGap: "var(--s-2)",
  "> :first-child": {
    display: "grid",
    placeItems: "center",
    borderRadius: "50%",
    color: "var(--sw-teal)",
    backgroundColor: "var(--ockb-teal-medium)",
    height: "var(--s5)",
    width: "var(--s5)"
  },
  "> *:last-child": {
    gridColumnStart: 2
  }
});
const InstructionItem = ({step: {number, header, body}}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: wrapper
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "3"
  }, number), /* @__PURE__ */ React.createElement(Heading, {
    level: "3",
    className: header
  }, header), body ? /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: body
  }) : /* @__PURE__ */ React.createElement("div", null));
};
const main = cssStack({
  space: "var(--s6)"
}, {});
export const Instructions = ({steps, title}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: main
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "2"
  }, title), steps.map((step, index) => /* @__PURE__ */ React.createElement(InstructionItem, {
    key: step.header,
    step: {...step, number: index + 1}
  })));
};
