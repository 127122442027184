import React from "../_snowpack/pkg/react.js";
import {BrowserRouter as Router, Redirect, Route, Switch} from "../_snowpack/pkg/react-router-dom.js";
import {Alert, Layout} from "./components/index.js";
import {ExposeHistoryAsASingleton} from "./components/utils/history.js";
import ScrollToTop from "./components/utils/ScrollToTop.js";
import {UserType} from "./constants/ektron.js";
import {
  contactURL,
  cookieNoticeURL,
  coursesURL,
  createParticipantURL,
  disclaimerURL,
  forgotLoginURL,
  locationURL,
  loginURL,
  participantAccountURL,
  participantURL,
  privacyPolicyURL,
  resetPasswordURL,
  rootURL
} from "./constants/routing.js";
import {AccountPage} from "./pages/account.js";
import {ContactPage} from "./pages/contact.js";
import {CookieNoticePage} from "./pages/cookie-notice.js";
import {CoursePage} from "./pages/course.js";
import {CourseChapterPage} from "./pages/course-chapter.js";
import {CoursesPage} from "./pages/courses.js";
import {CreateParticipantPage} from "./pages/create-participant.js";
import {DisclaimerPage} from "./pages/disclaimer.js";
import {ForgotLoginPage} from "./pages/forgot-login.js";
import {HomePage} from "./pages/home.js";
import {LocationPage} from "./pages/location.js";
import {LoginPage} from "./pages/login.js";
import {NotFoundPage} from "./pages/not-found.js";
import {ParticipantsPage} from "./pages/participants.js";
import {ResetPasswordPage} from "./pages/password-reset.js";
import {PrivacyPolicyPage} from "./pages/privacy-policy.js";
import {useAppState} from "./state/index.js";
import {useAlert} from "./state/alert.js";
const routes = [
  {
    exact: true,
    path: rootURL(),
    name: "Home",
    Component: HomePage,
    roles: [UserType.Guest, UserType.Participant, UserType.Library]
  },
  {
    exact: false,
    path: participantAccountURL(),
    name: "Account",
    Component: AccountPage,
    roles: [UserType.Participant]
  },
  {
    exact: false,
    path: contactURL(),
    name: "Contact",
    Component: ContactPage,
    roles: [UserType.Guest, UserType.Participant, UserType.Library]
  },
  {
    exact: true,
    path: coursesURL(),
    name: "Cursussen",
    Component: CoursesPage,
    roles: [UserType.Guest, UserType.Participant]
  },
  {
    exact: true,
    path: `${coursesURL()}/:courseId`,
    name: "Cursus",
    Component: CoursePage,
    roles: [UserType.Guest, UserType.Participant]
  },
  {
    exact: false,
    path: `${coursesURL()}/:courseId/:chapterSlug/:partSlug`,
    name: "Cursus",
    Component: CourseChapterPage,
    roles: [UserType.Participant]
  },
  {
    exact: true,
    path: `${locationURL()}`,
    name: "Locatie",
    Component: LocationPage,
    roles: [UserType.Library]
  },
  {
    exact: true,
    path: `${participantURL()}`,
    name: "Deelnemers",
    Component: ParticipantsPage,
    roles: [UserType.Library]
  },
  {
    exact: false,
    path: createParticipantURL(),
    name: "Deelnemer inschrijven",
    Component: CreateParticipantPage,
    roles: [UserType.Library]
  },
  {
    exact: false,
    path: resetPasswordURL(),
    name: "Wachtwoord resetten",
    Component: ResetPasswordPage,
    roles: [UserType.Guest, UserType.Participant, UserType.Library]
  },
  {
    exact: false,
    path: loginURL(),
    name: "Inloggen",
    Component: LoginPage,
    roles: [UserType.Guest]
  },
  {
    exact: false,
    path: forgotLoginURL(),
    name: "Inloggegevens vergeten",
    Component: ForgotLoginPage,
    roles: [UserType.Guest, UserType.Participant, UserType.Library]
  },
  {
    exact: false,
    path: cookieNoticeURL(),
    name: "Cookies",
    Component: CookieNoticePage,
    roles: [UserType.Guest, UserType.Participant, UserType.Library]
  },
  {
    exact: false,
    path: disclaimerURL(),
    name: "Disclaimer",
    Component: DisclaimerPage,
    roles: [UserType.Guest, UserType.Participant, UserType.Library]
  },
  {
    exact: false,
    path: privacyPolicyURL(),
    name: "Privacybeleid",
    Component: PrivacyPolicyPage,
    roles: [UserType.Guest, UserType.Participant, UserType.Library]
  },
  {
    exact: false,
    path: "*",
    name: "Not Found",
    Component: NotFoundPage,
    roles: [UserType.Guest, UserType.Participant, UserType.Library]
  }
];
export const App = () => {
  const {alert} = useAlert();
  const user = useAppState(({user: user2}) => user2);
  return /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(ExposeHistoryAsASingleton, null), /* @__PURE__ */ React.createElement(ScrollToTop, null), /* @__PURE__ */ React.createElement(Alert, {
    ...alert
  }), /* @__PURE__ */ React.createElement(Layout, null, /* @__PURE__ */ React.createElement(Switch, null, routes.map(({exact, path, Component, roles}, key) => /* @__PURE__ */ React.createElement(Route, {
    key,
    exact,
    path,
    render: (props) => roles.includes(user?.userType ?? UserType.Guest) ? /* @__PURE__ */ React.createElement(Component, {
      ...props
    }) : /* @__PURE__ */ React.createElement(Redirect, {
      to: "/"
    })
  })))));
};
