export const rootURL = () => "/";
export const resetPasswordURL = () => "/wachtwoord-instellen";
export const forgotLoginURL = () => "/inlog-vergeten";
export const loginURL = () => "/inloggen";
export const contactURL = () => "/contact";
export const faqURL = () => `${contactURL()}#veelgestelde-vragen`;
export const coursesURL = () => "/cursussen";
export const courseURL = (slug) => `${coursesURL()}/${slug}`;
export const courseItemURL = (slug, chapterSlug, itemSlug) => `${courseURL(slug)}/${chapterSlug}/${itemSlug}`;
export const locationURL = () => "/locatie";
export const participantURL = () => "/deelnemers";
export const createParticipantURL = () => `${participantURL()}/inschrijven`;
export const participantAccountURL = () => "/mijn-gegevens";
export const cookieNoticeURL = () => "/cookies";
export const disclaimerURL = () => "/disclaimer";
export const privacyPolicyURL = () => "/privacy";
