import React from "../../../_snowpack/pkg/react.js";
import {useForm} from "../../../_snowpack/pkg/react-hook-form.js";
import {Button, cssRule, Heading, Input, TextArea} from "../index.js";
import {errorSend, successSend} from "../../constants/feedbackMessages.js";
import {useMutatePostMailSendSupportQuestion} from "../../resources/ockb/index.js";
import {errorMessage, minMax, required} from "../../utils/formValidation.js";
import {lineBreakToBr} from "../../utils/string.js";
const contactQuestionPopout = cssRule({
  display: "flex",
  flexDirection: "column",
  gap: "var(--s1)"
});
const heading = cssRule({
  fontSize: "var(--font-size-copy)"
});
const form = cssRule({
  display: "flex",
  gap: "var(--s2)",
  flexDirection: "column",
  alignItems: "flex-start"
});
const buttonWrapper = cssRule({
  alignSelf: "flex-end",
  " input": {
    fontSize: "var(--font-size-copy)"
  }
});
export const ContactQuestionPopout = ({input, handleInputChange, handleAfterSend: showFeedback}) => {
  const mutation = useMutatePostMailSendSupportQuestion({
    onSuccess: () => showFeedback(successSend),
    onError: () => showFeedback(errorSend)
  });
  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm();
  const onSubmit = async (data) => {
    const modifiedData = {...data, question: lineBreakToBr(data.question)};
    mutation.mutate(modifiedData);
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: contactQuestionPopout
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "3",
    className: heading
  }, /* @__PURE__ */ React.createElement("p", null, "Stel je vraag")), /* @__PURE__ */ React.createElement("p", null, "Vul je vraag in onderstaand formulier in en we proberen je zo spoedig mogelijk een antwoord te sturen."), /* @__PURE__ */ React.createElement("form", {
    className: form,
    onSubmit: handleSubmit(onSubmit)
  }, /* @__PURE__ */ React.createElement(Input, {
    className: "shortField",
    placeholder: "Naam",
    type: "text",
    required: true,
    value: input.name,
    ...register("name", {
      required: true,
      onChange: (ev) => {
        ev.preventDefault();
        handleInputChange({...input, name: ev.target.value});
      }
    })
  }), errors.name && errors.name.type === "required" && errorMessage(required("naam")), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "E-mailadres",
    type: "email",
    required: true,
    value: input.email,
    ...register("email", {
      required: true,
      onChange: (ev) => {
        handleInputChange({...input, email: ev.target.value});
      }
    })
  }), errors.email && errors.email.type === "required" && errorMessage(required("email-adres")), /* @__PURE__ */ React.createElement(TextArea, {
    placeholder: "Je vraag",
    required: true,
    value: input.question,
    ...register("question", {
      required: true,
      minLength: 10,
      maxLength: 1e3,
      onChange: (ev) => handleInputChange({...input, question: ev.target.value})
    })
  }), errors.question && /* @__PURE__ */ React.createElement("span", null, errors.question.type === "required" && errorMessage(required("vraag")), errors.question.type === "minLength" && errorMessage(minMax("min", 10)), errors.question.type === "maxLength" && errorMessage(minMax("max", 1e3))), /* @__PURE__ */ React.createElement("span", {
    className: buttonWrapper
  }, /* @__PURE__ */ React.createElement(Button, {
    primary: true,
    inverted: mutation.isLoading,
    loading: mutation.isLoading,
    disabled: mutation.isLoading,
    type: "submit"
  }, "Versturen"))));
};
