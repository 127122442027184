import React, {useEffect} from "../../../../_snowpack/pkg/react.js";
import {cssRule, cssStack} from "../../index.js";
import {ItemStatus, ItemType} from "../../../constants/ektron.js";
import {getItemRegistration} from "../../../hooks/modelLogic.js";
import {useUpdateOnlinecourseProgress} from "../../../resources/ockb/index.js";
import {useAlert} from "../../../state/alert.js";
import {ChapterItemExam} from "./Exam.js";
import {ChapterItemLesson} from "./Lesson.js";
export const ChapterItem = ({course, part, toggleQuestionForm}) => {
  const {setAlert} = useAlert();
  const mutation = useUpdateOnlinecourseProgress({
    onError: () => setAlert({
      open: true,
      variant: "error",
      message: "Er is iets fout gegaan bij het opslaan van je voortgang."
    })
  });
  const itemStatus = getItemRegistration(course, part)?.status ?? ItemStatus.NotSet;
  const setItemStatus = (status, data = {
    questionsCorrect: 0,
    questionsFalse: 0
  }) => mutation.mutate({
    courseId: course.id,
    courseItemId: part.id,
    status,
    ...data
  });
  useEffect(() => updateProgress({type: "shown"}), [part]);
  const updateProgress = (action) => {
    switch (part.type) {
      case ItemType.Exam:
        switch (itemStatus) {
          case ItemStatus.NotSet:
            if (action.type === "shown")
              setItemStatus(ItemStatus.ExamNotStarted);
            break;
          case ItemStatus.ExamNotStarted:
            if (action.type === "fail")
              setItemStatus(ItemStatus.ExamFailed, action.data);
            if (action.type === "done")
              setItemStatus(ItemStatus.ExamFinished, action.data);
            break;
          case ItemStatus.ExamFinished:
            if (action.type === "reset")
              setItemStatus(ItemStatus.ExamNotStarted);
            break;
          case ItemStatus.ExamFailed:
            if (action.type === "reset")
              setItemStatus(ItemStatus.ExamNotStarted);
            break;
        }
        break;
      case ItemType.SubExam:
        switch (itemStatus) {
          case ItemStatus.NotSet:
            if (action.type === "shown")
              setItemStatus(ItemStatus.SubExamNotStarted);
            break;
          case ItemStatus.SubExamNotStarted:
            if (action.type === "fail")
              setItemStatus(ItemStatus.SubExamFailed, action.data);
            if (action.type === "done")
              setItemStatus(ItemStatus.SubExamFinished, action.data);
            break;
          case ItemStatus.SubExamFinished:
            if (action.type === "reset")
              setItemStatus(ItemStatus.SubExamNotStarted);
            break;
          case ItemStatus.SubExamFailed:
            if (action.type === "reset")
              setItemStatus(ItemStatus.SubExamNotStarted);
            break;
        }
        break;
      default:
        switch (itemStatus) {
          case ItemStatus.NotSet:
            if (action.type === "shown")
              setItemStatus(ItemStatus.ContentOpened);
            break;
          case ItemStatus.ContentOpened:
            if (action.type === "done")
              setItemStatus(ItemStatus.ContentFinished);
            break;
          case ItemStatus.ContentFinished:
            if (action.type === "reset")
              setItemStatus(ItemStatus.ContentOpened);
            break;
        }
        break;
    }
  };
  if (!part)
    return /* @__PURE__ */ React.createElement(React.Fragment, null, "Geen onderdeel geselecteerd");
  return /* @__PURE__ */ React.createElement("div", {
    className: cssStack({}, {})
  }, /* @__PURE__ */ React.createElement("span", {
    className: progressText
  }, /* @__PURE__ */ React.createElement("b", {
    className: highlighted
  }, "Onderdeel ", part.itemNumber), " van ", course?.itemCount ?? 0), part.type === ItemType.SubExam || part.type === ItemType.Exam ? /* @__PURE__ */ React.createElement(ChapterItemExam, {
    part,
    toggleQuestionForm,
    notifier: updateProgress
  }) : /* @__PURE__ */ React.createElement(ChapterItemLesson, {
    item: part,
    status: itemStatus,
    toggleQuestionForm,
    notifier: updateProgress
  }));
};
const progressText = cssRule({
  color: "var(--ockb-text-light)"
});
const highlighted = cssRule({
  color: "var(--sw-teal)"
});
