import React, {useState} from "../../../_snowpack/pkg/react.js";
import {useForm} from "../../../_snowpack/pkg/react-hook-form.js";
import {MdVisibility, MdVisibilityOff} from "../../../_snowpack/pkg/react-icons/md.js";
import {Button, cssRule, cssStack, Heading, Input, LinkButton, Text} from "../index.js";
import {useContentPageData, useMutateLoginDataAPI} from "../../resources/ockb/index.js";
import {useAlert} from "../../state/alert.js";
export const LoginDataForm = ({email}) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: {errors}
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const {setAlert} = useAlert();
  const mutation = useMutateLoginDataAPI({
    onSuccess: () => setAlert({open: true, variant: "success", message: "Wachtwoord aangepast"}),
    onError: () => setAlert({
      open: true,
      variant: "error",
      message: "Er is iets misgegaan, probeer het later opnieuw."
    })
  });
  const {data: contentPage} = useContentPageData();
  const onSubmit = async ({currentPassword, newPassword}) => {
    await mutation.mutate({currentPassword, newPassword});
  };
  return /* @__PURE__ */ React.createElement("form", {
    className: cssStack({space: "var(--s4)"}),
    onSubmit: handleSubmit(onSubmit)
  }, email ? /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    level: "5",
    className: label
  }, "Inlognaam"), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "E-mailadres",
    type: "email",
    value: email,
    disabled: true
  })) : null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: wachtwoord
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "5"
  }, "Wachtwoord"), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: showPassword ? MdVisibilityOff : MdVisibility,
    color: "primary",
    type: "button",
    label: showPassword ? "Verberg wachtwoorden" : "Toon wachtwoorden",
    onClick: () => setShowPassword(!showPassword)
  })), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Huidig wachtwoord",
    type: showPassword ? "text" : "password",
    minLength: 8,
    required: true,
    ...register("currentPassword", {required: true})
  })), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Nieuw wachtwoord",
    type: showPassword ? "text" : "password",
    minLength: 8,
    required: true,
    pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,999}$",
    ...register("newPassword", {required: true})
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Herhaal nieuw wachtwoord",
    type: showPassword ? "text" : "password",
    minLength: 8,
    required: true,
    ...register("newPasswordRepeated", {
      required: true,
      validate: {
        matchesPreviousPassword: (value) => {
          const {newPassword} = getValues();
          return value === newPassword || "Wachtwoorden komen niet met elkaar overeen";
        }
      }
    })
  }), errors.newPasswordRepeated && /* @__PURE__ */ React.createElement("p", {
    style: {color: "var(--color-error)"}
  }, errors.newPasswordRepeated.message), /* @__PURE__ */ React.createElement("div", {
    className: passwordDescription
  }, contentPage && /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: contentPage.profile.credentialsRequirements
  })), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    primary: true,
    loading: mutation.isLoading
  }, "Opslaan"), /* @__PURE__ */ React.createElement("i", null, "Velden met een ", /* @__PURE__ */ React.createElement("span", {
    style: {color: "var(--sw-rood)"}
  }, "*"), " zijn verplicht"));
};
const label = cssRule({
  marginBottom: "var(--s-3)"
});
const wachtwoord = cssRule({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "var(--s-3)"
});
const passwordDescription = cssRule({
  color: "var(--ockb-text-light)",
  fontSize: "var(--font-size-copy-s)",
  listStylePosition: "inside"
});
