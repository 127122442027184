import {useMutation, useQuery, useQueryClient} from "../../../_snowpack/pkg/react-query.js";
import {API_BASE_URL, CourseProgress, CURSUS_BLACKLIST} from "../../constants/ektron.js";
import {useAppState, useUserType} from "../../state/index.js";
import {session} from "./session.js";
export {};
export {};
export {};
export {};
export {};
export {};
export {};
export const __PRIVATE__API = {
  GET_ockb_content_pagedataPath: () => `${API_BASE_URL}/content/pagedata`,
  GET_ockb_content_onlinecoursesPath: () => `${API_BASE_URL}/content/onlinecourses`,
  GET_ockb_content_faqPath: () => `${API_BASE_URL}/content/faq`,
  GET_ockb_content_onlinecoursePath: (courseId) => `${API_BASE_URL}/content/onlinecourses/${courseId}`,
  GET_ockb_progressPath: (userId) => `${API_BASE_URL}/progress/${userId}`,
  PUT_ockb_progressPath: (courseId, courseItemId) => `${API_BASE_URL}/progress/${courseId}/${courseItemId}`,
  GET_ockb_progress_send_certificatePath: (courseId) => `${API_BASE_URL}/progress/send-certificate/${courseId}`,
  DELETE_ockb_progressPath: (courseId) => `${API_BASE_URL}/progress/${courseId}`,
  GET_ockb_resource_libraryPath: (userId) => `${API_BASE_URL}/resource/libraries/${userId}`,
  GET_ockb_resource_participantsPath: () => `${API_BASE_URL}/resource/participants`,
  GET_ockb_resource_participantPath: (userId) => `${API_BASE_URL}/resource/participants/${userId}`,
  PUT_ockb_resource_participantPath: (userId) => __PRIVATE__API.GET_ockb_resource_participantPath(userId),
  POST_ockb_resource_participantPath: () => `${API_BASE_URL}/resource/participants/create`,
  DELETE_ockb_resource_participantPath: (userId) => `${API_BASE_URL}/resource/participants/${userId}`,
  GET_ockb_authentication_updatecredentials: (key) => `${API_BASE_URL}/authentication/updatecredentials?key=${key}`,
  PUT_ockb_authentication_updatecredentials: () => `${API_BASE_URL}/authentication/updatecredentials`,
  POST_ockb_authentication_forgotcredentials: (email) => `${API_BASE_URL}/authentication/forgotcredentials?email=${email}`,
  POST_ockb_authentication_resetPassword: () => `${API_BASE_URL}/authentication/updatecredentials`,
  POST_ockb_mail_sendsupportquestion: () => `${API_BASE_URL}/mail/sendsupportquestion`
};
const API = __PRIVATE__API;
export const useContentPageData = () => {
  const user = useAppState(({user: user2}) => user2);
  return useQuery(["ockb", "content", "pagedata", user?.id], async () => {
    return session.fetch(API.GET_ockb_content_pagedataPath());
  }, {suspense: true});
};
export function useContentOnlinecourse(courseId) {
  return useQuery(["ockb", "content", "onlinecourses", courseId, useUserType()], () => courseId ? session.fetch(API.GET_ockb_content_onlinecoursePath(courseId)) : null);
}
export const useContentOnlinecourses = () => {
  return useQuery(["ockb", "content", "onlinecourses"], async () => {
    const response = await session.fetch(API.GET_ockb_content_onlinecoursesPath());
    return {
      ...response,
      courses: response.courses.filter((course) => !CURSUS_BLACKLIST.includes(course.id))
    };
  });
};
const onlineCoursesKey = (id) => ["ockb", "onlinecourses", "progress", id];
export const useOnlinecoursesProgress = () => {
  const user = useAppState(({user: user2}) => user2);
  return useQuery(onlineCoursesKey(user?.id), () => user == null ? [] : session.fetch(API.GET_ockb_progressPath(user.id)));
};
const updateOnlinecourseProgress = async ({
  courseId,
  courseItemId,
  ...data
}) => {
  await session.fetch(API.PUT_ockb_progressPath(courseId, courseItemId), {
    method: "PUT",
    body: JSON.stringify(data)
  });
};
export const useUpdateOnlinecourseProgress = ({onError}) => {
  const queryClient = useQueryClient();
  const user = useAppState(({user: user2}) => user2);
  return useMutation(updateOnlinecourseProgress, {
    onError,
    onMutate: async ({courseId, courseItemId, ...data}) => {
      const key = onlineCoursesKey(user?.id);
      await queryClient.cancelQueries(key);
      let progress = queryClient.getQueryData(key) ?? [];
      progress = JSON.parse(JSON.stringify(progress));
      const courseProgress = progress.find((pItem) => pItem.courseId === courseId) || (progress[progress.length] = {
        courseId,
        courseItemRegistrations: [],
        hasDownloadedCertificate: false,
        id: "",
        lastActive: new Date().toISOString(),
        lastOpenedItem: 0,
        name: "",
        progressionStatus: CourseProgress.Started,
        startDate: new Date().toISOString(),
        totalItemRegistrations: 0
      });
      const registration = courseProgress.courseItemRegistrations.find((courseItemRegistration) => courseItemRegistration.courseItemId === courseItemId);
      if (registration) {
        Object.assign(registration, data);
      } else {
        courseProgress.courseItemRegistrations.push({
          courseItemId,
          ...data
        });
      }
      courseProgress.lastOpenedItem = courseItemId;
      courseProgress.totalItemRegistrations = courseProgress.courseItemRegistrations.length;
      queryClient.setQueryData(key, () => progress);
      return;
    },
    onSettled: async (_data, _error, {courseId}) => {
      const key = onlineCoursesKey(user?.id);
      const progress = queryClient.getQueryData(key) ?? [];
      if (!progress.find((pItem) => pItem.courseId === courseId)?.id) {
        queryClient.invalidateQueries(key);
      }
    }
  });
};
export const useContentFaq = () => {
  return useQuery(["ockb", "content", "faq"], () => session.fetch(API.GET_ockb_content_faqPath()));
};
export const useResourceLibrary = () => {
  const user = useAppState(({user: user2}) => user2);
  const url = API.GET_ockb_resource_libraryPath(user?.id ?? "");
  return useQuery(["ockb", "resource", "library", user?.id], () => session.fetch(url), {
    enabled: !!user?.id
  });
};
export const useMutateResourceLibrary = ({
  onSuccess,
  onError
}) => {
  const user = useAppState(({user: user2}) => user2);
  const url = API.GET_ockb_resource_libraryPath(user?.id ?? "");
  const queryClient = useQueryClient();
  return useMutation(async (data) => {
    await session.fetch(url, {
      method: "PUT",
      body: JSON.stringify(data)
    });
    queryClient.invalidateQueries(["ockb", "resource", "library"]);
    queryClient.invalidateQueries(["ockb", "content", "pagedata"]);
  }, {onSuccess, onError});
};
export const useParticipantsAPI = () => {
  const user = useAppState(({user: user2}) => user2);
  return useQuery(["ockb", "resource", "participants", user?.id], () => session.fetch(API.GET_ockb_resource_participantsPath()));
};
export const useParticipantAPI = (userId) => {
  const user = useAppState(({user: user2}) => user2);
  const id = userId ?? user.id;
  const url = API.GET_ockb_resource_participantPath(id);
  return useQuery(["ockb", "resource", "participant", id], () => session.fetch(url));
};
export const useMutateParticipantAPI = ({
  userId,
  onSuccess,
  onError
}) => {
  const {user} = useAppState(({user: user2}) => ({user: user2}));
  const queryClient = useQueryClient();
  return useMutation(async (data) => session.fetch(userId ? API.PUT_ockb_resource_participantPath(userId) : API.POST_ockb_resource_participantPath(), {
    method: userId ? "PUT" : "POST",
    body: JSON.stringify({
      ...data,
      ...{day: data.day || 0, month: data.month || 0, year: data.year || 0},
      ...userId ? {id: userId} : {libraryId: user.id}
    })
  }), {
    onSuccess,
    onError,
    onSettled: () => {
      if (user?.id === userId)
        session.forceRefresh();
      queryClient.invalidateQueries(["ockb", "resource", "participant", userId]);
      queryClient.invalidateQueries(["ockb", "resource", "participants", user?.id]);
    }
  });
};
export const useUpdateCredentialsAPI = (key) => {
  const url = API.GET_ockb_authentication_updatecredentials(key);
  return useQuery(["ockb", "authentication", "updatecredentials", key], () => session.fetch(url));
};
export const useMutateLoginDataAPI = ({
  onError,
  onSuccess
}) => {
  return useMutation(async (data) => {
    await session.fetch(API.PUT_ockb_authentication_updatecredentials(), {
      method: "PUT",
      body: JSON.stringify(data)
    });
  }, {onError, onSuccess});
};
export const useMutateDeleteParticipantAPI = ({
  userId,
  onSuccess,
  onError
}) => {
  const queryClient = useQueryClient();
  const user = useAppState(({user: user2}) => user2);
  return useMutation(async () => {
    if (!user)
      throw new Error("no user object");
    await session.fetch(API.DELETE_ockb_resource_participantPath(userId ?? user.id), {
      method: "DELETE"
    });
  }, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(["ockb", "resource", "participants"]);
    }
  });
};
const deleteOnlinecourseProgress = async (courseId) => {
  return await session.fetch(API.DELETE_ockb_progressPath(courseId), {
    method: "DELETE"
  });
};
export const useDeleteOnlinecourseProgressAPI = ({
  onSuccess,
  onError
} = {}) => {
  const queryClient = useQueryClient();
  const user = useAppState(({user: user2}) => user2);
  const key = onlineCoursesKey(user?.id);
  return useMutation(async (courseId) => {
    await deleteOnlinecourseProgress(courseId);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
      onSuccess?.();
    },
    onError
  });
};
const postMailSendSupportQuestion = async (data) => session.fetch(API.POST_ockb_mail_sendsupportquestion(), {
  method: "POST",
  headers: {"Content-Type": "application/json"},
  body: JSON.stringify(data)
});
export const useMutatePostMailSendSupportQuestion = ({
  onSuccess,
  onError
} = {}) => {
  return useMutation(async (data) => postMailSendSupportQuestion(data), {
    onSuccess,
    onError
  });
};
export const postMailSendCourseQuestion = async (data) => {
  const response = await session.fetch(`${API_BASE_URL}/mail/sendcourseitemquestion`, {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(data)
  });
  return response;
};
const sendCertificateOnlinecourseProgress = async (courseId) => session.fetch(API.GET_ockb_progress_send_certificatePath(courseId));
export const useMutateSendCertificateOnlinecourseProgress = ({
  onSuccess,
  onError
} = {}) => {
  return useMutation(async (courseId) => sendCertificateOnlinecourseProgress(courseId), {
    onSuccess,
    onError
  });
};
const forgotCredentials = async (email) => await session.fetch(API.POST_ockb_authentication_forgotcredentials(email), {
  method: "POST",
  headers: {"Content-Type": "application/json"}
});
export const useMutateForgotCredentials = ({
  onSuccess,
  onError
} = {}) => {
  return useMutation(async (email) => forgotCredentials(email), {
    onSuccess,
    onError
  });
};
export const resetPassword = async ({
  newPassword,
  passwordForgetKey
}) => {
  await session.fetch(API.POST_ockb_authentication_resetPassword(), {
    method: "PUT",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({newPassword, passwordForgetKey})
  });
};
