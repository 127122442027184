import React, {useEffect} from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {useForm, useFormState} from "../../_snowpack/pkg/react-hook-form.js";
import {MdChevronRight} from "../../_snowpack/pkg/react-icons/md.js";
import {useHistory, useLocation} from "../../_snowpack/pkg/react-router.js";
import {AuthLayout, Button, Checkbox, cssRule, Input, LinkButton} from "../components/index.js";
import {INVALID_CREDENTIALS} from "../constants/errors.js";
import {rootURL} from "../constants/routing.js";
import {session} from "../resources/ockb/session.js";
import {useAlert} from "../state/alert.js";
import {generateCenter} from "../styling/every-layout/center.js";
import {generateStack} from "../styling/every-layout/stack.js";
const wrapper = cssRule({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "var(--ockb-teal-light)",
  paddingTop: "calc(var(--s5) * 2)",
  paddingBottom: "calc(var(--s5) * 2)",
  "@media (max-width: 600px)": {
    paddingLeft: "var(--s0)",
    paddingRight: "var(--s0)"
  },
  "> [data--card]": {
    maxWidth: "410px",
    width: "100%",
    "> form": {
      ...generateStack({}),
      "> *:last-child": {
        ...generateCenter({}),
        ...generateStack({})
      }
    }
  }
});
const displayRight = cssRule({
  display: "flex",
  justifyContent: "flex-end"
});
export const LoginPage = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const passwordResetSuccess = new URLSearchParams(location.search).get("passwordResetSuccess");
  const {setAlert} = useAlert();
  const {control, register, handleSubmit} = useForm({});
  const {isSubmitting: waiting} = useFormState({control});
  useEffect(() => {
    if (!passwordResetSuccess)
      return;
    if (passwordResetSuccess === "true") {
      return setAlert({open: true, message: "Je wachtwoord is gewijzigd", variant: "success"});
    }
  }, [passwordResetSuccess]);
  const onSubmit = async (loginData) => {
    try {
      await session.login(loginData.email, loginData.password);
      history.replace(rootURL());
    } catch (error) {
      const errorMessage = error.message;
      if (errorMessage === INVALID_CREDENTIALS) {
        return setAlert({
          open: true,
          message: "De ingevulde gegevens zijn onjuist",
          variant: "error"
        });
      }
      setAlert({open: true, message: "Er is een onbekende fout opgetreden", variant: "error"});
    }
  };
  return /* @__PURE__ */ React.createElement(AuthLayout, null, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, "Inloggen | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement("div", {
    className: wrapper
  }, /* @__PURE__ */ React.createElement("div", {
    "data--card": true
  }, /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleSubmit(onSubmit)
  }, /* @__PURE__ */ React.createElement("h2", null, "Inloggen"), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "E-mailadres",
    type: "email",
    required: true,
    ...register("email", {required: true})
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Wachtwoord",
    type: showPassword ? "text" : "password",
    required: true,
    ...register("password", {required: true})
  }), /* @__PURE__ */ React.createElement("div", {
    className: displayRight
  }, /* @__PURE__ */ React.createElement(LinkButton, {
    label: "Inloggegevens vergeten?",
    color: "primary",
    type: "button",
    onClick: () => history.push("/inlog-vergeten")
  })), /* @__PURE__ */ React.createElement(Checkbox, {
    label: "Toon wachtwoord",
    onChange: setShowPassword
  }), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    primary: true,
    loading: waiting
  }, "Inloggen"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(LinkButton, {
    label: "Hulp nodig bij inloggen?",
    color: "primary",
    icon: MdChevronRight,
    onClick: () => history.push("/contact?faq=Waarom kan ik niet inloggen?")
  }), /* @__PURE__ */ React.createElement("i", null, "Velden met een ", /* @__PURE__ */ React.createElement("span", {
    style: {color: "var(--sw-rood)"}
  }, "*"), " zijn verplicht")))))));
};
