import React from "../../_snowpack/pkg/react.js";
import {useForm} from "../../_snowpack/pkg/react-hook-form.js";
import {MdChevronLeft} from "../../_snowpack/pkg/react-icons/md.js";
import {useHistory} from "../../_snowpack/pkg/react-router.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {AuthLayout, Button, cssRule, cssStack, Input, LinkButton} from "../components/index.js";
import {USER_DOES_NOT_EXIST} from "../constants/errors.js";
import {useMutateForgotCredentials} from "../resources/ockb/index.js";
import {useAlert} from "../state/alert.js";
const wrapper = cssRule({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "var(--ockb-teal-light)",
  paddingTop: "calc(var(--s5) * 2)",
  paddingBottom: "calc(var(--s5) * 2)",
  "@media (max-width: 600px)": {
    paddingLeft: "var(--s0)",
    paddingRight: "var(--s0)"
  }
});
const card = cssRule({
  maxWidth: "410px",
  width: "100%"
});
const displayCenter = cssRule({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "var(--s3)"
});
export const ForgotLoginPage = () => {
  const {register, handleSubmit} = useForm({});
  const history = useHistory();
  const {setAlert} = useAlert();
  const mutation = useMutateForgotCredentials({
    onSuccess() {
      setAlert({
        open: true,
        message: "Er is een mail gestuurd naar uw emailadres om uw wachtwoord te resetten",
        variant: "success"
      });
    },
    onError(error) {
      const errorMessage = error.message;
      if (errorMessage === USER_DOES_NOT_EXIST)
        return setAlert({
          open: true,
          message: "Dit e-mailadres is niet bekend",
          variant: "error"
        });
      setAlert({
        open: true,
        message: "Er is een onbekende fout opgetreden",
        variant: "error"
      });
    }
  });
  const onSubmit = async (data) => mutation.mutate(data.email);
  return /* @__PURE__ */ React.createElement(AuthLayout, null, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, "Inloggegevens vergeten? | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement("div", {
    className: wrapper
  }, /* @__PURE__ */ React.createElement("div", {
    className: card
  }, /* @__PURE__ */ React.createElement("form", {
    className: cssStack(),
    onSubmit: handleSubmit(onSubmit)
  }, /* @__PURE__ */ React.createElement("h2", null, "Inloggegevens vergeten?"), /* @__PURE__ */ React.createElement("p", null, "Vul je e-mailadres in, dan sturen we je een e-mail met een link om je wachtwoord te resetten."), /* @__PURE__ */ React.createElement(Input, {
    type: "email",
    placeholder: "E-mailadres",
    required: true,
    ...register("email", {required: true})
  }), /* @__PURE__ */ React.createElement(Button, {
    primary: true,
    loading: mutation.isLoading
  }, "Resetlink versturen"), /* @__PURE__ */ React.createElement("div", {
    className: displayCenter
  }, /* @__PURE__ */ React.createElement(LinkButton, {
    label: "Terug naar inloggen",
    color: "primary",
    icon: MdChevronLeft,
    onClick: () => history.push("/inloggen")
  }), /* @__PURE__ */ React.createElement("i", null, "Velden met een * zijn verplicht")))))));
};
