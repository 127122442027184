import React from "../../../_snowpack/pkg/react.js";
import {cssRule} from "../index.js";
const subtitle = cssRule({
  color: "var(--ockb-text-light)",
  fontStyle: "italics",
  '[data-status="correct"]': {
    color: "var(--color-success)"
  },
  '[data-status="wrong"]': {
    color: "var(--color-error)"
  }
});
export const QuestionSubtitle = ({status}) => {
  return /* @__PURE__ */ React.createElement("div", {
    "data-status": status,
    className: subtitle
  }, /* @__PURE__ */ React.createElement("i", null, status === "correct" ? "Je hebt deze vraag juist beantwoord" : status === "wrong" ? "Je hebt deze vraag niet juist beantwoord" : "Eén antwoord mogelijk"));
};
