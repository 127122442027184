const normalize = (text) => text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
export const fuzzyContains = (textRaw, queryRaw) => {
  const text = normalize(textRaw);
  const query = normalize(queryRaw);
  let qi = 0;
  let matchLength = 0;
  let firstPos;
  for (let ti = 0; ti < text.length; ti++) {
    const c = text[ti];
    if (c === query[qi]) {
      firstPos = firstPos ?? ti;
      qi++;
    }
    if (qi > 0)
      matchLength++;
    if (qi === query.length)
      return true;
    if (matchLength > query.length * 2) {
      qi = 0;
      matchLength = 0;
      ti = firstPos;
      firstPos = void 0;
    }
  }
  return false;
};
