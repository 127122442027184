import React from "../../../../_snowpack/pkg/react.js";
import {MdForum, MdReplay} from "../../../../_snowpack/pkg/react-icons/md.js";
import {
  Button,
  Card,
  cssStack,
  DefaultError,
  DefaultLoading,
  ExamResult,
  Heading,
  LinkButton,
  QuestionItem,
  Text
} from "../../index.js";
import {ItemType} from "../../../constants/ektron.js";
import {useScrollMargin} from "../../../hooks/useScrollMargin.js";
import {useContentPageData} from "../../../resources/ockb/index.js";
export const ChapterItemExam = ({part, toggleQuestionForm, notifier}) => {
  const {data, error} = useContentPageData();
  const ref = React.useRef();
  const [answers, setAnswers] = React.useState([]);
  const questions = part.questions || [];
  const questionsCorrect = questions.filter((q, i) => q.answers?.some((answer) => answer.isRightAnswer && answer.text === answers[i])).length;
  const questionsFalse = questions.length - questionsCorrect;
  const isFlawless = questionsFalse === 0;
  const failedExam = (part.minimumRequiredValid ?? 0) > questionsCorrect;
  const [state, setState] = React.useState("toets");
  const execute = (action) => {
    switch (state) {
      case "toets":
        if (action === "controleer") {
          setState("resultaat");
          notifier({
            type: failedExam ? "fail" : "done",
            data: {questionsCorrect, questionsFalse}
          });
          ref.current?.scrollIntoView();
        }
        break;
      case "resultaat":
        if (action === "nogmaals") {
          setAnswers([]);
          setState("toets");
          notifier({type: "reset"});
          ref.current?.scrollIntoView();
        } else if (action === "bekijk") {
          setState("antwoorden");
          ref.current?.scrollIntoView();
        }
        break;
      case "antwoorden":
        if (action === "nogmaals") {
          setAnswers([]);
          setState("toets");
          notifier({type: "reset"});
          ref.current?.scrollIntoView();
        } else if (action === "terug") {
          setState("resultaat");
          ref.current?.scrollIntoView();
        }
        break;
    }
  };
  const reset = () => {
    setAnswers([]);
    setState("toets");
  };
  React.useEffect(reset, [questions]);
  const scrollMargin = useScrollMargin();
  if (error)
    return /* @__PURE__ */ React.createElement(DefaultError, null);
  if (!data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  const pageData = part.type === ItemType.SubExam ? data.courses.review : data.courses.exam;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    ref,
    style: scrollMargin
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "3",
    ektronHTML: part.title
  })), part.introduction && /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: part.introduction
  }), state === "resultaat" && pageData && /* @__PURE__ */ React.createElement("div", {
    className: cssStack()
  }, /* @__PURE__ */ React.createElement(ExamResult, {
    partType: part.type,
    variant: failedExam ? "failed" : isFlawless ? "passedFlawless" : "passed"
  }), /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: failedExam ? pageData.failed.body : isFlawless ? pageData.flawless.body : pageData.passed.body
  }), /* @__PURE__ */ React.createElement(Card, {
    color: "teal"
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "3"
  }, "Resultaat"), /* @__PURE__ */ React.createElement("p", null, questionsCorrect, " ", questionsCorrect === 1 ? "vraag" : "vragen", " juist beantwoord"), /* @__PURE__ */ React.createElement("p", null, questionsFalse, " ", questionsFalse === 1 ? "vraag" : "vragen", " fout beantwoord")), /* @__PURE__ */ React.createElement("div", {
    className: cssStack({horizontal: true})
  }, /* @__PURE__ */ React.createElement(Button, {
    primary: true,
    onClick: () => execute("bekijk")
  }, "Bekijk je antwoorden"), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdReplay,
    onClick: () => execute("nogmaals"),
    label: failedExam ? "Probeer het nogmaals" : "Maak de toets opnieuw",
    color: "primary"
  }), /* @__PURE__ */ React.createElement("span", {
    style: {flex: "999 0 auto"}
  }, " "), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdForum,
    label: "Stel een vraag",
    color: "primary",
    onClick: toggleQuestionForm
  }))), state !== "resultaat" && /* @__PURE__ */ React.createElement("div", {
    className: cssStack({space: "var(--smax)"})
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    level: "3",
    ektronHTML: pageData?.instructions.header
  }), /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: pageData?.instructions.body
  })), questions.map((question, index) => /* @__PURE__ */ React.createElement(QuestionItem, {
    key: question.question,
    question,
    givenAnswer: answers[index] || "",
    setAnswer: (answer) => {
      const newAnswers = [...answers];
      newAnswers[index] = answer;
      setAnswers(newAnswers);
    },
    showAnswer: state === "antwoorden",
    number: index + 1
  })), state === "toets" ? /* @__PURE__ */ React.createElement("div", {
    className: cssStack({horizontal: true})
  }, /* @__PURE__ */ React.createElement(Button, {
    primary: true,
    onClick: () => execute("controleer")
  }, "Controleer antwoorden"), /* @__PURE__ */ React.createElement("span", {
    style: {flex: "999 0 auto"}
  }, " "), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdForum,
    label: "Stel een vraag",
    color: "primary",
    onClick: toggleQuestionForm
  })) : /* @__PURE__ */ React.createElement("div", {
    className: cssStack({horizontal: true})
  }, /* @__PURE__ */ React.createElement(Button, {
    primary: true,
    onClick: () => execute("terug")
  }, "Terug naar je resultaat"), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdReplay,
    onClick: () => execute("nogmaals"),
    label: failedExam ? "Probeer het nogmaals" : "Maak de toets opnieuw",
    color: "primary"
  }), /* @__PURE__ */ React.createElement("span", {
    style: {flex: "999 0 auto"}
  }, " "), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdForum,
    label: "Stel een vraag",
    color: "primary",
    onClick: toggleQuestionForm
  }))));
};
