import React from "../../../_snowpack/pkg/react.js";
import {FaFacebookSquare, FaInstagram, FaTwitter} from "../../../_snowpack/pkg/react-icons/fa.js";
import {MdEmail, MdLocalPostOffice, MdPhone} from "../../../_snowpack/pkg/react-icons/md.js";
import {Link} from "../../../_snowpack/pkg/react-router-dom.js";
import {cssRule, cssStack, Heading, LinkButton, Section} from "../index.js";
import {UserType} from "../../constants/ektron.js";
import {seniorWebLinks} from "../../constants/footer.js";
import {contactURL, coursesURL, faqURL, locationURL, participantAccountURL, participantURL} from "../../constants/routing.js";
import {useContentPageData} from "../../resources/ockb/index.js";
import {useAppState, useUserType} from "../../state/index.js";
const content = cssRule({
  display: "grid",
  gridTemplateColumns: "repeat(3, auto)",
  gap: "var(--s0)",
  justifyContent: "space-between",
  padding: "0 var(--s2)",
  "> *:not(:nth-child(n+4))": {
    alignSelf: "end"
  },
  color: "var(--ockb-text-light)",
  " > h3": {
    color: "var(--sw-teal)"
  }
});
const contactWrapper = cssRule({
  display: "flex",
  alignItems: "center",
  gap: "var(--s1)",
  paddingTop: "var(--s2)",
  justifyContent: "flex-end",
  marginTop: "var(--s4)",
  borderTop: "var(--border-thin) solid var(--color-line-light)",
  " > div": {
    display: "flex",
    gap: "var(--s-1)",
    " > a": {
      display: "flex",
      alignItems: "center"
    }
  },
  "@media (max-width: 600px)": {
    flexDirection: "column"
  }
});
const contactItem = cssRule({
  display: "flex",
  alignItems: "center",
  gap: "var(--s-1)",
  whiteSpace: "nowrap",
  " > a": {
    textDecoration: "none"
  }
});
const commonLink = cssRule({
  color: "var(--ockb-text-light)",
  textDecoration: "none"
});
const stack = cssStack({space: "var(--s-1)"});
export const Footer = ({}) => {
  const user = useAppState(({user: user2}) => user2);
  const userType = useUserType();
  const pagedata = useContentPageData();
  const library = pagedata.data?.footer.libraryData;
  const coursesLinks = [
    {
      label: "Alle cursussen",
      roles: [UserType.Participant, UserType.Guest],
      link: coursesURL()
    },
    {
      label: "Mijn gegevens",
      roles: [UserType.Participant],
      link: participantAccountURL()
    },
    {
      label: "Locatiegegevens",
      roles: [UserType.Library],
      link: locationURL()
    },
    {
      label: "Deelnemers",
      roles: [UserType.Library],
      link: participantURL()
    },
    {
      label: "Contact",
      link: contactURL()
    },
    {
      label: "Veelgestelde vragen",
      link: faqURL()
    }
  ];
  const libraryLinks = [
    {
      label: "Bibliotheek homepage",
      key: "homepageUrl",
      link: library?.urls.homepageUrl ?? "https://www.bibliotheek.nl/"
    },
    {
      label: "Lid worden",
      key: "joinLibraryUrl",
      link: library?.urls.joinLibraryUrl ?? "https://www.bibliotheek.nl/lid-worden.html"
    },
    {
      label: "Meer computercursussen",
      key: "courseOverviewUrl",
      link: library?.urls.courseOverviewUrl ?? "https://www.bibliotheek.nl/in-de-bibliotheek/online-cursussen.html"
    },
    {
      label: "Bibliotheek zoeken",
      roles: [UserType.Guest],
      link: library?.urls.locationsUrl ?? "https://www.bibliotheek.nl/bibliotheek-zoeken.html"
    },
    {
      label: "Vestiging zoeken",
      roles: [UserType.Participant, UserType.Library],
      key: "locationsUrl",
      link: library?.urls.locationsUrl ?? "https://www.bibliotheek.nl"
    }
  ];
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Section, {
    scale: "small",
    style: {paddingTop: "var(--s0)", paddingBottom: "var(--s2)"}
  }, /* @__PURE__ */ React.createElement("div", {
    className: content
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "3"
  }, "Cursussen"), /* @__PURE__ */ React.createElement("a", {
    target: "_blank",
    href: library?.urls.logoUrl ?? ""
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/assets/icons-shared/logo-ockb.svg",
    alt: "OCKB"
  })), /* @__PURE__ */ React.createElement("img", {
    src: "/assets/icons-shared/logo-sw.svg",
    alt: "SeniorWeb"
  }), /* @__PURE__ */ React.createElement("div", {
    className: stack
  }, coursesLinks.filter((link) => link.roles == null || link.roles.includes(userType)).map((coursesLink) => /* @__PURE__ */ React.createElement(Link, {
    key: coursesLink.label,
    className: commonLink,
    to: coursesLink.link
  }, coursesLink.label))), /* @__PURE__ */ React.createElement("div", {
    className: stack
  }, libraryLinks.filter((link) => link.roles == null || link.roles.includes(userType)).map((coursesLink) => /* @__PURE__ */ React.createElement(Link, {
    key: coursesLink.label,
    className: commonLink,
    to: {
      pathname: library?.urls?.[coursesLink.key ?? ""] ?? coursesLink.link
    },
    target: "_blank"
  }, coursesLink.label))), /* @__PURE__ */ React.createElement("div", {
    className: stack
  }, seniorWebLinks.map((coursesLink) => /* @__PURE__ */ React.createElement(Link, {
    key: coursesLink.label,
    className: commonLink,
    to: {pathname: coursesLink.link},
    target: "_blank"
  }, coursesLink.label)))), /* @__PURE__ */ React.createElement("div", {
    className: contactWrapper
  }, user && library?.contact?.phone && /* @__PURE__ */ React.createElement("div", {
    className: contactItem
  }, /* @__PURE__ */ React.createElement("a", {
    target: "_blank",
    href: `tel:${library.contact.phone}`
  }, /* @__PURE__ */ React.createElement(LinkButton, {
    label: library.contact.phone,
    icon: MdPhone
  }))), /* @__PURE__ */ React.createElement("div", {
    className: contactItem
  }, library?.contact.email && /* @__PURE__ */ React.createElement("a", {
    target: "_blank",
    href: `mailto:${library.contact.email}`
  }, /* @__PURE__ */ React.createElement(LinkButton, {
    label: library.contact.email,
    icon: MdEmail
  }))), /* @__PURE__ */ React.createElement("div", null, library?.socials?.facebookUrl && /* @__PURE__ */ React.createElement("a", {
    target: "_blank",
    href: library.socials.facebookUrl
  }, /* @__PURE__ */ React.createElement(FaFacebookSquare, {
    color: "var(--ockb-text-light)",
    size: 18
  })), library?.socials?.facebookUrl && /* @__PURE__ */ React.createElement("a", {
    target: "_blank",
    href: library.socials.instagramUrl
  }, /* @__PURE__ */ React.createElement(FaInstagram, {
    color: "var(--ockb-text-light)",
    size: 18
  })), library?.socials?.twitterUrl && /* @__PURE__ */ React.createElement("a", {
    target: "_blank",
    href: library.socials.twitterUrl
  }, /* @__PURE__ */ React.createElement(FaTwitter, {
    color: "var(--ockb-text-light)",
    size: 18
  })), library?.socials?.newsletterUrl && /* @__PURE__ */ React.createElement("a", {
    target: "_blank",
    href: library?.socials?.newsletterUrl
  }, /* @__PURE__ */ React.createElement(MdLocalPostOffice, {
    color: "var(--ockb-text-light)",
    size: 18
  }))))));
};
