import classNames from "../../../_snowpack/pkg/classnames/bind.js";
import React from "../../../_snowpack/pkg/react.js";
import {cssRule, cssStack, Heading, Text} from "../index.js";
import {generateFrame} from "../../styling/every-layout/frame.js";
import {generateStack} from "../../styling/every-layout/stack.js";
export const NoResultsCard = ({body}) => {
  const horizontal = true;
  const vertical = !horizontal;
  const ellipsis = horizontal;
  return /* @__PURE__ */ React.createElement("div", {
    className: main({horizontal, vertical})
  }, /* @__PURE__ */ React.createElement("div", {
    className: "image"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("svg", {
    id: "no_results",
    xmlns: "http://www.w3.org/2000/svg",
    width: "90",
    height: "90",
    viewBox: "0 0 90 90"
  }, /* @__PURE__ */ React.createElement("path", {
    id: "Path_1510",
    "data-name": "Path 1510",
    d: "M0,0H90V90H0Z",
    fill: "none"
  }), /* @__PURE__ */ React.createElement("circle", {
    id: "Ellipse_155",
    "data-name": "Ellipse 155",
    cx: "5.5",
    cy: "5.5",
    r: "5.5",
    transform: "translate(53 30)",
    fill: "#d72221"
  }), /* @__PURE__ */ React.createElement("ellipse", {
    id: "Ellipse_156",
    "data-name": "Ellipse 156",
    cx: "6",
    cy: "5.5",
    rx: "6",
    ry: "5.5",
    transform: "translate(26 30)",
    fill: "#d72221"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1511",
    "data-name": "Path 1511",
    d: "M39.462,2A37.5,37.5,0,1,0,77,39.5,37.482,37.482,0,0,0,39.462,2ZM39.5,69.5a30,30,0,1,1,30-30A29.992,29.992,0,0,1,39.5,69.5Zm0-22.5A20.607,20.607,0,0,0,20.3,60.125h6.262a14.909,14.909,0,0,1,25.875,0H58.7A20.607,20.607,0,0,0,39.5,47Z",
    transform: "translate(5.5 5.5)",
    fill: "#d72221"
  }))))), /* @__PURE__ */ React.createElement("div", {
    "data--info": true
  }, /* @__PURE__ */ React.createElement("div", {
    style: {flexGrow: 999}
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "4",
    ellipsis
  }, "Geen resultaten gevonden")), /* @__PURE__ */ React.createElement(Text, {
    light: true
  }, body)));
};
const main = classNames.bind({
  default: cssRule({
    background: "var(--color-default-bg)",
    " > [data--info]": {
      ...generateStack({space: "var(--s2)"})
    }
  }),
  horizontal: cssRule({
    display: "flex",
    alignItems: "center",
    padding: "var(--s2)",
    " > .image": {
      flex: "0 0 calc(2 * (100% - 7 * var(--s0)) / 8 + 1 * var(--s0))",
      " > div": {
        ...generateFrame({numerator: 1, denominator: 1}),
        " > div > img": {
          width: "50%",
          height: "50%",
          objectFit: "contain"
        }
      }
    },
    " > [data--info]": {
      flex: "0 1 calc(6 * (100% - 7 * var(--s0)) / 8 + 5 * var(--s0))",
      maxWidth: "calc(6 * (100% - 7 * var(--s0)) / 8 + 5 * var(--s0))",
      borderLeft: "var(--border-thin) solid var(--color-line)",
      paddingLeft: "var(--s0)"
    }
  }),
  vertical: cssStack({space: "var(--s5)"}, {
    padding: "var(--s5)",
    " .image > div": {
      ...generateFrame({numerator: 1, denominator: 2}),
      " > div > img": {
        width: "100%",
        height: "100%",
        objectFit: "contain"
      }
    },
    " > [data--info]": {
      flexGrow: 999,
      paddingTop: "var(--s5)",
      borderTop: "var(--border-thin) solid var(--color-line)"
    }
  })
}, "default");
