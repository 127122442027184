import React, {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {MdAdd, MdRemove} from "../../../_snowpack/pkg/react-icons/md.js";
import {useParams} from "../../../_snowpack/pkg/react-router.js";
import {CoursePartItem, cssRule} from "../index.js";
const iconButton = cssRule({
  display: "flex",
  alignItems: "center",
  gap: "var(--s-2)",
  margin: "var(--s0) 0",
  cursor: "pointer",
  " >svg": {
    "flex-shrink": "0"
  }
});
const title = cssRule({
  textAlign: "left"
});
const list = cssRule({
  marginLeft: "var(--s2)"
});
const iconBlue = cssRule({color: "var(--ockb-text-link)"});
export const CourseNavigationChapter = ({course, chapter}) => {
  const {chapterSlug} = useParams();
  const isCurrentChapter = chapterSlug === chapter.slug;
  const [open, setOpen] = useState(isCurrentChapter);
  useEffect(() => {
    if (!isCurrentChapter && open)
      return setOpen(false);
    if (isCurrentChapter && !open)
      return setOpen(true);
  }, [isCurrentChapter]);
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("button", {
    className: iconButton,
    onClick: () => setOpen(!open)
  }, open ? /* @__PURE__ */ React.createElement(MdRemove, {
    size: "1.25rem",
    className: iconBlue
  }) : /* @__PURE__ */ React.createElement(MdAdd, {
    size: "1.25rem",
    className: iconBlue
  }), /* @__PURE__ */ React.createElement("h4", {
    className: title
  }, chapter.title)), open && /* @__PURE__ */ React.createElement("ol", {
    className: list
  }, chapter.items.map((part, index) => /* @__PURE__ */ React.createElement(CoursePartItem, {
    key: part.id,
    course,
    chapter,
    isLast: index === chapter.items.length - 1,
    part
  }))));
};
