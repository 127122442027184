import React, {useState} from "../../../_snowpack/pkg/react.js";
import {cssRule, cssStack, FAQ, FAQFilter, Heading, LoadingStateOrError, Text} from "../index.js";
import {useContentFaq} from "../../resources/ockb/index.js";
const faqIntro = cssRule({
  margin: "var(--s1) 0"
});
const faqAndFilter = cssRule({
  display: "grid",
  "--columnwidth": "calc((100% - 11 * var(--sgutter)) / 12)",
  gap: "var(--sgutter) calc(var(--columnwidth) + var(--sgutter))",
  gridTemplateColumns: "calc(3 * (var(--columnwidth) + var(--sgutter))) 1fr",
  "@media (max-width: 600px)": {
    display: "flex",
    flexDirection: "column"
  }
});
const heading = cssRule({
  paddingLeft: "var(--s2)"
});
const faqList = cssStack({
  space: "var(--s2)"
});
export const FAQs = () => {
  const [selectedSubject, setSubject] = useState("Meestgesteld");
  const {data: apiFaqsData, error: contactFAQsError} = useContentFaq();
  let uiFAQsData = {
    intro: {},
    faqsContainers: []
  };
  if (!(contactFAQsError || apiFaqsData == null)) {
    uiFAQsData.intro = {
      title: apiFaqsData.intro?.header,
      html: apiFaqsData.intro?.body,
      image: apiFaqsData.intro?.image
    };
    uiFAQsData.faqsContainers = apiFaqsData.items?.map((container) => ({
      title: container.title,
      faqItems: container.content.map((faq) => ({
        title: faq.header,
        html: faq.body,
        image: faq.image
      }))
    }));
  }
  const selectedFAQsContainer = uiFAQsData.faqsContainers.find((container) => {
    return container.title?.toLowerCase() === selectedSubject.toLowerCase();
  }) ?? {};
  const getFAQsSubjects = (containers) => containers?.map((container) => container?.title);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, contactFAQsError || !apiFaqsData ? /* @__PURE__ */ React.createElement(LoadingStateOrError, {
    dataError: contactFAQsError
  }) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: faqIntro,
    id: "veelgestelde-vragen"
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "2"
  }, uiFAQsData.intro.title), /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: uiFAQsData.intro.html
  })), /* @__PURE__ */ React.createElement("div", {
    className: faqAndFilter
  }, /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement(Heading, {
    className: heading,
    level: "4"
  }, selectedFAQsContainer?.title), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(FAQFilter, {
    subjects: getFAQsSubjects(uiFAQsData.faqsContainers),
    selectedSubject,
    onChange: (subject) => setSubject(subject)
  })), selectedFAQsContainer && /* @__PURE__ */ React.createElement("div", {
    className: faqList
  }, selectedFAQsContainer.faqItems?.map((faq, i) => /* @__PURE__ */ React.createElement("div", {
    key: i
  }, /* @__PURE__ */ React.createElement(FAQ, {
    number: i + 1,
    faq
  })))))));
};
