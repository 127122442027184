import React, {useState} from "../../../_snowpack/pkg/react.js";
import {MdDelete, MdHelp} from "../../../_snowpack/pkg/react-icons/md.js";
import {
  Card,
  CourseProgressLegend,
  cssGrid,
  cssRule,
  cssStack,
  DeleteCourseProgressDialog,
  Heading,
  LinkButton,
  CourseChapter as CourseChapterComponent
} from "../index.js";
import {UserType} from "../../constants/ektron.js";
import {useUserType} from "../../state/index.js";
const main = cssStack({
  space: "var(--s0)"
});
const header = cssRule({
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    gap: "var(--s0)",
    marginBottom: "var(--s0)"
  }
});
const legend = cssRule({
  minWidth: "calc(4 * (100% - 11 * var(--s0)) / 12 + 3 * var(--s0))",
  maxWidth: "calc(8 * (100% - 11 * var(--s0)) / 12 + 7 * var(--s0))",
  marginLeft: "auto"
});
export const CourseChapters = ({chapters, course}) => {
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const isParticipant = useUserType() === UserType.Participant;
  if (!chapters)
    return null;
  const handleCloseRemoveDialog = () => setOpenRemoveDialog(false);
  return /* @__PURE__ */ React.createElement("div", {
    className: main
  }, /* @__PURE__ */ React.createElement(DeleteCourseProgressDialog, {
    course,
    open: openRemoveDialog,
    handleClose: handleCloseRemoveDialog
  }), /* @__PURE__ */ React.createElement("div", {
    className: header
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "3"
  }, "Cursusonderdelen"), /* @__PURE__ */ React.createElement("div", {
    className: cssStack({horizontal: true, space: "var(--s3)"})
  }, isParticipant && /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdDelete,
    label: "Cursusvoortgang wissen",
    color: "danger",
    onClick: () => setOpenRemoveDialog(true)
  }), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdHelp,
    label: "Wat betekenen de iconen?",
    color: "primary",
    onClick: () => setShowLegend(!showLegend)
  }))), showLegend && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Card, {
    className: legend
  }, /* @__PURE__ */ React.createElement(CourseProgressLegend, null))), /* @__PURE__ */ React.createElement("div", {
    className: cssGrid({space: "var(--s0)"})
  }, chapters.map((chapter) => /* @__PURE__ */ React.createElement(CourseChapterComponent, {
    key: chapter.slug,
    course,
    chapter
  }))));
};
