import {useEffect} from "../../../_snowpack/pkg/react.js";
import {useLocation} from "../../../_snowpack/pkg/react-router-dom.js";
import {getReactHistorySingleton} from "./history.js";
const scrollPositions = new Map();
let scrollTo = null;
export function navigate(path, scrollIntoView) {
  const history = getReactHistorySingleton();
  if (!history)
    return;
  history.push(path);
  scrollTo = scrollIntoView;
}
export default function ScrollToTop({reset = false}) {
  const location = useLocation();
  const {pathname, hash} = location;
  const key = `${pathname}${hash}`;
  useEffect(() => {
    if (reset)
      return window.scrollTo(0, 0);
    if (scrollTo) {
      scrollTo.scrollIntoView({behavior: "smooth", block: "start"});
      scrollTo = null;
    } else if (scrollPositions.has(key) || !hash) {
      window.scrollTo(0, scrollPositions.get(key) ?? 0);
    }
    return () => {
      scrollPositions.set(key, window.scrollY);
    };
  }, [key]);
  return null;
}
