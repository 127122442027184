import React from "../../../_snowpack/pkg/react.js";
import {useHistory} from "../../../_snowpack/pkg/react-router.js";
import {Button, cssStack, DefaultError, DefaultLoading, Heading} from "../index.js";
import {rootURL} from "../../constants/routing.js";
import {useMutateDeleteParticipantAPI, useParticipantAPI} from "../../resources/ockb/index.js";
import {session} from "../../resources/ockb/session.js";
import {useAppState} from "../../state/index.js";
import {useAlert} from "../../state/alert.js";
export const DeleteAccount = ({}) => {
  const {user} = useAppState(({user: user2}) => ({user: user2}));
  const history = useHistory();
  const {data, isError, isLoading} = useParticipantAPI();
  const {setAlert} = useAlert();
  const mutation = useMutateDeleteParticipantAPI({
    onSuccess: () => {
      session.logout();
      history.replace(rootURL());
      setAlert({open: true, message: "Het account is succesvol verwijderd", variant: "success"});
    },
    onError: () => setAlert({open: true, message: "Er is een onbekende fout opgetreden", variant: "error"})
  });
  if (isError || !user?.id)
    return /* @__PURE__ */ React.createElement(DefaultError, null);
  if (isLoading || !data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  return /* @__PURE__ */ React.createElement("div", {
    className: main
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "4"
  }, "Je gegevens"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    level: "5"
  }, "Naam"), /* @__PURE__ */ React.createElement("div", null, user.fullName), /* @__PURE__ */ React.createElement(Heading, {
    level: "5"
  }, "E-mailadres"), /* @__PURE__ */ React.createElement("div", null, data.email)), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    onClick: () => mutation.mutate(),
    danger: true,
    loading: mutation.isLoading
  }, "Account verwijderen"));
};
const main = cssStack({}, {
  " > ul": {
    marginLeft: "var(--s0)"
  },
  " > div": {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: "var(--sgutter)"
  }
});
