import {useMemo} from "../../_snowpack/pkg/react.js";
import {
  useContentOnlinecourses,
  useOnlinecoursesProgress
} from "../resources/ockb/index.js";
import {computeProgress, getCourseProgress} from "./modelLogic.js";
export const useCourseProgress = (course) => {
  const {data} = useOnlinecoursesProgress();
  const courseProgress = useMemo(() => course == null || data == null ? null : getCourseProgress(course, data), [course, data]);
  const userProgress = useMemo(() => course == null || courseProgress == null ? 0 : computeProgress(course, courseProgress), [course, courseProgress]);
  return course == null ? null : {
    ...course,
    userProgress,
    courseProgress
  };
};
export const useAllCourses = () => {
  const {data} = useContentOnlinecourses();
  const {data: allProgress} = useOnlinecoursesProgress();
  return allProgress == null || data?.courses == null ? [] : data.courses.map((course) => {
    const courseProgress = getCourseProgress(course, allProgress);
    const userProgress = courseProgress == null ? null : computeProgress(course, courseProgress);
    return {...course, userProgress, courseProgress};
  });
};
