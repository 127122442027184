import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

undefined /* [snowpack] import.meta.hot */ ;
export const API_BASE_URL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_API_URL;
export const CURSUS_BLACKLIST = [
  55589
];
export var CourseLevel;
(function(CourseLevel2) {
  CourseLevel2[CourseLevel2["NotSet"] = 0] = "NotSet";
  CourseLevel2[CourseLevel2["Simple"] = 1] = "Simple";
  CourseLevel2[CourseLevel2["Average"] = 2] = "Average";
  CourseLevel2[CourseLevel2["Challenging"] = 3] = "Challenging";
  CourseLevel2[CourseLevel2["Difficult"] = 4] = "Difficult";
})(CourseLevel || (CourseLevel = {}));
export const sCourseLevel = (l) => {
  switch (l) {
    case 0:
      return "niet ingesteld";
    case 1:
      return "makkelijk";
    case 2:
      return "gemiddeld";
    case 3:
      return "uitdagend";
    case 4:
      return "moeilijk";
  }
};
export var CourseProgress;
(function(CourseProgress2) {
  CourseProgress2[CourseProgress2["NotSet"] = 0] = "NotSet";
  CourseProgress2[CourseProgress2["NotStarted"] = 1] = "NotStarted";
  CourseProgress2[CourseProgress2["Started"] = 2] = "Started";
  CourseProgress2[CourseProgress2["Finished"] = 3] = "Finished";
})(CourseProgress || (CourseProgress = {}));
export const sCourseProgress = (p) => {
  switch (p) {
    case 0:
      return "niet ingesteld";
    case 1:
      return "nog niet gestart";
    case 2:
      return "gestart";
    case 3:
      return "afgerond";
  }
};
export var ItemStatus;
(function(ItemStatus2) {
  ItemStatus2[ItemStatus2["NotSet"] = 0] = "NotSet";
  ItemStatus2[ItemStatus2["ContentNotOpened"] = 4] = "ContentNotOpened";
  ItemStatus2[ItemStatus2["ContentOpened"] = 5] = "ContentOpened";
  ItemStatus2[ItemStatus2["ContentFinished"] = 6] = "ContentFinished";
  ItemStatus2[ItemStatus2["ContentSkipped"] = 7] = "ContentSkipped";
  ItemStatus2[ItemStatus2["ExamNotStarted"] = 8] = "ExamNotStarted";
  ItemStatus2[ItemStatus2["ExamFinished"] = 9] = "ExamFinished";
  ItemStatus2[ItemStatus2["ExamFailed"] = 10] = "ExamFailed";
  ItemStatus2[ItemStatus2["SubExamNotStarted"] = 11] = "SubExamNotStarted";
  ItemStatus2[ItemStatus2["SubExamFinished"] = 12] = "SubExamFinished";
  ItemStatus2[ItemStatus2["SubExamFailed"] = 13] = "SubExamFailed";
})(ItemStatus || (ItemStatus = {}));
export const successItemStatusses = [
  6,
  9,
  12
];
export var ItemType;
(function(ItemType2) {
  ItemType2[ItemType2["NotSet"] = 0] = "NotSet";
  ItemType2[ItemType2["SubExam"] = 1] = "SubExam";
  ItemType2[ItemType2["Exam"] = 2] = "Exam";
  ItemType2[ItemType2["Article"] = 3] = "Article";
  ItemType2[ItemType2["Tip"] = 4] = "Tip";
  ItemType2[ItemType2["Software"] = 5] = "Software";
  ItemType2[ItemType2["TypingCourse"] = 6] = "TypingCourse";
})(ItemType || (ItemType = {}));
export var UserType;
(function(UserType2) {
  UserType2[UserType2["Guest"] = 0] = "Guest";
  UserType2[UserType2["Library"] = 1] = "Library";
  UserType2[UserType2["Participant"] = 2] = "Participant";
})(UserType || (UserType = {}));
export var ButtonTypes;
(function(ButtonTypes2) {
  ButtonTypes2["Button"] = "Button";
  ButtonTypes2["ButtonPrimary"] = "ButtonPrimary";
  ButtonTypes2["LinkButton"] = "LinkButton";
  ButtonTypes2["PhoneLinkButton"] = "PhoneLinkButton";
  ButtonTypes2["MailLinkButton"] = "MailLinkButton";
})(ButtonTypes || (ButtonTypes = {}));
export var BackgroundColors;
(function(BackgroundColors2) {
  BackgroundColors2["Green"] = "Green";
  BackgroundColors2["Blue"] = "Blue";
})(BackgroundColors || (BackgroundColors = {}));
export const EMPTY_DATE = "0001-01-01T00:00:00";
