import React from "../../../_snowpack/pkg/react.js";
import {MdSchool} from "../../../_snowpack/pkg/react-icons/md.js";
import {CircleProgress} from "../index.js";
export const CourseInfoProgress = ({course}) => {
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(CircleProgress, {
    progress: course.userProgress,
    size: "4rem",
    white: true
  }), /* @__PURE__ */ React.createElement(MdSchool, null)), /* @__PURE__ */ React.createElement("div", null, Math.round(course.userProgress * 100), "%", /* @__PURE__ */ React.createElement("br", null), "voltooid"));
};
