import React from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {
  BannerPrimary,
  BannerSecondary,
  cssRule,
  DefaultError,
  DefaultLoading,
  FeaturedCourses,
  Heading,
  Instructions,
  LogoKBBlackWhite,
  LogoSWBlackWhite,
  Section,
  Text
} from "../components/index.js";
import {UserType} from "../constants/ektron.js";
import {useContentPageData} from "../resources/ockb/index.js";
import {useAppState, useUserType} from "../state/index.js";
import {generateStack} from "../styling/every-layout/stack.js";
import {getGreetingDayPart} from "../utils/getTextParts.js";
const rightContent = cssRule({
  background: "#BFE7F8",
  ...generateStack({space: "var(--s4)"}),
  position: "relative",
  top: "calc(-1 * var(--smax))",
  padding: "var(--s4)",
  alignItems: "center",
  " > p": {
    textAlign: "center"
  },
  " > div": {
    paddingTop: "var(--s2)",
    ...generateStack({horizontal: true, space: "var(--s6)"})
  }
});
export const HomePage = () => {
  const user = useAppState((s) => s.user);
  const {data, error} = useContentPageData();
  const userType = useUserType();
  if (error)
    /* @__PURE__ */ React.createElement(DefaultError, null);
  if (!data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, "SeniorWeb cursussen")), userType === UserType.Library ? /* @__PURE__ */ React.createElement("img", {
    src: data.home.intro.image,
    width: "100%"
  }) : void 0, /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement(BannerPrimary, {
    source: {
      ...data.home.intro,
      title: user?.userType === UserType.Participant ? `${getGreetingDayPart()} ${user.shortName}` : data.home.intro.title
    },
    rightContent: userType === UserType.Library ? /* @__PURE__ */ React.createElement("div", {
      className: `${rightContent} scale-regular`
    }, /* @__PURE__ */ React.createElement(Heading, {
      level: "2"
    }, "Samenwerking"), /* @__PURE__ */ React.createElement(Text, null, "Deze website is een samenwerking tussen de KB en de vereniging SeniorWeb."), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(LogoKBBlackWhite, null), /* @__PURE__ */ React.createElement(LogoSWBlackWhite, null))) : void 0
  })), userType === UserType.Library ? void 0 : /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, /* @__PURE__ */ React.createElement(FeaturedCourses, null)), (userType === UserType.Library || userType === UserType.Participant) && data.home.infoBlock ? /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, /* @__PURE__ */ React.createElement(Instructions, {
    title: data.home.infoBlock.header,
    steps: data.home.infoBlock.items
  })) : void 0, /* @__PURE__ */ React.createElement(Section, {
    color: user != null ? "mediumblue" : "mediumteal"
  }, /* @__PURE__ */ React.createElement(BannerSecondary, {
    source: data.home.footerCTA
  })));
};
