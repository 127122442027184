import React from "../../../../_snowpack/pkg/react.js";
import {MdForum, MdPrint} from "../../../../_snowpack/pkg/react-icons/md.js";
import {Button, cssRule, cssStack, Heading, LinkButton, Text} from "../../index.js";
import {ItemStatus} from "../../../constants/ektron.js";
export const ChapterItemLesson = ({item, status, toggleQuestionForm, notifier}) => {
  const contentRef = React.useRef();
  const handlePrint = () => {
    if (!contentRef.current)
      return;
    const pri = document.getElementById("printiframe").contentWindow;
    if (!pri)
      return;
    pri.document.open();
    pri.document.write(`<style> * { font-family: sans-serif; }</style>${contentRef.current.innerHTML}`);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    ref: contentRef,
    className: wrapper
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "3",
    ektronHTML: item.title
  }), item.introduction && /* @__PURE__ */ React.createElement("b", null, /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: item.introduction,
    className: content
  })), item.steps?.map((step) => /* @__PURE__ */ React.createElement("div", {
    key: step.title
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "4",
    ektronHTML: step.title
  }), /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: step.content,
    className: content
  })))), /* @__PURE__ */ React.createElement("div", {
    className: cssStack({horizontal: true, space: "var(--s2)"})
  }, status !== ItemStatus.ContentFinished ? /* @__PURE__ */ React.createElement(Button, {
    onClick: () => notifier({type: "done"})
  }, "Markeren als voltooid") : /* @__PURE__ */ React.createElement(Button, {
    withBorder: true,
    onClick: () => notifier({type: "reset"})
  }, "Markeren als niet voltooid"), /* @__PURE__ */ React.createElement("span", {
    style: {flex: "999 0 auto"}
  }, " "), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdPrint,
    label: "Printen",
    color: "primary",
    onClick: handlePrint
  }), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdForum,
    label: "Stel een vraag",
    color: "primary",
    onClick: toggleQuestionForm
  })));
};
const wrapper = cssRule({
  flex: 1
});
const content = cssRule({
  maxWidth: "unset",
  marginBottom: "var(--s4)",
  " ul": {
    display: "flex",
    flexDirection: "column",
    listStyleType: "none",
    " > li": {
      textIndent: "calc(calc(8px + 1em) * -1)",
      paddingLeft: "calc(8px + 1em)",
      "::before": {
        display: "inline-flex",
        content: '" "',
        marginRight: "1em",
        height: "8px",
        width: "8px",
        minWidth: "8px",
        borderRadius: "4px",
        backgroundColor: "var(--sw-teal)"
      }
    }
  }
});
