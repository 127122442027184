import React, {useState} from "../../../_snowpack/pkg/react.js";
import {MdDelete, MdEdit, MdVpnKey} from "../../../_snowpack/pkg/react-icons/md.js";
import {Card, cssRule, cssStack, Heading, LinkButton, ParticipantForm, Text} from "../index.js";
import {USER_DOES_NOT_EXIST} from "../../constants/errors.js";
import {
  useMutateDeleteParticipantAPI,
  useMutateForgotCredentials
} from "../../resources/ockb/index.js";
import {useAlert} from "../../state/alert.js";
const image = cssRule({
  width: "var(--s2)",
  marginLeft: "var(--s0)"
});
const divider = cssRule({
  width: "1px",
  height: "100%",
  backgroundColor: "var(--ockb-teal-light)"
});
export const ParticipantItem = ({source}) => {
  const {setAlert} = useAlert();
  const [edit, setEdit] = useState(false);
  const mutation = useMutateDeleteParticipantAPI({
    userId: source.id,
    onError: () => setAlert({open: true, variant: "error", message: "Er is een onbekende fout opgetreden"}),
    onSuccess: () => setAlert({open: true, variant: "success", message: "Deelnemer verwijderd"})
  });
  const resetMailMutation = useMutateForgotCredentials({
    onSuccess() {
      setAlert({
        open: true,
        message: "Er is een mail gestuurd naar het e-mailadres van de deelnemer die daarmee een nieuw wachtwoord kan aanmaken",
        variant: "success"
      });
    },
    onError(error) {
      const errorMessage = error.message;
      if (errorMessage === USER_DOES_NOT_EXIST)
        return setAlert({open: true, message: "Dit e-mailadres is niet bekend", variant: "error"});
      setAlert({open: true, message: "Er is een onbekende fout opgetreden", variant: "error"});
    }
  });
  const handleSendResetMail = async () => {
    if (!source.email)
      return;
    resetMailMutation.mutate(source.email);
  };
  return /* @__PURE__ */ React.createElement(Card, {
    className: cssStack({horizontal: true, space: "var(--s2)"})
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("img", {
    src: "/assets/icons-shared/icon-user.svg",
    alt: "user",
    className: image
  })), /* @__PURE__ */ React.createElement("div", {
    className: divider
  }), /* @__PURE__ */ React.createElement("div", {
    className: cssStack({space: "var(--s2)"})
  }, /* @__PURE__ */ React.createElement("div", {
    className: cssStack()
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "6"
  }, source.name), /* @__PURE__ */ React.createElement(Text, {
    light: true
  }, source.email)), /* @__PURE__ */ React.createElement("div", {
    className: cssStack({horizontal: true, space: "var(--s2)"})
  }, /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdEdit,
    label: "Wijzigen",
    color: "primary",
    onClick: () => setEdit(!edit)
  }), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdVpnKey,
    loading: resetMailMutation.isLoading,
    label: "Wachtwoord reset",
    color: "primary",
    onClick: handleSendResetMail
  }), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdDelete,
    loading: mutation.isLoading,
    label: "Verwijderen",
    color: "danger",
    onClick: () => mutation.mutate()
  })), edit ? /* @__PURE__ */ React.createElement(ParticipantForm, {
    userId: source.id
  }) : null));
};
