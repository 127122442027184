import * as React from "../../../_snowpack/pkg/react.js";
import {MdChevronRight, MdEmail, MdOpenInNew, MdPhone} from "../../../_snowpack/pkg/react-icons/md.js";
import {Button, cssRule, cssSidebar, cssStack, Heading, LinkButton, Text} from "../index.js";
import {ButtonTypes} from "../../constants/ektron.js";
import {useNavigation} from "../../utils/hooks.js";
const tweeKolommen = cssRule({
  display: "flex",
  gap: "var(--s3)",
  justifyContent: "space-between",
  alignItems: "center",
  "@media (max-width: 600px)": {
    flexDirection: "column"
  }
});
const tekstKolom = cssStack();
const buttonWrapper = cssRule({
  display: "flex",
  flexDirection: "column",
  gap: "var(--s0)",
  '[data-button-alignment-left="true"]': {
    alignItems: "flex-start"
  },
  '[data-button-alignment-center="true"]': {
    alignItems: "center"
  },
  '[data-button-alignment-right="true"]': {
    alignItems: "flex-end"
  }
});
const wrapper = cssRule({
  padding: "0 var(--s3)"
});
const sidebarWrapper = cssSidebar({
  contentMinimumWidth: 40,
  sideWidth: "35%",
  space: "var(--s3)"
});
const getLinkButtonIcon = (type) => {
  if (type === ButtonTypes.PhoneLinkButton)
    return MdPhone;
  if (type === ButtonTypes.MailLinkButton)
    return MdEmail;
  return MdChevronRight;
};
export const BannerSecondary = ({
  source: {buttonAlignment, image, title, body, buttons}
}) => {
  const navigation = useNavigation();
  return /* @__PURE__ */ React.createElement("div", {
    className: `${wrapper} scale-small`
  }, /* @__PURE__ */ React.createElement("div", {
    className: sidebarWrapper
  }, image && /* @__PURE__ */ React.createElement("img", {
    style: {maxWidth: "25%"},
    src: image
  }), /* @__PURE__ */ React.createElement("div", {
    className: tweeKolommen
  }, /* @__PURE__ */ React.createElement("div", {
    className: tekstKolom
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "2"
  }, title), /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: body,
    light: true
  })), /* @__PURE__ */ React.createElement("div", {
    "data-button-alignment-left": buttonAlignment === "left",
    "data-button-alignment-center": buttonAlignment === "center",
    "data-button-alignment-right": buttonAlignment === "right",
    className: buttonWrapper
  }, (buttons ?? []).map(({type, text, url}, i) => type === "Button" ? /* @__PURE__ */ React.createElement(Button, {
    key: i,
    primary: true,
    onClick: () => navigation.follow(url)
  }, text, navigation.isExternal(url) ? /* @__PURE__ */ React.createElement(MdOpenInNew, null) : void 0) : /* @__PURE__ */ React.createElement(LinkButton, {
    key: i,
    icon: getLinkButtonIcon(type),
    label: text,
    color: "primary",
    onClick: () => navigation.follow(url)
  }))))));
};
