import React from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {MdKeyboardArrowRight} from "../../_snowpack/pkg/react-icons/md.js";
import {BannerPrimary, Breadcrumbs, cssRule, Heading, Section} from "../components/index.js";
import {rootURL} from "../constants/routing.js";
import {generateStack} from "../styling/every-layout/stack.js";
const advise = cssRule({
  ...generateStack({space: "var(--s7)"}),
  " > div": {...generateStack({})}
});
const adviseList = cssRule({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  rowGap: "var(--s2)",
  columnGap: "var(--s0)",
  alignItems: "center",
  "> *:nth-child(odd)": {
    width: "var(--s5)",
    height: "var(--s5)",
    color: "var(--sw-teal)",
    backgroundColor: "var(--ockb-teal-mid)",
    borderRadius: "50%",
    fontSize: "150%",
    display: "grid",
    placeItems: "center"
  }
});
export const NotFoundPage = () => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, "Pagina niet gevonden | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement(BannerPrimary, {
    source: {
      title: "404 pagina niet gevonden",
      body: "<p>We hebben de pagina die je zoekt niet gevonden.</p> <p>De pagina bestaat mogelijk niet (meer) of is verplaatst.</p>",
      image: "assets/img-ockb/contact-not-signedin.svg"
    }
  })), /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, /* @__PURE__ */ React.createElement(Breadcrumbs, {
    crumbs: [
      {name: "Home", path: rootURL()},
      {name: "404 pagina niet gevonden", path: ""}
    ]
  })), /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, /* @__PURE__ */ React.createElement("div", {
    className: advise
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    level: "3"
  }, "Je kunt het volgende proberen"), /* @__PURE__ */ React.createElement("div", {
    className: adviseList
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(MdKeyboardArrowRight, null)), /* @__PURE__ */ React.createElement("p", null, "Controleer of je het internetadres goed hebt getypt"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(MdKeyboardArrowRight, null)), /* @__PURE__ */ React.createElement("p", null, "Probeer via de homepage te vinden wat je zoekt"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(MdKeyboardArrowRight, null)), /* @__PURE__ */ React.createElement("p", null, "Probeer via het cursusoverzicht te vinden wat je zoekt"))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    level: "4"
  }, "Tip"), /* @__PURE__ */ React.createElement("p", null, "Kom je op deze pagina via een bookmark of favoriet? Verwijder deze dan, want de pagina waar die bookmark naar verwijst bestaat niet meer.")))));
};
