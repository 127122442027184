import React from "../../../_snowpack/pkg/react.js";
import classNames from "../../../_snowpack/pkg/classnames/bind.js";
import {MdCheckCircle, MdOutlineError} from "../../../_snowpack/pkg/react-icons/md.js";
import {cssRule, Text} from "../index.js";
import {useContentPageData} from "../../resources/ockb/index.js";
import {ItemType} from "../../constants/ektron.js";
const wrapper = classNames.bind({
  default: cssRule({
    display: "flex",
    padding: "var(--s0)",
    gap: "var(--s0)",
    alignItems: "center",
    borderRadius: "var(--s-3)",
    color: "var(--color-light)"
  }),
  passed: cssRule({
    backgroundColor: "var(--color-success)"
  }),
  failed: cssRule({
    backgroundColor: "var(--color-error)"
  })
}, "default");
const getIcon = (variant) => {
  if (variant === "failed")
    return /* @__PURE__ */ React.createElement(MdOutlineError, {
      size: "1.5rem"
    });
  return /* @__PURE__ */ React.createElement(MdCheckCircle, {
    size: "1.5rem"
  });
};
export const ExamResult = ({partType, variant}) => {
  const {data, error} = useContentPageData();
  if (!data || error)
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  const pageData = partType === ItemType.SubExam ? data.courses.review : data.courses.exam;
  const getMessage = () => {
    if (!pageData)
      return "";
    if (variant === "failed")
      return pageData.failed.header ?? "";
    if (variant === "passedFlawless")
      return pageData.flawless.header ?? "";
    return pageData.passed.header ?? "";
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: wrapper({
      failed: variant === "failed",
      passed: variant === "passed" || variant === "passedFlawless"
    })
  }, getIcon(variant), /* @__PURE__ */ React.createElement(Text, null, getMessage()));
};
