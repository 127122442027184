import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import React from "../_snowpack/pkg/react.js";
import ReactDOM from "../_snowpack/pkg/react-dom.js";
import {QueryClientProvider} from "../_snowpack/pkg/react-query.js";
import {App} from "./App.js";
import {getReactHistorySingleton} from "./components/utils/history.js";
import {courseURL} from "./constants/routing.js";
import "./ga.js";
import {__PRIVATE__queryClient} from "./state/index.js";
const renderTarget = document.getElementById("root");
{
  const seenNodes = new WeakSet();
  new MutationObserver((records) => {
    for (const record of records) {
      for (const node of record.addedNodes) {
        if (node instanceof HTMLElement) {
          for (const a of node.getElementsByTagName("a")) {
            if (seenNodes.has(a))
              continue;
            seenNodes.add(a);
            const href = a.getAttribute("href") || "";
            {
              if (href.match(/^\/(computerwoordenboek|webwinkel|artikel|tip)\//i)) {
                a.setAttribute("href", `https://seniorweb.nl${href}`);
                a.setAttribute("target", "_blank");
              }
            }
            {
              const relatieveCursusLink = href.match(/^\/cursussen\/online-cursussen\/(.*)$/i);
              if (relatieveCursusLink) {
                const newHref = courseURL(relatieveCursusLink[1]);
                a.setAttribute("href", newHref);
                a.addEventListener("click", (e) => {
                  const reactHistory = getReactHistorySingleton();
                  if (!reactHistory)
                    return;
                  reactHistory.push(newHref);
                  e.preventDefault();
                });
              }
            }
          }
          for (const videoDiv of node.querySelectorAll("div.video[data-youtubeid]")) {
            if (seenNodes.has(videoDiv))
              continue;
            seenNodes.add(videoDiv);
            const {youtubeid} = videoDiv.dataset;
            videoDiv.innerHTML = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"></path></svg> Klik hier om deze video op youtube te bekijken`;
            videoDiv.addEventListener("click", () => window.open(`https://www.youtube.com/watch?v=${youtubeid}`, "_blank"));
            videoDiv.style.cursor = "pointer";
            videoDiv.style.border = "var(--border-thin) solid";
            videoDiv.style.padding = "var(--s1)";
            videoDiv.style.margin = "var(--s1)";
          }
        }
      }
    }
  }).observe(renderTarget, {
    childList: true,
    subtree: true
  });
}
ReactDOM.render(/* @__PURE__ */ React.createElement(React.StrictMode, null, /* @__PURE__ */ React.createElement(QueryClientProvider, {
  client: __PRIVATE__queryClient
}, /* @__PURE__ */ React.createElement(App, null), /* @__PURE__ */ React.createElement("div", {
  id: "dialog"
}), /* @__PURE__ */ React.createElement("iframe", {
  id: "printiframe",
  style: {height: "0px", width: "0px", position: "absolute"}
}))), renderTarget);
if (undefined /* [snowpack] import.meta.hot */ ) {
  undefined /* [snowpack] import.meta.hot */ .accept();
}
