import React from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {MdPersonAddAlt1} from "../../_snowpack/pkg/react-icons/md.js";
import {useHistory} from "../../_snowpack/pkg/react-router-dom.js";
import {
  BannerPrimary,
  BannerSecondary,
  Breadcrumbs,
  Button,
  cssRule,
  cssSidebar,
  cssStack,
  DefaultError,
  DefaultLoading,
  LocationDetails,
  NoResultsCard,
  ParticipantItem,
  Section,
  ZoekButton
} from "../components/index.js";
import {UserType} from "../constants/ektron.js";
import {createParticipantURL, participantURL, rootURL} from "../constants/routing.js";
import {useContentPageData, useParticipantsAPI} from "../resources/ockb/index.js";
import {useAppState, useGuardUserType} from "../state/index.js";
const main = cssSidebar({
  contentMinimumWidth: 100 / 12 * 6,
  sideWidth: "calc(var(--section-width) / 12 * 4)",
  space: "calc(var(--section-width) / 12)"
});
const section = cssStack({space: "var(--s6)"});
const addIcon = cssRule({
  marginRight: "var(--s-5)"
});
const crumbs = [
  {name: "Home", path: rootURL()},
  {name: "Deelnemers", path: participantURL()}
];
export const ParticipantsPage = () => {
  useGuardUserType(UserType.Library);
  const {data, error} = useParticipantsAPI();
  const history = useHistory();
  const [text, setText] = React.useState("");
  const user = useAppState(({user: user2}) => user2);
  const {data: contentPage} = useContentPageData();
  const filteredParticipants = data?.filter((participant) => participant.name?.toLowerCase().includes(text.toLowerCase()) || participant.email?.toLowerCase().includes(text.toLowerCase())) ?? [];
  if (error)
    /* @__PURE__ */ React.createElement(DefaultError, null);
  if (!data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, "Deelnemers | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement(Section, null, contentPage && /* @__PURE__ */ React.createElement(BannerPrimary, {
    source: contentPage.participants.intro
  }, /* @__PURE__ */ React.createElement(ZoekButton, {
    placeholder: "Zoek op naam of e-mailadres",
    text,
    setText
  }))), /* @__PURE__ */ React.createElement(Section, {
    color: "light",
    className: section
  }, /* @__PURE__ */ React.createElement(Breadcrumbs, {
    crumbs
  }), /* @__PURE__ */ React.createElement("div", {
    className: main
  }, /* @__PURE__ */ React.createElement("div", {
    className: cssStack({space: "var(--s2)"})
  }, /* @__PURE__ */ React.createElement(LocationDetails, {
    minimal: true,
    title: "Deelnemers van:"
  }), /* @__PURE__ */ React.createElement(Button, {
    primary: true,
    onClick: () => history.push(createParticipantURL())
  }, /* @__PURE__ */ React.createElement("div", {
    className: cssStack({horizontal: true})
  }, /* @__PURE__ */ React.createElement(MdPersonAddAlt1, {
    color: "#FFF",
    size: 20,
    className: addIcon
  }), "Deelnemer inschrijven"))), /* @__PURE__ */ React.createElement("div", {
    className: cssStack()
  }, filteredParticipants.length === 0 && /* @__PURE__ */ React.createElement(NoResultsCard, {
    body: "Er zijn geen deelnemers gevonden. Probeer een andere zoekterm."
  }), filteredParticipants.map((participant) => /* @__PURE__ */ React.createElement(ParticipantItem, {
    key: participant.id,
    source: participant
  }))))), /* @__PURE__ */ React.createElement(Section, {
    color: user != null ? "mediumblue" : "mediumteal"
  }, contentPage && /* @__PURE__ */ React.createElement(BannerSecondary, {
    source: contentPage.participants.footerCTA
  })));
};
