import React from "../../../_snowpack/pkg/react.js";
import {cssGrid, cssRule} from "../index.js";
import {ItemStatus} from "../../constants/ektron.js";
import {CourseProgress} from "../atoms/CourseProgress.js";
const legendItem = cssRule({
  display: "flex",
  alignItems: "center",
  gap: "var(--s0)"
});
const label = cssRule({
  fontSize: "var(--font-size-copy-s)"
});
export const CourseProgressLegend = () => {
  return /* @__PURE__ */ React.createElement("div", {
    className: cssGrid({
      space: "var(--s-1)",
      minimum: "calc((var(--measure) * 1.5 - 2 * var(--s0)) / 3 - 2 * var(--s0) - 2 * var(--s0))"
    })
  }, /* @__PURE__ */ React.createElement("div", {
    className: legendItem
  }, /* @__PURE__ */ React.createElement(CourseProgress, {
    status: ItemStatus.ContentNotOpened
  }), /* @__PURE__ */ React.createElement("span", {
    className: label
  }, "Leerstof nog niet bekeken")), /* @__PURE__ */ React.createElement("div", {
    className: legendItem
  }, /* @__PURE__ */ React.createElement(CourseProgress, {
    status: ItemStatus.ExamNotStarted
  }), /* @__PURE__ */ React.createElement("span", {
    className: label
  }, "Toets nog niet gemaakt")), /* @__PURE__ */ React.createElement("div", {
    className: legendItem
  }, /* @__PURE__ */ React.createElement(CourseProgress, {
    status: ItemStatus.ContentOpened
  }), /* @__PURE__ */ React.createElement("span", {
    className: label
  }, "Leerstof bekeken")), /* @__PURE__ */ React.createElement("div", {
    className: legendItem
  }, /* @__PURE__ */ React.createElement(CourseProgress, {
    status: ItemStatus.ExamFailed
  }), /* @__PURE__ */ React.createElement("span", {
    className: label
  }, "Toets niet gehaald")), /* @__PURE__ */ React.createElement("div", {
    className: legendItem
  }, /* @__PURE__ */ React.createElement(CourseProgress, {
    status: ItemStatus.ContentFinished
  }), /* @__PURE__ */ React.createElement("span", {
    className: label
  }, "Leerstof voltooid")), /* @__PURE__ */ React.createElement("div", {
    className: legendItem
  }, /* @__PURE__ */ React.createElement(CourseProgress, {
    status: ItemStatus.ExamFinished
  }), /* @__PURE__ */ React.createElement("span", {
    className: label
  }, "Toets gehaald")));
};
