import React from "../../../_snowpack/pkg/react.js";
import {MdSearch} from "../../../_snowpack/pkg/react-icons/md.js";
import {Button, cssRule} from "../index.js";
export const ZoekButton = ({placeholder, text, setText}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: zoekButton
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(MdSearch, {
    viewBox: "3 3 18 18"
  })), /* @__PURE__ */ React.createElement("input", {
    type: "text",
    value: text,
    placeholder,
    onChange: (e) => setText(e.target.value)
  }), /* @__PURE__ */ React.createElement(Button, null, "Zoeken"));
};
const zoekButton = cssRule({
  display: "flex",
  "> div, > input": {
    backgroundColor: "var(--ockb-teal-light)",
    padding: "var(--s0)"
  },
  "> div": {
    flex: "0 0 auto",
    paddingRight: 0,
    borderRadius: "var(--s-4) 0 0 var(--s-4)",
    color: "var(--ockb-text-link)",
    display: "flex",
    alignItems: "center"
  },
  "> input": {
    flex: "1 1 calc(100% / 7 * 5)",
    ":focus": {
      outlineOffset: "0px",
      outline: "none"
    }
  },
  "> button": {
    padding: "0 var(--s7)",
    borderRadius: "0 var(--s-4) var(--s-4) 0"
  }
});
