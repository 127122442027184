import * as React from "../../../_snowpack/pkg/react.js";
export const IconContact = (props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: 20,
    height: 20,
    ...props,
    fill: "currentColor"
  }, /* @__PURE__ */ React.createElement("path", {
    fill: "none",
    d: "M0 0h24v24H0z"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z"
  }), /* @__PURE__ */ React.createElement("circle", {
    cx: "9",
    cy: "13",
    r: "1"
  }), /* @__PURE__ */ React.createElement("circle", {
    cx: "15",
    cy: "13",
    r: "1"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M18 11.03A6.04 6.04 0 0 0 12.05 6c-3.03 0-6.29 2.51-6.03 6.45a8.075 8.075 0 0 0 4.86-5.89c1.31 2.63 4 4.44 7.12 4.47z"
  }));
};
