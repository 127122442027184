import * as React from "../../../_snowpack/pkg/react.js";
export const LogoKBBlackWhite = (props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "44.4",
    height: "48",
    viewBox: "0 0 44.4 48",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    id: "Logo_KB",
    "data-name": "Logo KB",
    transform: "translate(0.051 -0.002)"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "Layer_1",
    "data-name": "Layer 1",
    transform: "translate(-0.051 0.002)"
  }, /* @__PURE__ */ React.createElement("path", {
    id: "Path_1274",
    "data-name": "Path 1274",
    d: "M118.047,48a1.081,1.081,0,0,1-.3-.037,1.161,1.161,0,0,1-.714-.551,1.258,1.258,0,0,1-.13-.919L122.278,24,116.91,1.509a1.222,1.222,0,0,1,.855-1.472,1.161,1.161,0,0,1,1.406.895l5.442,22.775a1.238,1.238,0,0,1,0,.589L119.17,47.084a1.17,1.17,0,0,1-1.123.918Z",
    transform: "translate(-80.248 -0.002)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1275",
    "data-name": "Path 1275",
    d: "M2.405,47.921a17.221,17.221,0,0,0,1.9-.121c.13,0,.2,0,.24.047s.044.086-.018.154a2.58,2.58,0,0,0-.717.948c-.367.83-.335,1.138-.335,6.165v6.411c0,2.035.338,2.539.495,2.823a2.983,2.983,0,0,0,.658.67.148.148,0,0,1,.059.127.1.1,0,0,1-.11.1c-.053,0-1.647-.145-2.192-.145a22.461,22.461,0,0,0-2.32.151.113.113,0,0,1-.121-.1.157.157,0,0,1,.074-.142,3.134,3.134,0,0,0,.7-.711c.213-.329.456-.741.456-2.779,0-2.666.036-6.2.033-6.405s-.033-3.348-.036-4.968A2.5,2.5,0,0,0,.29,48.019a.19.19,0,0,1-.03-.107c0-.086.113-.083.136-.083.3,0,.7.092,2.006.092",
    transform: "translate(0.051 -32.57)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1276",
    "data-name": "Path 1276",
    d: "M15.246,55.056c-.044-.059-.05-.118-.024-.145.889-.841,2.1-2.175,3.591-3.828,1.6-1.778,2.586-3.108,2.708-3.226a.3.3,0,0,1,.24-.121c.139,0,.649,0,1.262-.024.459-.018,1.164-.059,1.348-.059s.213.015.228.062-.033.121-.059.148c-.1.1-2.785,2.255-3.232,2.666-.184.172-3.555,3.134-3.834,3.528a.071.071,0,0,0-.015.039.157.157,0,0,0,.018.044c.3.5,3.413,4.6,3.872,5.208a29.332,29.332,0,0,0,3.339,4.032,2.856,2.856,0,0,0,1.552.711,2.186,2.186,0,0,0,.838-.039.267.267,0,0,0,.041-.166v-8.95c0-.441-.033-5.427-.033-5.875a2.53,2.53,0,0,0-.27-1.111.311.311,0,0,1-.041-.175c.015-.056.074-.092.169-.083.335.036.889.021,1.419.021s.957-.039,1.144-.039c.385,0,2.714-.056,3.365-.056.48,0,2.563-.033,3.534.936a3.77,3.77,0,0,1,1.185,3,3.555,3.555,0,0,1-1.055,2.468,5.706,5.706,0,0,1-1.552,1.064c-.373.169-.661.246-.655.335s.136.059.409.11a5.2,5.2,0,0,1,3.128,1.535,4.264,4.264,0,0,1,1.072,3.259,4.915,4.915,0,0,1-1.68,3.579,6.745,6.745,0,0,1-4,1.256,5.425,5.425,0,0,1-1.055,0c-.05,0-.136-.05-.136-.3v-.593c0-.1.068-.172.249-.172a4.242,4.242,0,0,0,3.17-1.126,3.97,3.97,0,0,0,1.1-2.9A3.748,3.748,0,0,0,35.513,57.5a5.6,5.6,0,0,0-3.8-1.1c-.119,0-.166,0-.21-.059s-.033-.1-.033-.213v-.72a.332.332,0,0,1,.036-.2.16.16,0,0,1,.11-.062,8.707,8.707,0,0,0,1.244-.2,3.214,3.214,0,0,0,2.456-3.087,2.772,2.772,0,0,0-1.6-2.7,5.891,5.891,0,0,0-2.708-.406c-.767,0-1.043,0-1.218.015a.43.43,0,0,0-.278.071.415.415,0,0,0-.068.276c0,.388-.021,5.531-.021,5.863s.074,9.454.074,9.791a.426.426,0,0,1-.05.252.3.3,0,0,1-.213.053c-.409,0-1.683-.024-2.118,0s-2.794.062-3.226.062a3.206,3.206,0,0,1-.93-.116,1.8,1.8,0,0,1-.868-.637c-.3-.353-1.946-2.447-3.6-4.782-.447-.652-2.761-3.875-3.253-4.55",
    transform: "translate(-10.686 -32.442)",
    fill: "#002944"
  }))));
};
