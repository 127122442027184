import React from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {MdCheck} from "../../_snowpack/pkg/react-icons/md.js";
import {useHistory, useParams} from "../../_snowpack/pkg/react-router-dom.js";
import {
  BannerPrimary,
  BannerSecondary,
  Breadcrumbs,
  Button,
  CourseChapters,
  CourseInfoProgress,
  cssRule,
  cssSidebar,
  cssStack,
  DefaultError,
  DefaultLoading,
  Heading,
  Level,
  Requirements,
  Section,
  Text
} from "../components/index.js";
import ScrollToTop from "../components/utils/ScrollToTop.js";
import {SENIORWEB_CDN} from "../constants/assets.js";
import {UserType} from "../constants/ektron.js";
import {coursesURL, rootURL} from "../constants/routing.js";
import {useCourse} from "../hooks/index.js";
import {
  continueLastItemUrl,
  didStartCourse,
  hasCompletedCourse,
  startItemURL
} from "../hooks/modelLogic.js";
import {
  useContentPageData,
  useMutateSendCertificateOnlinecourseProgress
} from "../resources/ockb/index.js";
import {useAppState, useUserType} from "../state/index.js";
import {useAlert} from "../state/alert.js";
import {generateImposter} from "../styling/every-layout/imposter.js";
import {getBackgroundColor} from "../utils/getBackgroundColor.js";
const content = cssRule({
  " > p": {
    marginBottom: "var(--s3)"
  },
  "> p:last-child": {
    marginBottom: "var(--s0)"
  },
  " > ul": {
    listStyle: "none",
    paddingLeft: "0",
    margin: "var(--s0) 0",
    " > li": {
      position: "relative",
      display: "flex",
      alignItems: "center",
      marginBottom: "var(--s-1)"
    },
    " > li::before": {
      content: '" "',
      position: "relative",
      display: "inline-block",
      alignSelf: "flex-start",
      flexShrink: "0",
      width: "var(--s5)",
      height: "var(--s5)",
      borderRadius: "50%",
      marginRight: "var(--s0)",
      backgroundColor: "var(--ockb-teal-medium)",
      backgroundImage: 'url("/assets/icons-shared/icon-list-check.svg")',
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat"
    }
  }
});
export const CoursePage = () => {
  const {courseId} = useParams();
  const course = useCourse(courseId);
  const {setAlert} = useAlert();
  const {data, error} = useContentPageData();
  const history = useHistory();
  const user = useAppState(({user: user2}) => user2);
  const isParticipant = useUserType() === UserType.Participant;
  const mutation = useMutateSendCertificateOnlinecourseProgress({
    onError: () => setAlert({
      open: true,
      message: "Er is iets misgegaan met het versturen van het certificaat, probeer het opnieuw.",
      variant: "error"
    }),
    onSuccess: () => setAlert({
      open: true,
      message: `Certificaat is verzonden ${user?.username ? `naar ${user.username}` : ""}`,
      variant: "success"
    })
  });
  const crumbs = [
    {name: "Home", path: rootURL()},
    {name: "Cursussen", path: coursesURL()},
    {name: `Cursus ${course?.title ?? ""}`, path: `/cursussen/TODO`}
  ];
  if (error)
    return /* @__PURE__ */ React.createElement(DefaultError, null);
  if (!course || !data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  const handleSendCertificate = () => mutation.mutate(course.id);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, course.title?.replace(/&#39;/g, "'"), " | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement(ScrollToTop, {
    reset: true
  }), /* @__PURE__ */ React.createElement(Section, {
    color: "light",
    className: cssStack()
  }, /* @__PURE__ */ React.createElement(Level, {
    niveau: course.level
  }), /* @__PURE__ */ React.createElement(BannerPrimary, {
    source: {
      image: SENIORWEB_CDN(course.icon ?? ""),
      title: course.title,
      body: course.summary
    }
  }), /* @__PURE__ */ React.createElement("div", {
    className: cssStack({horizontal: true, space: "var(--s6)"}, {"> h5": {color: "var(--sw-teal)"}})
  }, hasCompletedCourse(course) && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Button, {
    loading: mutation.isLoading,
    disabled: mutation.isLoading || mutation.isSuccess,
    primary: false,
    onClick: handleSendCertificate
  }, "Certificaat versturen"), /* @__PURE__ */ React.createElement(Heading, {
    level: "5"
  }, /* @__PURE__ */ React.createElement(MdCheck, null), "  Cursus voltooid")), isParticipant && /* @__PURE__ */ React.createElement("div", {
    className: proceedButtonInfo
  }, didStartCourse(course) ? hasCompletedCourse(course) ? null : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Button, {
    onClick: () => history.push(continueLastItemUrl(course))
  }, "Ga verder met de cursus"), /* @__PURE__ */ React.createElement(CourseInfoProgress, {
    course
  })) : /* @__PURE__ */ React.createElement(Button, {
    onClick: () => history.push(startItemURL(course))
  }, "Start deze cursus")))), /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement(Breadcrumbs, {
    crumbs
  })), /* @__PURE__ */ React.createElement(Section, {
    className: cssSidebar({
      sidebarOnRight: true,
      contentMinimumWidth: 60,
      sideWidth: "30%",
      space: "var(--s3)"
    })
  }, /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: course.introduction,
    className: content
  }), course.requiredAttributes && /* @__PURE__ */ React.createElement(Requirements, {
    requirements: course.requiredAttributes
  })), /* @__PURE__ */ React.createElement(Section, null, data.courses.signupToStartCourse && /* @__PURE__ */ React.createElement(Section, {
    color: getBackgroundColor(data.courses.signupToStartCourse.background)
  }, /* @__PURE__ */ React.createElement(BannerSecondary, {
    source: data.courses.signupToStartCourse
  }))), /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, /* @__PURE__ */ React.createElement(CourseChapters, {
    chapters: course.chapters,
    course
  })), /* @__PURE__ */ React.createElement(Section, {
    color: "mediumblue"
  }, /* @__PURE__ */ React.createElement(BannerSecondary, {
    source: data.courses.footerCTA
  })));
};
const proceedButtonInfo = cssStack({horizontal: true, space: "var(--s5)"}, {
  " > div": {
    display: "flex",
    gap: "var(--s0)",
    color: "var(--ockb-text-light)",
    alignItems: "center",
    " > div": {
      position: "relative",
      " > svg:last-child": {
        ...generateImposter({}),
        color: "var(--sw-teal)"
      },
      lineHeight: 1
    }
  }
});
