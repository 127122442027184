import React, {useState} from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {MdAccountCircle, MdPersonRemove, MdVpnKey} from "../../_snowpack/pkg/react-icons/md.js";
import {
  BannerPrimary,
  BannerSecondary,
  Breadcrumbs,
  cssRule,
  cssSidebar,
  DefaultError,
  DefaultLoading,
  DeleteAccount,
  Heading,
  LoginDataForm,
  NavigationList,
  ParticipantForm,
  Section,
  Text
} from "../components/index.js";
import {UserType} from "../constants/ektron.js";
import {participantAccountURL, rootURL} from "../constants/routing.js";
import {useContentPageData, useParticipantAPI} from "../resources/ockb/index.js";
import {useAppState, useGuardUserType} from "../state/index.js";
const card = cssRule({
  display: "flex",
  flexDirection: "column",
  gap: "var(--s1)",
  backgroundColor: "var(--ockb-teal-medium)",
  padding: "var(--s1)",
  listStylePosition: "inside"
});
const sidebarWrapper = cssSidebar({
  contentMinimumWidth: 100 / 12 * 6,
  sideWidth: "calc(var(--section-width) / 12 * 4)",
  space: "calc(var(--section-width) / 12)"
});
const divider = cssRule({
  borderColor: "var(--ockb-input-border-color)",
  borderTop: "none"
});
const crumbs = [
  {name: "Home", path: rootURL()},
  {name: "Mijn gegevens", path: participantAccountURL()}
];
export const AccountPage = () => {
  useGuardUserType(UserType.Participant);
  const [tab, _setTab] = useState(sessionStorage.getItem("accountTab") || "account");
  const setTab = (tab2) => (sessionStorage.setItem("accountTab", tab2), _setTab(tab2));
  const {data: contentPage, error} = useContentPageData();
  const {data} = useParticipantAPI();
  const user = useAppState(({user: user2}) => user2);
  if (!contentPage)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  if (error)
    return /* @__PURE__ */ React.createElement(DefaultError, null);
  const navigationItems = [
    {
      icon: /* @__PURE__ */ React.createElement(MdAccountCircle, {
        size: "1.25rem"
      }),
      label: "Mijn persoonsgegevens",
      tab: "account"
    },
    {
      icon: /* @__PURE__ */ React.createElement(MdVpnKey, {
        size: "1.25rem"
      }),
      label: "Wachtwoord aanpassen",
      tab: "login"
    },
    {
      icon: /* @__PURE__ */ React.createElement(MdPersonRemove, {
        size: "1.25rem"
      }),
      label: contentPage?.profile?.deleteAccount.header ?? "",
      tab: "delete"
    }
  ];
  const getTitle = (tab2) => navigationItems.find((item) => item.tab === tab2)?.label ?? "";
  const getDescription = (tab2) => {
    switch (tab2) {
      case "account":
        return "Geef hieronder je wijzigingen door en houd je gegevens actueel.";
      case "login":
        return "Pas hieronder de gegevens aan waarmee je op deze website inlogt.";
      case "delete":
        return contentPage.profile?.deleteAccount.body ?? "";
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, "Mijn gegevens | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, /* @__PURE__ */ React.createElement(BannerPrimary, {
    source: contentPage.profile.intro
  })), /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement(Breadcrumbs, {
    crumbs
  })), /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement("div", {
    className: sidebarWrapper
  }, /* @__PURE__ */ React.createElement(NavigationList, {
    navigationItems,
    onClick: (tab2) => setTab(tab2),
    selected: tab
  }), /* @__PURE__ */ React.createElement("div", {
    className: card
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "3",
    ektronHTML: getTitle(tab)
  }), /* @__PURE__ */ React.createElement("hr", {
    className: divider
  }), /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: getDescription(tab)
  }), tab === "account" && (user?.id ? /* @__PURE__ */ React.createElement(ParticipantForm, {
    userId: user.id,
    submitLabel: "Opslaan"
  }) : null), tab === "login" && /* @__PURE__ */ React.createElement(LoginDataForm, {
    email: data?.email
  }), tab === "delete" && /* @__PURE__ */ React.createElement(DeleteAccount, null)))), /* @__PURE__ */ React.createElement(Section, {
    color: user != null ? "mediumblue" : "mediumteal"
  }, /* @__PURE__ */ React.createElement(BannerSecondary, {
    source: contentPage.profile.footerCTA
  })));
};
