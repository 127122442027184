import classNames from "../../../_snowpack/pkg/classnames/bind.js";
import React from "../../../_snowpack/pkg/react.js";
import {MdCheck, MdChevronRight} from "../../../_snowpack/pkg/react-icons/md.js";
import {useHistory} from "../../../_snowpack/pkg/react-router.js";
import {CircleProgress, cssRule, cssStack, Heading, Level, LinkButton, Text} from "../index.js";
import {noImageSvg, SENIORWEB_CDN} from "../../constants/assets.js";
import {UserType} from "../../constants/ektron.js";
import {courseURL} from "../../constants/routing.js";
import {useCourse} from "../../hooks/index.js";
import {continueLastItemUrl, didStartCourse, hasCompletedCourse} from "../../hooks/modelLogic.js";
import {useAppState} from "../../state/index.js";
import {generateFrame} from "../../styling/every-layout/frame.js";
import {generateStack} from "../../styling/every-layout/stack.js";
export const CourseListItem = ({source, horizontal}) => {
  const history = useHistory();
  const user = useAppState(({user: user2}) => user2);
  const course = useCourse((user?.userType ?? UserType.Guest) === UserType.Guest ? null : source.slug || String(source.id));
  const vertical = !horizontal;
  const ellipsis = horizontal;
  const iconSrc = source.icon ? source.icon.startsWith("http") ? source.icon : SENIORWEB_CDN(source.icon) : noImageSvg;
  return /* @__PURE__ */ React.createElement("div", {
    className: main({horizontal, vertical})
  }, /* @__PURE__ */ React.createElement("div", {
    className: "image"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("img", {
    title: source.title,
    src: iconSrc
  })))), /* @__PURE__ */ React.createElement("div", {
    "data--info": true
  }, /* @__PURE__ */ React.createElement("div", {
    style: {flexGrow: 999}
  }, /* @__PURE__ */ React.createElement("div", {
    className: "scale-small",
    style: {display: "flex", justifyContent: "space-between"}
  }, /* @__PURE__ */ React.createElement(Level, {
    niveau: source.level,
    size: "small"
  }), course && didStartCourse(course) ? /* @__PURE__ */ React.createElement("div", {
    style: {display: "flex", gap: "var(--s-4)", alignItems: "center"}
  }, hasCompletedCourse(course) ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Heading, {
    level: "5",
    style: {color: "var(--sw-teal)"}
  }, /* @__PURE__ */ React.createElement(MdCheck, null), " Cursus voltooid")) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(CircleProgress, {
    progress: course.userProgress
  }), Math.round(course.userProgress * 100), "% voltooid")) : null), /* @__PURE__ */ React.createElement(Heading, {
    level: "4",
    ellipsis,
    title: ellipsis ? source.title : void 0,
    ektronHTML: source.title
  })), horizontal && source.content ? /* @__PURE__ */ React.createElement(Text, {
    light: true
  }, source.content) : "", /* @__PURE__ */ React.createElement("div", {
    style: {
      display: "flex",
      justifyContent: "space-between"
    }
  }, /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdChevronRight,
    label: "Meer info",
    color: user ? "default" : "primary",
    onClick: () => history.push(courseURL(source.slug || String(source.id)))
  }), user && /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdChevronRight,
    label: course && didStartCourse(course) ? "Verder" : "Starten",
    color: "primary",
    onClick: () => course && history.push(continueLastItemUrl(course))
  }))));
};
const main = classNames.bind({
  default: cssRule({
    background: "var(--color-default-bg)",
    " > [data--info]": {
      ...generateStack({space: "var(--s2)"})
    }
  }),
  horizontal: cssRule({
    display: "flex",
    alignItems: "center",
    padding: "var(--s2)",
    " > .image": {
      flex: "0 0 calc(2 * (100% - 7 * var(--s0)) / 8 + 1 * var(--s0))",
      " > div": {
        ...generateFrame({numerator: 1, denominator: 1}),
        " > div > img": {
          width: "50%",
          height: "50%",
          objectFit: "contain"
        }
      }
    },
    " > [data--info]": {
      flex: "0 1 calc(6 * (100% - 7 * var(--s0)) / 8 + 5 * var(--s0))",
      maxWidth: "calc(6 * (100% - 7 * var(--s0)) / 8 + 5 * var(--s0))",
      borderLeft: "var(--border-thin) solid var(--color-line)",
      paddingLeft: "var(--s0)"
    }
  }),
  vertical: cssStack({space: "var(--s5)"}, {
    padding: "var(--s5)",
    " .image > div": {
      ...generateFrame({numerator: 1, denominator: 2}),
      " > div > img": {
        width: "100%",
        height: "100%",
        objectFit: "contain"
      }
    },
    " > [data--info]": {
      flexGrow: 999,
      paddingTop: "var(--s5)",
      borderTop: "var(--border-thin) solid var(--color-line)"
    }
  })
}, "default");
