import React from "../../../_snowpack/pkg/react.js";
import {cssStack} from "../index.js";
const main = cssStack({horizontal: true}, {
  " > div": {
    display: "flex",
    backgroundColor: "var(--sw-wit)",
    color: "var(--sw-teal)",
    borderRadius: "var(--s-3)",
    padding: "var(--s-2)",
    " > img": {
      minWidth: "24px",
      height: "24px"
    }
  }
});
function getIcon(requirement) {
  if (requirement.match(/^android-/i))
    return "android";
  if (["iPhone / iPad", "iPhone", "iPad"].includes(requirement))
    return "apple";
  switch (requirement) {
    case "Apple ID":
      return "apple-id";
    case "Computer of laptop":
      return "computer_laptop";
    case "Dropbox":
      return "dropbox";
    case "E-mailaccount":
      return "e-mailaccount";
    case "Excel":
      return "excel";
    case "Foto's app Android":
      return "fotos-android";
    case "Foto's app iOS":
      return "fotos-ios";
    case "Google-account":
      return "google";
    case "Internetverbinding":
      return "internetverbinding";
    case "Mac of Macbook":
      return "mac-macbook";
    case "Microsoft-account":
      return "microsoft";
    case "Outlook / Outlook.com":
      return "outlook";
    case "Actieve simkaart":
    case "Simkaart":
      return "simkaart";
    case "Smartphone":
      return "smartphone";
    case "Tablet":
      return "tablet";
    case "Toetsenbord":
      return "toetsenbord";
    case "WhatsApp":
      return "whatsapp";
    case "Wifi":
      return "wifi";
    case "Windows 10":
      return "windows";
    case "Word":
      return "word";
    default:
      return "icon-arrow-right";
  }
}
export const Requirement = ({requirement}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: main
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("img", {
    src: `/assets/icons-shared/${getIcon(requirement)}.svg`,
    alt: requirement
  })), /* @__PURE__ */ React.createElement("p", null, requirement));
};
