import React from "../../../_snowpack/pkg/react.js";
import {Card, CoursePartItem, cssRule, cssStack} from "../index.js";
const title = cssRule({
  color: "var(--sw-teal)",
  fontSize: "1rem"
});
export const CourseChapter = ({
  course,
  chapter
}) => {
  return /* @__PURE__ */ React.createElement(Card, {
    className: cssStack()
  }, /* @__PURE__ */ React.createElement("h5", {
    className: title
  }, chapter.title), /* @__PURE__ */ React.createElement("ol", null, chapter.items.map((part, index) => /* @__PURE__ */ React.createElement(CoursePartItem, {
    key: part.id,
    course,
    chapter,
    isLast: index === chapter.items.length - 1,
    part
  }))));
};
