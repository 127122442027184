import classNames from "../../../_snowpack/pkg/classnames/bind.js";
import React from "../../../_snowpack/pkg/react.js";
import {MdChevronRight, MdEmail, MdLocationOn, MdPhone} from "../../../_snowpack/pkg/react-icons/md.js";
import {useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import {cssRule, cssStack, DefaultError, DefaultLoading, LinkButton} from "../index.js";
import {locationURL} from "../../constants/routing.js";
import {useResourceLibrary} from "../../resources/ockb/index.js";
const wrapper = classNames.bind({
  default: cssRule({
    padding: "var(--s1)",
    backgroundColor: "var(--sw-wit)"
  }),
  lightGray: cssRule({
    backgroundColor: "var(--ockb-teal-light)"
  })
}, "default");
const name = cssRule({
  fontSize: "var(--s0)",
  fontWeight: 600
});
const locationWrapper = cssRule({
  display: "flex",
  alignItems: "center",
  gap: "var(--s-5)"
});
const titleCSS = classNames.bind({
  default: cssRule({
    fontSize: "var(--font-size-copy-xs)"
  }),
  minimal: cssRule({
    color: "var(--sw-teal)"
  })
});
export const LocationDetails = ({color = "white", minimal = false, title}) => {
  const {data, error} = useResourceLibrary();
  const history = useHistory();
  if (error)
    /* @__PURE__ */ React.createElement(DefaultError, null);
  if (!data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  return /* @__PURE__ */ React.createElement("div", {
    className: wrapper({lightGray: color === "light"})
  }, /* @__PURE__ */ React.createElement("div", {
    className: cssStack()
  }, /* @__PURE__ */ React.createElement("h4", {
    className: titleCSS({minimal})
  }, title), data.contact.name ? /* @__PURE__ */ React.createElement("span", {
    className: name
  }, data.contact.name) : null, /* @__PURE__ */ React.createElement("div", {
    className: cssStack()
  }, /* @__PURE__ */ React.createElement("div", {
    className: locationWrapper
  }, !minimal && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(MdLocationOn, {
    size: "1.25rem"
  })), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", null, data.address.street, " ", data.address.number, " ", data.address.addition), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("span", null, data.address.postcode, " ", data.address.city))), !minimal && /* @__PURE__ */ React.createElement(React.Fragment, null, data.contact.phone ? /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdPhone,
    label: data.contact.phone,
    onClick: () => console.log(`tel:${data.contact.phone}`)
  }) : /* @__PURE__ */ React.createElement(React.Fragment, null), data.contact.email ? /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdEmail,
    label: data.contact.email,
    onClick: () => console.log(`mailto:${data.contact.email}`)
  }) : /* @__PURE__ */ React.createElement(React.Fragment, null))), minimal && /* @__PURE__ */ React.createElement(LinkButton, {
    label: "Gegevens wijzigen",
    color: "primary",
    icon: MdChevronRight,
    onClick: () => history.push(locationURL())
  })));
};
