import React from "../../../_snowpack/pkg/react.js";
import {cssRule, cssStack, Heading, QuestionSubtitle} from "../index.js";
import {hash} from "../../utils/hash.js";
import {Explanation} from "../atoms/Explanation.js";
import {QuestionCount} from "../atoms/QuestionCount.js";
export const QuestionItem = ({question, givenAnswer, setAnswer, number, showAnswer}) => {
  const isCorrect = question.answers?.some((answer) => answer.isRightAnswer && answer.text === givenAnswer);
  const status = showAnswer ? isCorrect ? "correct" : "wrong" : "default";
  return /* @__PURE__ */ React.createElement("div", {
    className: cssStack({space: "var(--s4)"})
  }, /* @__PURE__ */ React.createElement("div", {
    className: wrapper
  }, /* @__PURE__ */ React.createElement(QuestionCount, {
    count: number,
    status
  }), /* @__PURE__ */ React.createElement("div", {
    className: cssStack({space: "var(--s-2)"})
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "5",
    ektronHTML: question.question
  }), /* @__PURE__ */ React.createElement(QuestionSubtitle, {
    status
  }), /* @__PURE__ */ React.createElement("div", {
    className: cssStack({space: "var(--s-2)"})
  }, question.answers?.map((answer) => {
    const id = hash(question.question + answer.text);
    return /* @__PURE__ */ React.createElement("div", {
      key: answer.text,
      className: answerWrapper
    }, /* @__PURE__ */ React.createElement("input", {
      id,
      value: answer.text,
      type: "radio",
      name: question.question,
      disabled: showAnswer,
      checked: givenAnswer === answer.text,
      onChange: (e) => setAnswer(e.target.value)
    }), /* @__PURE__ */ React.createElement("label", {
      htmlFor: id,
      dangerouslySetInnerHTML: {__html: answer.text},
      "data-correct": showAnswer ? answer.isRightAnswer : void 0,
      style: {lineHeight: 1}
    }));
  })))), showAnswer && question.feedback && /* @__PURE__ */ React.createElement(Explanation, {
    explanation: question.feedback
  }));
};
const wrapper = cssRule({
  display: "flex",
  alignItems: "flex-start",
  gap: "var(--s0)"
});
const answerWrapper = cssRule({
  display: "flex",
  gap: "var(--s0)",
  alignItems: "center",
  " > input": {
    transform: "scale(1.5)"
  },
  " > label": {
    width: "100%",
    userSelect: "none",
    cursor: "pointer",
    '[data-correct="true"]': {
      fontWeight: "bold",
      textDecoration: "underline"
    }
  }
});
