import {create} from "../../_snowpack/pkg/nano-css.js";
import {addon as addonRule} from "../../_snowpack/pkg/nano-css/addon/rule.js";
import {generateSidebar} from "../styling/every-layout/sidebar.js";
import {generateStack} from "../styling/every-layout/stack.js";
import {generateGrid} from "../styling/every-layout/grid.js";
import {hash} from "../utils/hash.js";
const nano = create({
  selector: (parent, selector) => parent + selector
});
addonRule(nano);
export const cssRule = (() => {
  function allowMultipleSelectors(css) {
    const result = {};
    for (const [key, value] of Object.entries(css)) {
      if (typeof value !== "object" || value == null) {
        result[key] = value;
        continue;
      }
      const selectors = key.split(/,/g);
      const updatedValue = allowMultipleSelectors(value);
      for (const selector of selectors) {
        result[selector] = {...result[selector] ?? {}, ...updatedValue};
      }
    }
    return result;
  }
  const classNameCache = new Map();
  return (css, block) => {
    const fullCss = allowMultipleSelectors(css);
    const h = hash(JSON.stringify(fullCss));
    if (classNameCache.has(h))
      return classNameCache.get(h);
    const classname = nano.rule(fullCss, block);
    classNameCache.set(h, classname);
    return classname;
  };
})();
export const cssStack = (config = {}, css = {}) => cssRule({...generateStack(config), ...css});
export const cssSidebar = (config = {}, css = {}) => cssRule({...generateSidebar(config), ...css});
export const cssGrid = (config = {}, css = {}) => cssRule({...generateGrid(config), ...css});
export const xdGridLayout = ({
  columnCount = 12,
  leftColumnCount,
  rightColumnCount,
  leftColumnMinWidth,
  rightColumnMinWidth,
  gap = "var(--s0)",
  gapColumnCount = columnCount - leftColumnCount - rightColumnCount
}) => ({
  display: "flex",
  flexWrap: "wrap",
  "> div:first-child": {
    flex: `1 1 calc(${leftColumnCount} * (100% - ${columnCount - 1} * ${gap}) / ${columnCount} + ${leftColumnCount - 1} * ${gap})`,
    minWidth: leftColumnMinWidth
  },
  gap: `${gap} calc(${gapColumnCount} * (100% - ${columnCount - 1} * ${gap}) / ${columnCount} + ${gapColumnCount + 1} * ${gap})`,
  "> div:last-child": {
    flex: `1 1 calc(${rightColumnCount} * (100% - ${columnCount - 1} * ${gap}) / ${columnCount} + ${rightColumnCount - 1} * ${gap})`,
    minWidth: rightColumnMinWidth
  }
});
