import React from "../../../_snowpack/pkg/react.js";
import {MdAccountCircle} from "../../../_snowpack/pkg/react-icons/md.js";
import {Link} from "../../../_snowpack/pkg/react-router-dom.js";
import {UserType} from "../../constants/ektron.js";
import {
  contactURL,
  coursesURL,
  locationURL,
  loginURL,
  participantAccountURL,
  participantURL,
  rootURL
} from "../../constants/routing.js";
import {session} from "../../resources/ockb/session.js";
import {useAppState, useUserType} from "../../state/index.js";
import {cssRule} from "../css.js";
export const Menu = ({open, setOpen}) => {
  const {user} = useAppState(({user: user2}) => ({user: user2}));
  const userType = useUserType();
  const handleCloseMenu = () => setOpen(false);
  const handleSignOut = async () => {
    await session.logout();
    setOpen(false);
  };
  const links = [
    {to: rootURL(), isMainRoute: true, show: true, label: "Home", onClick: handleCloseMenu},
    {
      to: coursesURL(),
      isMainRoute: true,
      show: userType !== UserType.Library,
      label: "Cursussen",
      onClick: handleCloseMenu
    },
    {
      to: participantAccountURL(),
      isMainRoute: true,
      show: userType === UserType.Participant,
      label: "Mijn gegevens",
      onClick: handleCloseMenu
    },
    {
      to: locationURL(),
      isMainRoute: true,
      show: userType === UserType.Library,
      label: "Locatiegegevens",
      onClick: handleCloseMenu
    },
    {
      to: participantURL(),
      isMainRoute: true,
      show: userType === UserType.Library,
      label: "Deelnemers",
      onClick: handleCloseMenu
    },
    {
      to: participantAccountURL(),
      isMainRoute: false,
      show: !!user,
      label: user?.fullName ?? "",
      icon: /* @__PURE__ */ React.createElement(MdAccountCircle, null),
      onClick: handleCloseMenu
    },
    {to: rootURL(), isMainRoute: false, show: !!user, label: "Uitloggen", onClick: handleSignOut},
    {
      to: loginURL(),
      isMainRoute: false,
      show: !user,
      label: "Inloggen",
      onClick: handleCloseMenu
    },
    {
      to: contactURL(),
      isMainRoute: false,
      show: !user,
      label: "Contact",
      onClick: handleCloseMenu
    }
  ];
  return /* @__PURE__ */ React.createElement("div", {
    className: main,
    "data--open": open
  }, /* @__PURE__ */ React.createElement("nav", {
    "data--open": open
  }, links.filter((link) => link.isMainRoute).map((link) => link.show && /* @__PURE__ */ React.createElement(Link, {
    key: link.label,
    className: "heading",
    to: link.to,
    onClick: link.onClick
  }, link.icon, " ", link.label)), /* @__PURE__ */ React.createElement("div", {
    className: "divider"
  }), links.filter((link) => !link.isMainRoute).map((link) => link.show && /* @__PURE__ */ React.createElement(Link, {
    key: link.label,
    className: "heading",
    to: link.to,
    onClick: link.onClick
  }, link.icon, " ", link.label))));
};
const main = cssRule({
  position: "sticky",
  top: "64px",
  zIndex: 1,
  " > [data--open=false]": {
    transform: "translateX(100%)"
  },
  " > nav": {
    position: "absolute",
    top: 0,
    overflowX: "hidden",
    transition: "transform 0.3s",
    display: "flex",
    flexDirection: "column",
    padding: "var(--s2)",
    width: "100vw",
    maxWidth: "100vw",
    zIndex: 1,
    bottom: 0,
    backgroundColor: "var(--sw-wit)",
    height: "calc(100vh - 64px)",
    transform: "none",
    "@media (min-width: 600px)": {
      display: "none"
    },
    " > .divider": {
      margin: "var(--s3) 0",
      height: "1px",
      width: "100%",
      backgroundColor: "var(--sw-grijs-10)"
    },
    " > a": {
      display: "flex",
      alignItems: "center",
      gap: "var(--s0)",
      fontWeight: "600",
      lineHeight: "var(--line-height-large)",
      textDecoration: "none",
      color: "var(--sw-teal)",
      fontSize: "var(--font-size-copy-l)",
      "> svg": {
        width: "20px",
        height: "20px"
      }
    }
  }
});
