import React from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {useHistory} from "../../_snowpack/pkg/react-router-dom.js";
import {
  BannerPrimary,
  BannerSecondary,
  Breadcrumbs,
  cssRule,
  cssSidebar,
  cssStack,
  DefaultError,
  DefaultLoading,
  Heading,
  LocationDetails,
  ParticipantForm,
  Section,
  Text
} from "../components/index.js";
import {BackgroundColors, ButtonTypes, UserType} from "../constants/ektron.js";
import {participantURL} from "../constants/routing.js";
import {useContentPageData} from "../resources/ockb/index.js";
import {useGuardUserType} from "../state/index.js";
const card = cssRule({
  display: "flex",
  flexDirection: "column",
  gap: "var(--s1)",
  backgroundColor: "var(--ockb-teal-medium)",
  padding: "var(--s1)"
});
const divider = cssRule({
  borderColor: "var(--ockb-input-border-color)",
  borderTop: "none"
});
const sidebarWrapper = cssSidebar({
  contentMinimumWidth: 100 / 12 * 4,
  sideWidth: "calc(var(--section-width) / 12 * 4)",
  space: "calc(var(--section-width) / 12)"
});
const crumbs = [
  {name: "Home", path: "/"},
  {name: "Deelnemers", path: "/deelnemers"},
  {name: "Deelnemer inschrijven", path: "/deelnemers/inschrijven"}
];
export const CreateParticipantPage = () => {
  useGuardUserType(UserType.Library);
  const {data, error} = useContentPageData();
  const history = useHistory();
  if (error)
    /* @__PURE__ */ React.createElement(DefaultError, null);
  if (!data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, data.participants.createIntro.header, " | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, /* @__PURE__ */ React.createElement(BannerPrimary, {
    source: {
      title: data.participants.createIntro.header,
      body: data.participants.createIntro.body,
      image: data.participants.createIntro.image,
      background: BackgroundColors.Green,
      buttons: [
        {
          text: "Naar deelnemerslijst",
          type: ButtonTypes.Button,
          url: "/deelnemers"
        }
      ]
    }
  })), /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement(Breadcrumbs, {
    crumbs
  })), /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement("div", {
    className: sidebarWrapper
  }, /* @__PURE__ */ React.createElement("div", {
    className: cssStack()
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "4"
  }, data.participants.createExplanation.header), /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: data.participants.createExplanation.body
  }), /* @__PURE__ */ React.createElement(LocationDetails, {
    color: "light",
    title: "Deelnemer wordt ingeschreven voor:"
  })), /* @__PURE__ */ React.createElement("div", {
    className: card
  }, /* @__PURE__ */ React.createElement("h3", null, "Gegevens deelnemer"), /* @__PURE__ */ React.createElement("hr", {
    className: divider
  }), /* @__PURE__ */ React.createElement(ParticipantForm, {
    userId: null,
    submitLabel: "Deelnemer inschrijven",
    onSuccess: () => history.push(participantURL())
  })))), /* @__PURE__ */ React.createElement(Section, {
    color: "mediumblue"
  }, /* @__PURE__ */ React.createElement(BannerSecondary, {
    source: data.participants.footerCTA
  })));
};
