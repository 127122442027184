import React from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {
  BannerPrimary,
  Breadcrumbs,
  ContactActionItems,
  cssRule,
  FAQs,
  LoadingStateOrError,
  Section
} from "../components/index.js";
import {BackgroundColors} from "../constants/ektron.js";
import {contactURL, rootURL} from "../constants/routing.js";
import {useContentPageData} from "../resources/ockb/index.js";
import {getGreetingDayPart} from "../utils/getTextParts.js";
const underline = cssRule({
  borderBottom: "var(--border-thin) solid var(--color-line-light)"
});
export const ContactPage = () => {
  const {data: siteContentAPI, error: contentError} = useContentPageData();
  let pageContentUI = {
    actionItems: [],
    header: {}
  };
  if (!(contentError || siteContentAPI == null)) {
    const pageContentAPI = siteContentAPI.support;
    const {supportBlocks, header: headerContent, headerExtraBlock} = pageContentAPI;
    const allHeaderContent = {
      ...headerContent,
      title: `${getGreetingDayPart()}, ${headerContent.header}`,
      extraTextBlock: headerExtraBlock,
      background: BackgroundColors.Blue
    };
    const actionItems = supportBlocks?.map((item, i) => {
      return {
        id: `${i}`,
        icon: {
          name: item.image,
          color: ""
        },
        title: {
          normal: item.title,
          bold: item.body
        },
        button: item.buttons ? item.buttons[0] : void 0,
        connectedContent: item.connectedContent
      };
    });
    pageContentUI = {header: allHeaderContent, actionItems};
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, "Contact | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement(Section, null, contentError || !pageContentUI ? /* @__PURE__ */ React.createElement(LoadingStateOrError, {
    dataError: contentError
  }) : /* @__PURE__ */ React.createElement(BannerPrimary, {
    source: pageContentUI.header
  })), /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, /* @__PURE__ */ React.createElement(Breadcrumbs, {
    crumbs: [
      {name: "Home", path: rootURL()},
      {name: "Contact", path: contactURL()}
    ]
  })), /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, contentError || !siteContentAPI ? /* @__PURE__ */ React.createElement(LoadingStateOrError, {
    dataError: contentError
  }) : /* @__PURE__ */ React.createElement(ContactActionItems, {
    items: pageContentUI.actionItems || []
  })), /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement(FAQs, null)), /* @__PURE__ */ React.createElement("div", {
    className: underline
  }));
};
