import React from "../../../_snowpack/pkg/react.js";
import {useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import {Button, Card, cssRule} from "../index.js";
import {BackgroundColors} from "../../constants/ektron.js";
import {useContentPageData} from "../../resources/ockb/index.js";
import {Heading, Text} from "../atoms/index.js";
import {cssStack} from "../css.js";
import {DefaultError} from "../molecules/index.js";
const wrapper = cssRule({
  display: "flex"
});
const content = cssRule({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});
const side = cssRule({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: 'url("/assets/img-ockb/login.jpg")',
  backgroundSize: "cover",
  "@media (max-width: 600px)": {
    display: "none"
  }
});
const card = cssRule({
  padding: "var(--s5)",
  textAlign: "center",
  maxWidth: "360px"
});
export const AuthLayout = ({children}) => {
  const {data, error} = useContentPageData();
  const history = useHistory();
  if (error)
    return /* @__PURE__ */ React.createElement(DefaultError, null);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: wrapper
  }, /* @__PURE__ */ React.createElement("div", {
    className: content
  }, children), /* @__PURE__ */ React.createElement("div", {
    className: side
  }, data && /* @__PURE__ */ React.createElement(Card, {
    color: data.login.intro.background === BackgroundColors.Blue ? "blue" : "teal",
    className: card
  }, /* @__PURE__ */ React.createElement("div", {
    className: cssStack()
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "3",
    ektronHTML: data.login.intro.title
  }), /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: data.login.intro.body
  }), data.login.intro.buttons?.map((button) => /* @__PURE__ */ React.createElement(Button, {
    key: button.text,
    onClick: () => history.push(button.url)
  }, button.text)))))));
};
