import classNames from "../../../_snowpack/pkg/classnames/bind.js";
import React from "../../../_snowpack/pkg/react.js";
import {Link, useParams} from "../../../_snowpack/pkg/react-router-dom.js";
import {cssRule} from "../index.js";
import {ItemStatus, ItemType, UserType} from "../../constants/ektron.js";
import {courseItemURL} from "../../constants/routing.js";
import {getItemRegistration} from "../../hooks/modelLogic.js";
import {useUserType} from "../../state/index.js";
import {CourseProgress} from "./CourseProgress.js";
export const CoursePartItem = ({course, chapter, part, isLast}) => {
  const isParticipant = useUserType() === UserType.Participant;
  const {chapterSlug, partSlug} = useParams();
  const isSelected = chapterSlug && partSlug ? partSlug === part.slug && chapterSlug === chapter.slug : part.id === course.courseProgress?.lastOpenedItem;
  const progressItem = getItemRegistration(course, part);
  const getCourseStatus = () => {
    if (isSelected)
      return ItemStatus.ContentOpened;
    if (progressItem)
      return progressItem.status;
    if (part.type === ItemType.SubExam)
      return ItemStatus.SubExamNotStarted;
    if (part.type === ItemType.Exam)
      return ItemStatus.ExamNotStarted;
    return ItemStatus.NotSet;
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: wrapper
  }, /* @__PURE__ */ React.createElement("div", {
    "data--progress": true
  }, /* @__PURE__ */ React.createElement(CourseProgress, {
    selected: isSelected,
    status: getCourseStatus()
  }), !isLast && /* @__PURE__ */ React.createElement("div", {
    className: line
  })), /* @__PURE__ */ React.createElement("div", {
    "data--listitem": true
  }, /* @__PURE__ */ React.createElement(Link, {
    to: isParticipant ? courseItemURL(course.slug, chapter.slug, part.slug) : "#",
    className: title({
      selected: isSelected,
      isGuest: !isParticipant
    })
  }, /* @__PURE__ */ React.createElement("div", null, part.itemNumber, "."), /* @__PURE__ */ React.createElement("span", {
    dangerouslySetInnerHTML: {__html: part.title}
  }))));
};
const line = cssRule({
  marginLeft: "auto",
  marginRight: "auto",
  width: "2px",
  minHeight: "10px",
  height: "100%",
  flex: 1,
  backgroundColor: "var(--ockb-teal-light)"
});
const title = classNames.bind({
  default: cssRule({
    display: "flex",
    marginLeft: "var(--s-2)",
    fontSize: ".8rem",
    textDecoration: "none",
    transition: "color 0.3s",
    color: "var(--ockb-text-light)",
    ":hover": {
      color: "var(--ockb-text-link)"
    },
    " > div": {
      minWidth: "var(--s1)"
    }
  }),
  selected: cssRule({
    fontWeight: "bold",
    color: "var(--ockb-text-link)"
  }),
  isGuest: cssRule({
    cursor: "default"
  })
}, "default");
const wrapper = cssRule({
  display: "flex",
  justifyContent: "space-between",
  "> [data--progress]": {
    display: "flex",
    flexDirection: "column"
  },
  "> [data--listitem]": {
    display: "flex",
    flex: 1,
    marginLeft: 0,
    fontSize: ".8rem"
  },
  "> [data--listitem] span": {
    marginLeft: "var(--s-5)"
  }
});
