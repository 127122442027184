import React, {useState} from "../../../_snowpack/pkg/react.js";
import {Link, useRouteMatch} from "../../../_snowpack/pkg/react-router-dom.js";
import {Menu} from "./index.js";
import {UserType} from "../../constants/ektron.js";
import {
  coursesURL,
  locationURL,
  participantAccountURL,
  participantURL
} from "../../constants/routing.js";
import {useUserType} from "../../state/index.js";
import {cssRule} from "../css.js";
import {TopBar} from "../molecules/TopBar.js";
const main = cssRule({
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "var(--sw-wit)",
  borderBottom: "var(--border-thin) solid var(--sw-groen-15)",
  " > nav": {
    boxSizing: "content-box",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "calc(1.5 * var(--measure))",
    " > div": {
      display: "flex",
      gap: "var(--s0)",
      padding: "var(--s0) 0",
      justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: "calc(226px + var(--s5))",
      " > a": {
        textDecoration: "none",
        color: "var(--sw-teal)",
        borderBottom: "2px solid rgba(1, 1, 1, 0)",
        '[data-active="true"]': {
          borderColor: "var(--sw-teal)"
        }
      },
      "@media (max-width: 600px)": {
        display: "none"
      }
    }
  }
});
export const Header = ({}) => {
  const {path} = useRouteMatch(location.pathname) ?? {path: "/"};
  const userType = useUserType();
  const [open, setOpen] = useState(false);
  const toggleMenu = () => setOpen(!open);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("header", {
    className: main
  }, /* @__PURE__ */ React.createElement(TopBar, {
    isMenuOpen: open,
    toggleMenu
  }), /* @__PURE__ */ React.createElement("nav", null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Link, {
    className: "heading",
    "data-active": path === "/",
    to: "/"
  }, "Home"), userType !== UserType.Library && /* @__PURE__ */ React.createElement(Link, {
    className: "heading",
    "data-active": path.startsWith(coursesURL()),
    to: coursesURL()
  }, "Cursussen"), userType === UserType.Participant && /* @__PURE__ */ React.createElement(Link, {
    className: "heading",
    "data-active": path.startsWith(participantAccountURL()),
    to: participantAccountURL()
  }, "Mijn gegevens"), userType === UserType.Library && /* @__PURE__ */ React.createElement(Link, {
    className: "heading",
    "data-active": path.startsWith(locationURL()),
    to: locationURL()
  }, "Locatiegegevens"), userType === UserType.Library && /* @__PURE__ */ React.createElement(Link, {
    className: "heading",
    "data-active": path.startsWith(participantURL()),
    to: participantURL()
  }, "Deelnemers"))), /* @__PURE__ */ React.createElement(Menu, {
    open,
    setOpen
  })));
};
