import React from "../../../_snowpack/pkg/react.js";
import {cssRule} from "../index.js";
const main = cssRule({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "var(--s5)",
  height: "var(--s5)",
  fontSize: "var(--font-size-headline-3)",
  color: "var(--ockb-text-link)",
  fontWeight: 600,
  fontFamily: "var(--font-special)",
  borderRadius: "50%",
  backgroundColor: "var(--ockb-blue-light)",
  '[data-status="correct"]': {
    color: "var(--color-success)",
    backgroundColor: "#8DC73F26"
  },
  '[data-status="wrong"]': {
    color: "var(--color-error)",
    backgroundColor: "#D7222126"
  }
});
export const QuestionCount = ({count, status}) => {
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    "data-status": status,
    className: main
  }, count));
};
