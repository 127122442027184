import React from "../../../_snowpack/pkg/react.js";
import {Card, cssStack, Heading, Requirement} from "../index.js";
export const Requirements = ({requirements}) => {
  return /* @__PURE__ */ React.createElement("div", null, requirements.length > 0 ? /* @__PURE__ */ React.createElement(Card, {
    color: "blue",
    className: cssStack()
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "4"
  }, "Benodigdheden"), /* @__PURE__ */ React.createElement("div", {
    className: cssStack()
  }, requirements.map((requirement) => /* @__PURE__ */ React.createElement(Requirement, {
    key: requirement,
    requirement
  })))) : void 0);
};
