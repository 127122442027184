import React from "../../../_snowpack/pkg/react.js";
import {useForm} from "../../../_snowpack/pkg/react-hook-form.js";
import {Button, cssRule, cssStack, DefaultError, DefaultLoading, Heading, Input} from "../index.js";
import {
  useMutateResourceLibrary,
  useResourceLibrary
} from "../../resources/ockb/index.js";
import {useAppState} from "../../state/index.js";
import {useAlert} from "../../state/alert.js";
import {generateStack} from "../../styling/every-layout/stack.js";
const main = cssRule({
  ...generateStack({space: "var(--s4)"}),
  " > div": {
    ...generateStack({})
  }
});
const hStack = cssStack({horizontal: true});
export const LocationForm = ({}) => {
  const {register, handleSubmit} = useForm();
  const {setAlert} = useAlert();
  const user = useAppState(({user: user2}) => user2);
  const {data, error} = useResourceLibrary();
  const mutation = useMutateResourceLibrary({
    onSuccess() {
      setAlert({open: true, variant: "success", message: "Wijzigingen opgeslagen"});
    },
    onError() {
      setAlert({
        open: true,
        variant: "error",
        message: "Er is iets misgegaan, probeer het later opnieuw."
      });
    }
  });
  const onSubmit = async (rawData) => {
    const data2 = {
      id: user?.id || "",
      contact: {},
      address: {},
      urls: {},
      socials: {}
    };
    for (const [key, value] of Object.entries(rawData)) {
      if (value === "")
        continue;
      const [k1, ...k2] = key.split("_");
      data2[k1][k2.join("_")] = value;
    }
    await mutation.mutate(data2);
  };
  if (error)
    return /* @__PURE__ */ React.createElement(DefaultError, null);
  if (!data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  return /* @__PURE__ */ React.createElement("form", {
    className: main,
    onSubmit: handleSubmit(onSubmit)
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    level: "5"
  }, "Contact"), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Locatienaam",
    type: "text",
    required: true,
    ...register("contact_name", {required: true}),
    defaultValue: data.contact?.name
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Contactpersoon",
    type: "text",
    required: true,
    ...register("contact_contactPerson", {required: true}),
    defaultValue: data.contact?.contactPerson
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Telefoonnummer",
    type: "tel",
    required: true,
    ...register("contact_phone", {required: true}),
    defaultValue: data.contact?.phone
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "E-mailadres",
    type: "email",
    required: true,
    ...register("contact_email", {required: true}),
    defaultValue: data.contact?.email
  })), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    level: "5"
  }, "Adres"), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Postcode",
    type: "text",
    required: true,
    ...register("address_postcode", {required: true}),
    defaultValue: data.address?.postcode
  }), /* @__PURE__ */ React.createElement("div", {
    className: hStack
  }, /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Huisnummer",
    type: "number",
    required: true,
    ...register("address_number", {
      required: true
    }),
    defaultValue: data.address?.number
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Toevoeging",
    type: "text",
    ...register("address_addition", {}),
    defaultValue: data.address?.addition
  })), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Straat",
    type: "text",
    required: true,
    ...register("address_street", {required: true}),
    defaultValue: data.address?.street
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Plaats",
    type: "text",
    required: true,
    ...register("address_city", {required: true}),
    defaultValue: data.address?.city
  })), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    level: "5"
  }, "Bibliotheek webpagina's"), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "URL homepage",
    type: "url",
    ...register("urls_homepageUrl", {}),
    defaultValue: data.urls?.homepageUrl
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "URL pagina bibliotheek lid worden",
    type: "url",
    ...register("urls_joinLibraryUrl", {}),
    defaultValue: data.urls?.joinLibraryUrl
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "URL pagina meer cursussen in bibliotheek",
    type: "url",
    ...register("urls_courseOverviewUrl", {}),
    defaultValue: data.urls?.courseOverviewUrl
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "URL bibliotheekvestigingen",
    type: "url",
    ...register("urls_locationsUrl", {}),
    defaultValue: data.urls?.locationsUrl
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "URL bibliotheek logo",
    type: "url",
    ...register("urls_logoUrl", {}),
    defaultValue: data.urls?.logoUrl
  })), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    level: "5"
  }, "Social media"), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Twitter",
    type: "url",
    ...register("socials_twitterUrl", {}),
    defaultValue: data.socials?.twitterUrl
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Facebook",
    type: "url",
    ...register("socials_facebookUrl", {}),
    defaultValue: data.socials?.facebookUrl
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Instagram",
    type: "url",
    ...register("socials_instagramUrl", {}),
    defaultValue: data.socials?.instagramUrl
  }), /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Nieuwsbrief",
    type: "url",
    ...register("socials_newsletterUrl", {}),
    defaultValue: data.socials?.newsletterUrl
  })), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    primary: true,
    loading: mutation.isLoading
  }, "Opslaan"), /* @__PURE__ */ React.createElement("i", null, "Velden met een ", /* @__PURE__ */ React.createElement("span", {
    style: {color: "var(--sw-rood)"}
  }, "*"), " zijn verplicht"));
};
