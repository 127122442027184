import {session} from "./resources/ockb/session.js";
let lastTagJson = "";
function track(page_path) {
  const tag = {
    page_path,
    client_id: session.user?.id,
    page_location: location.origin + page_path
  };
  if (JSON.stringify(tag) === lastTagJson)
    return;
  lastTagJson = JSON.stringify(tag);
  window.gtag?.("event", "page_view", tag);
}
{
  session.onUser(() => track(location.pathname));
}
{
  addEventListener("popstate", () => track(location.pathname));
  ["push", "replace"].map(function(type) {
    const state = `${type}State`;
    const historyState = history[state];
    history[state] = function() {
      track(arguments[2]);
      return historyState.apply(history, arguments);
    };
  });
}
