import React from "../../../_snowpack/pkg/react.js";
import {MdChevronRight} from "../../../_snowpack/pkg/react-icons/md.js";
import {Card, Text, cssRule, Heading, LinkButton} from "../index.js";
const card = cssRule({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  flex: "0 0 32%",
  padding: "var(--s7) var(--s1)",
  backgroundColor: "var(--color-default-bg)",
  cursor: "pointer"
});
const selectedCard = cssRule({
  backgroundColor: "var(--ockb-blue-light)",
  " .arrow": {
    display: "block"
  }
});
const iconWrapper = cssRule({
  height: "2rem",
  width: "auto"
});
const title = cssRule({
  marginTop: "var(--s-2)",
  marginBottom: "var(--s-2)",
  textAlign: "center",
  fontWeight: 300,
  " p": {
    height: "1.5em"
  }
});
const arrow = cssRule({
  position: "absolute",
  bottom: "-0.5em",
  display: "none",
  height: "1em",
  width: "1em",
  backgroundColor: "inherit",
  transform: "rotate(45deg)"
});
export const ContactActionCard = ({source, order, handleClick}) => {
  return /* @__PURE__ */ React.createElement(Card, {
    className: `${card} ${source.selected ? selectedCard : ""}`,
    onClick: (ev) => {
      ev.preventDefault();
      handleClick(source.id);
    },
    style: {order}
  }, /* @__PURE__ */ React.createElement("img", {
    className: iconWrapper,
    src: source.icon.name
  }), /* @__PURE__ */ React.createElement(Heading, {
    level: "3",
    className: title
  }, /* @__PURE__ */ React.createElement("p", null, source.title.normal), /* @__PURE__ */ React.createElement(Text, {
    className: "heading",
    ektronHTML: source.title.bold
  })), /* @__PURE__ */ React.createElement(LinkButton, {
    icon: MdChevronRight,
    label: source.button?.text || "",
    color: "primary"
  }), /* @__PURE__ */ React.createElement("span", {
    className: `arrow ${arrow}`
  }));
};
