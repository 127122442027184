import * as React from "../../../_snowpack/pkg/react.js";
import {MdOpenInNew} from "../../../_snowpack/pkg/react-icons/md.js";
import {Button, cssRule, cssStack, HeaderExtraBlock, Heading, Text, xdGridLayout} from "../index.js";
import {useNavigation} from "../../utils/hooks.js";
const main = cssRule({
  ...xdGridLayout({
    leftColumnCount: 7,
    leftColumnMinWidth: "calc(var(--measure) * 2 / 3)",
    rightColumnCount: 4
  })
});
const headerExtraBlockContainer = cssRule({
  rowGap: "var(--smax)",
  marginBottom: "clamp(-6em, calc(6em - 20%), -2.5em)"
});
const left = cssStack({
  space: "var(--s2)"
});
const right = cssRule({
  width: "0px",
  img: {
    maxWidth: "100%",
    objectFit: "contain"
  }
});
export const BannerPrimary = ({source, rightContent, children}) => {
  const navigation = useNavigation();
  const hasHeaderExtraBlock = source?.extraTextBlock?.header;
  return /* @__PURE__ */ React.createElement("div", {
    className: `${main} ${hasHeaderExtraBlock ? headerExtraBlockContainer : ""} scale-large`
  }, /* @__PURE__ */ React.createElement("div", {
    className: left
  }, source?.title && /* @__PURE__ */ React.createElement(Heading, {
    level: "1",
    ektronHTML: source.title
  }), source?.body && /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: source?.body
  }), (source?.buttons ?? []).map(({type, text, url}, i) => /* @__PURE__ */ React.createElement("div", {
    key: i
  }, /* @__PURE__ */ React.createElement(Button, {
    primary: true,
    onClick: () => navigation.follow(url)
  }, text, navigation.isExternal(url) ? /* @__PURE__ */ React.createElement(MdOpenInNew, null) : void 0))), children), /* @__PURE__ */ React.createElement("div", {
    className: right
  }, rightContent ? rightContent : hasHeaderExtraBlock ? /* @__PURE__ */ React.createElement(HeaderExtraBlock, {
    content: source.extraTextBlock
  }) : source?.image ? /* @__PURE__ */ React.createElement("img", {
    src: source?.image
  }) : /* @__PURE__ */ React.createElement(React.Fragment, null)));
};
