import * as React from "../../../_snowpack/pkg/react.js";
import {MdAccountCircle, MdClose, MdMenu} from "../../../_snowpack/pkg/react-icons/md.js";
import {Link, useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import {IconContact} from "../index.js";
import {UserType} from "../../constants/ektron.js";
import {
  contactURL,
  locationURL,
  loginURL,
  participantAccountURL,
  rootURL
} from "../../constants/routing.js";
import {session} from "../../resources/ockb/session.js";
import {useAppState} from "../../state/index.js";
import {cssRule} from "../css.js";
export const TopBar = ({isMenuOpen, toggleMenu}) => {
  const {user} = useAppState(({user: user2}) => ({user: user2}));
  const history = useHistory();
  return /* @__PURE__ */ React.createElement("div", {
    className: wrapper
  }, /* @__PURE__ */ React.createElement("nav", null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Link, {
    to: rootURL()
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/assets/img-ockb/Logo.svg"
  }))), /* @__PURE__ */ React.createElement("ul", null, !!user ? /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement("span", {
    className: profileCSS
  }, /* @__PURE__ */ React.createElement(Link, {
    to: user.userType === UserType.Participant ? participantAccountURL() : locationURL()
  }, /* @__PURE__ */ React.createElement(MdAccountCircle, null), " ", user.fullName), "|", /* @__PURE__ */ React.createElement("button", {
    onClick: () => {
      session.logout();
      history.push(rootURL());
    },
    className: signOutCSS
  }, "uitloggen"))) : /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: loginURL()
  }, /* @__PURE__ */ React.createElement(MdAccountCircle, null), " Inloggen")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: contactURL()
  }, /* @__PURE__ */ React.createElement(IconContact, null), "Contact"))), /* @__PURE__ */ React.createElement("button", {
    onClick: toggleMenu
  }, "Menu", isMenuOpen ? /* @__PURE__ */ React.createElement(MdClose, null) : /* @__PURE__ */ React.createElement(MdMenu, null))));
};
const profileCSS = cssRule({
  display: "flex",
  alignItems: "center",
  gap: "var(--s-4)"
});
const signOutCSS = cssRule({
  cursor: "pointer"
});
const wrapper = cssRule({
  position: "relative",
  width: "100%",
  height: "64px",
  background: "linear-gradient(90deg, var(--color-default-bg) calc((100% - 1.5 * var(--measure)) / 2 + 0.3 * var(--measure)), var(--sw-teal) calc(100% - (100% - 1.5 * var(--measure)) / 2))",
  color: "white",
  " > nav": {
    boxSizing: "content-box",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: "calc(1.5 * var(--measure))",
    " > div": {
      position: "relative",
      " > a": {
        position: "absolute",
        top: "var(--s-3)",
        left: "var(--s-3)",
        height: "81px",
        " > img": {
          maxHeight: "100%"
        },
        "@media (max-width: 600px)": {
          height: "calc(60px - (2 * var(--s-2)))"
        }
      }
    },
    " > ul": {
      display: "flex",
      gap: "var(--s0)",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: "var(--s5)",
      " > li, > li > span": {
        listStyle: "none",
        " > a": {
          display: "flex",
          alignItems: "center",
          gap: "var(--s-5)",
          textDecoration: "none",
          color: "var(--sw-wit)",
          "> svg": {
            width: "20px",
            height: "20px"
          }
        }
      },
      "@media (max-width: 600px)": {
        display: "none"
      }
    },
    " > button": {
      display: "none",
      "@media (max-width: 600px)": {
        position: "absolute",
        top: "var(--s4)",
        right: "var(--s4)",
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        gap: "var(--s0)",
        fontSize: "1.5rem"
      }
    }
  },
  "@media (max-width: 600px)": {
    position: "sticky",
    top: 0
  }
});
