import * as React from "../../../_snowpack/pkg/react.js";
export const LogoSWBlackWhite = (props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "99.748",
    height: "44.008",
    viewBox: "0 0 99.748 44.008",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    id: "Logo_SW_gecentreerd",
    "data-name": "Logo SW gecentreerd",
    transform: "translate(0.011 0.005)"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "Laag_1",
    "data-name": "Laag 1",
    transform: "translate(-0.011 -0.005)"
  }, /* @__PURE__ */ React.createElement("path", {
    id: "Path_1547",
    "data-name": "Path 1547",
    d: "M194,47.78c-2.494-4.5-3.862-4.927-7.077-7.978-.082-.076-.162-.154-.238-.232h0l-.092.014a7.215,7.215,0,0,0-1.166.485,4.087,4.087,0,0,0-.971.673,2.092,2.092,0,0,0-.669,1.217,1.729,1.729,0,0,0,0,.386h0a2.44,2.44,0,0,0,.092.443c.023.064.043.129.07.205a1.114,1.114,0,0,0,.045.1,2.994,2.994,0,0,0,.238.433,6.682,6.682,0,0,0,2.117,1.809,21.231,21.231,0,0,0,7.643,2.441",
    transform: "translate(-146.045 -31.445)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1548",
    "data-name": "Path 1548",
    d: "M218.345,50.114a23.836,23.836,0,0,0-2.583,2.3,16.617,16.617,0,0,1-1.486,1.468c-.183.16-.376.316-.581.468h-.012a9.505,9.505,0,0,1-2.49,1.341,13.338,13.338,0,0,1-6.775.086,16.457,16.457,0,0,1-5.067-2.053c-.156-.119-.314-.246-.47-.376a11.567,11.567,0,0,0,22.427-3.981,11.39,11.39,0,0,0-.154-1.807,20.268,20.268,0,0,1-2.809,2.554",
    transform: "translate(-158.047 -37.795)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1549",
    "data-name": "Path 1549",
    d: "M225.481,3.634a.372.372,0,0,0-.638.041,4.482,4.482,0,0,1-1.187,1.757,5.177,5.177,0,0,1-.9.647,11.565,11.565,0,0,0-20.315-.1c2.7,1.493,5.683,3.225,6.237,3.9,2,2.431,4,5.1,4,5.1a2.747,2.747,0,0,0,.443.454,1.775,1.775,0,0,0,2.23.292c.464-.232,1.2-.965,1.665-1.232a3.7,3.7,0,0,1,.877-.316,9.651,9.651,0,0,1,.971-.191.055.055,0,0,0,.014-.1,5.109,5.109,0,0,0-.928-.228h0a2.016,2.016,0,0,0-.495-.328,1.989,1.989,0,0,0-1.608-.127s.739-.663,1.77-1.56a64.634,64.634,0,0,1,5.194-4.219c.15-.119.292-.23.421-.339a6.234,6.234,0,0,0,1.959-2.589.181.181,0,0,1,.335.029,4.494,4.494,0,0,1,.1,2.258h.012c-.062.308-.343,1.2.039.308a3.3,3.3,0,0,0-.2-3.455",
    transform: "translate(-160.876 0.005)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1550",
    "data-name": "Path 1550",
    d: "M-.009,166.619v-.109a.333.333,0,0,1,.37-.37H2.551c.24,0,.37.043.37.131v.152c0,.628.76,1.15,1.848,1.15,1,0,1.642-.433,1.642-1.027,0-.868-1.642-.846-3.256-1.5C1.679,164.549.1,163.818.1,161.88c0-2.17,1.866-3.49,4.558-3.49,2.8,0,4.644,1.456,4.644,3.517,0,.152-.129.261-.37.261H6.805c-.238,0-.367-.043-.367-.131v-.129c0-.63-.651-1.129-1.759-1.129-.932,0-1.642.347-1.642.977,0,.868,1.519.932,3.125,1.437,1.714.5,3.342,1.261,3.342,3.256,0,2.193-1.909,3.408-4.71,3.408-2.954,0-4.8-1.351-4.8-3.238",
    transform: "translate(0.011 -125.871)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1551",
    "data-name": "Path 1551",
    d: "M56.957,162.12a2.875,2.875,0,0,0-.131.542c-.021.086.023.129.109.129h3.7c.088,0,.131-.043.109-.129a2.071,2.071,0,0,0-.086-.456,1.848,1.848,0,0,0-1.889-1.367,1.741,1.741,0,0,0-1.8,1.281m4.558,4.014a.321.321,0,0,1,.519-.043l1.232,1.193a.355.355,0,0,1,.043.521,5.42,5.42,0,0,1-4.274,1.909,4.881,4.881,0,0,1-5.079-3.819,7.527,7.527,0,0,1-.261-2.018,7.915,7.915,0,0,1,.238-2.127,4.837,4.837,0,0,1,4.862-3.56c2.93,0,4.517,1.866,4.993,4.8.064.478.107,1.027.129,1.585a.333.333,0,0,1-.37.37H56.892a.115.115,0,0,0-.131.129,3.558,3.558,0,0,0,.154.673,2.318,2.318,0,0,0,2.408,1.39,2.877,2.877,0,0,0,2.193-1",
    transform: "translate(-42.672 -125.712)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1552",
    "data-name": "Path 1552",
    d: "M122.379,162.282v7.009a.331.331,0,0,1-.368.37H119.69a.333.333,0,0,1-.37-.37V162.91a1.766,1.766,0,0,0-1.757-1.952,1.787,1.787,0,0,0-1.8,1.952v6.381a.331.331,0,0,1-.367.37h-2.322a.333.333,0,0,1-.37-.37V158.874a.333.333,0,0,1,.37-.37h2.322a.333.333,0,0,1,.368.37v.616c0,.109.066.152.131.066a3.057,3.057,0,0,1,2.626-1.215,3.657,3.657,0,0,1,3.862,3.95",
    transform: "translate(-89.559 -125.831)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1553",
    "data-name": "Path 1553",
    d: "M170.047,151.7V141.287a.333.333,0,0,1,.37-.37h2.322a.333.333,0,0,1,.37.37V151.7a.333.333,0,0,1-.37.37h-2.322a.333.333,0,0,1-.37-.37m-.347-13.673a1.867,1.867,0,1,1,1.866,1.823,1.754,1.754,0,0,1-1.866-1.823",
    transform: "translate(-134.858 -108.244)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1554",
    "data-name": "Path 1554",
    d: "M203.8,165.549a5.559,5.559,0,0,0,.172-1.519,6.143,6.143,0,0,0-.172-1.542,1.988,1.988,0,0,0-3.884,0,6.8,6.8,0,0,0,0,3.061,1.973,1.973,0,0,0,3.884,0m-6.837.7a7.921,7.921,0,0,1-.3-2.213,7.534,7.534,0,0,1,.326-2.3,5.183,5.183,0,0,1,9.746-.023,7.6,7.6,0,0,1,.324,2.281,8.729,8.729,0,0,1-.283,2.193,5.144,5.144,0,0,1-9.809.066",
    transform: "translate(-156.28 -125.799)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1555",
    "data-name": "Path 1555",
    d: "M263.626,158.653a.361.361,0,0,1,.205.456l-.411,2.279c-.043.261-.172.283-.433.24a2.7,2.7,0,0,0-1.129-.045,1.925,1.925,0,0,0-1.866,1.932v5.816a.333.333,0,0,1-.367.37h-2.334a.333.333,0,0,1-.37-.37V158.914a.333.333,0,0,1,.37-.37H259.6a.333.333,0,0,1,.368.37v.673c0,.109.066.152.152.064a2.726,2.726,0,0,1,2.355-1.258,2.246,2.246,0,0,1,1.15.261",
    transform: "translate(-204.17 -125.871)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1556",
    "data-name": "Path 1556",
    d: "M298.719,169.972l-3.061-10.44c-.066-.261.043-.411.3-.411h2.388a.384.384,0,0,1,.411.326l1.5,6.418c.023.109.152.129.174,0l1.605-6.424a.384.384,0,0,1,.411-.326h2.127a.411.411,0,0,1,.411.326l1.642,6.447c.023.129.152.129.175,0l1.642-6.447a.411.411,0,0,1,.411-.326l2.388.023c.259,0,.367.172.3.411l-3.086,10.423a.411.411,0,0,1-.411.3h-2.433a.411.411,0,0,1-.411-.3l-1.626-5.687c-.045-.107-.152-.129-.174,0l-1.437,5.664a.411.411,0,0,1-.411.326h-2.431a.4.4,0,0,1-.411-.3",
    transform: "translate(-234.941 -126.446)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1557",
    "data-name": "Path 1557",
    d: "M379.967,162.12a2.873,2.873,0,0,0-.131.542c-.021.086.023.129.109.129h3.7c.088,0,.131-.043.109-.129a2.072,2.072,0,0,0-.086-.456,1.848,1.848,0,0,0-1.889-1.367,1.741,1.741,0,0,0-1.8,1.281m4.558,4.014a.321.321,0,0,1,.519-.043l1.232,1.193a.355.355,0,0,1,.043.521,5.414,5.414,0,0,1-4.274,1.909,4.882,4.882,0,0,1-5.079-3.819,7.61,7.61,0,0,1-.261-2.018,7.827,7.827,0,0,1,.24-2.127,4.833,4.833,0,0,1,4.86-3.56c2.93,0,4.517,1.866,4.993,4.8.066.478.109,1.027.129,1.585a.333.333,0,0,1-.367.37H379.9a.115.115,0,0,0-.129.129,3.425,3.425,0,0,0,.152.673,2.318,2.318,0,0,0,2.408,1.39,2.876,2.876,0,0,0,2.193-1",
    transform: "translate(-299.366 -125.712)",
    fill: "#002944"
  }), /* @__PURE__ */ React.createElement("path", {
    id: "Path_1558",
    "data-name": "Path 1558",
    d: "M442.953,149.124a4.465,4.465,0,0,0-.521-2.279,1.692,1.692,0,0,0-1.517-.821,1.661,1.661,0,0,0-1.456.846,5.619,5.619,0,0,0-.021,4.385,1.642,1.642,0,0,0,1.476.932,1.731,1.731,0,0,0,1.583-.932,4.541,4.541,0,0,0,.456-2.127m3.213-.066a7.843,7.843,0,0,1-.37,2.6,4.357,4.357,0,0,1-4.188,3.192,2.91,2.91,0,0,1-2.474-1.174c-.066-.086-.152-.064-.152.045v.585a.333.333,0,0,1-.37.37h-2.322a.333.333,0,0,1-.37-.37V139.861a.333.333,0,0,1,.37-.37H438.6a.333.333,0,0,1,.37.37v4.472c0,.107.086.15.152.064a2.987,2.987,0,0,1,2.474-1.063,4.291,4.291,0,0,1,4.145,3.039,7.7,7.7,0,0,1,.411,2.692",
    transform: "translate(-346.42 -110.85)",
    fill: "#002944"
  }))));
};
