import {
  cookieNoticeURL,
  disclaimerURL,
  privacyPolicyURL
} from "./routing.js";
export const seniorWebLinks = [
  {label: "SeniorWeb.nl", link: "https://www.seniorweb.nl/"},
  {label: "Over SeniorWeb", link: "https://www.seniorweb.nl/over-seniorweb"},
  {
    label: "Lidmaatschap",
    link: "https://www.seniorweb.nl/lid-worden"
  },
  {
    label: "Digitale hulp",
    link: "https://www.seniorweb.nl/pchulp"
  }
];
export const commonLinks = [
  {
    label: "Cookies",
    link: cookieNoticeURL()
  },
  {
    label: "Disclaimer",
    link: disclaimerURL()
  },
  {
    label: "Privacybeleid",
    link: privacyPolicyURL()
  }
];
