import * as React from "../../../_snowpack/pkg/react.js";
import {cssRule} from "../css.js";
const main = cssRule({});
export const Select = ({
  className,
  title,
  options,
  value: selectedValue,
  onChange
}) => /* @__PURE__ */ React.createElement("select", {
  className: className ? className : main,
  title,
  onChange: (e) => onChange(e.target.value),
  defaultValue: selectedValue
}, options.map(({value, title: title2 = value}) => /* @__PURE__ */ React.createElement("option", {
  key: value,
  value
}, title2)));
