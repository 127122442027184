import classNames from "../../../_snowpack/pkg/classnames.js";
import * as React from "../../../_snowpack/pkg/react.js";
import {
  MdExpandLess,
  MdExpandMore,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineUnfoldLess,
  MdOutlineUnfoldMore
} from "../../../_snowpack/pkg/react-icons/md.js";
import {Heading, LinkButton} from "../index.js";
import {cssRule} from "../css.js";
const main = cssRule({
  background: "var(--color-default-bg)",
  display: "flex",
  gap: "var(--s0)",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "var(--border-thin) solid var(--color-line)",
  marginBottom: "var(--smin)",
  cursor: "pointer",
  color: "var(--sw-teal)"
});
const optionItem = cssRule({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: "var(--s-3)",
  color: "var(--ockb-text-light)",
  ":hover": {
    color: "var(--color-default)"
  },
  "> svg.checked": {
    color: "var(--color-default)"
  }
});
export const MultiSelect = ({
  title,
  options,
  selection = [],
  onChange,
  showMoreThreshold = 7
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [showMore, setShowMore] = React.useState(false);
  const selectedOptions = options.filter(({value}) => selection.includes(value));
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: main,
    onClick: () => setIsOpen(!isOpen)
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "4"
  }, title, !isOpen && selectedOptions.length ? ` (${selectedOptions.length})` : ``), isOpen ? /* @__PURE__ */ React.createElement(MdExpandLess, null) : /* @__PURE__ */ React.createElement(MdExpandMore, null)), isOpen ? /* @__PURE__ */ React.createElement("div", null, options.slice(0, Math.max(selectedOptions.length, showMore ? Infinity : showMoreThreshold)).map(({value, title: title2 = value}) => {
    const checked = selection.includes(value);
    return /* @__PURE__ */ React.createElement("div", {
      key: value,
      className: optionItem,
      onClick: () => onChange(!checked, value)
    }, checked ? /* @__PURE__ */ React.createElement(MdOutlineCheckBox, {
      className: classNames({checked}),
      viewBox: "3 3 18 18"
    }) : /* @__PURE__ */ React.createElement(MdOutlineCheckBoxOutlineBlank, {
      className: classNames({checked}),
      viewBox: "3 3 18 18"
    }), title2);
  }), options.length <= showMoreThreshold ? /* @__PURE__ */ React.createElement(React.Fragment, null) : /* @__PURE__ */ React.createElement("div", {
    style: {marginTop: "var(--smin)"}
  }, showMore ? /* @__PURE__ */ React.createElement(LinkButton, {
    icon: () => /* @__PURE__ */ React.createElement(MdOutlineUnfoldLess, {
      viewBox: "3 3 18 18"
    }),
    label: "Minder",
    onClick: () => setShowMore(!showMore),
    color: "primary"
  }) : /* @__PURE__ */ React.createElement(LinkButton, {
    icon: () => /* @__PURE__ */ React.createElement(MdOutlineUnfoldMore, {
      viewBox: "3 3 18 18"
    }),
    label: "Meer",
    onClick: () => setShowMore(!showMore),
    color: "primary"
  }))) : /* @__PURE__ */ React.createElement(React.Fragment, null));
};
