import React, {useState} from "../../../_snowpack/pkg/react.js";
import {MdDelete, MdHelp} from "../../../_snowpack/pkg/react-icons/md.js";
import {
  CourseProgressLegend,
  cssRule,
  cssStack,
  DeleteCourseProgressDialog,
  LinkButton
} from "../index.js";
import {CourseNavigationChapter} from "../molecules/CourseNavigationChapter.js";
const aside = cssRule({
  display: "flex",
  flexDirection: "column",
  alignSelf: "start",
  position: "sticky",
  top: "8rem",
  gap: "var(--s4)",
  "@media (max-width: 600px)": {
    position: "unset"
  }
});
export const CourseNavigation = ({course}) => {
  const [openLegend, setOpenLegend] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const handleLegend = () => setOpenLegend(!openLegend);
  const handleOpenRemoveDialog = () => setOpenRemoveDialog(true);
  const handleCloseRemoveDialog = () => setOpenRemoveDialog(false);
  return /* @__PURE__ */ React.createElement("aside", {
    className: aside
  }, /* @__PURE__ */ React.createElement(DeleteCourseProgressDialog, {
    course,
    open: openRemoveDialog,
    handleClose: handleCloseRemoveDialog
  }), /* @__PURE__ */ React.createElement("div", null, course.chapters.map((chapter) => /* @__PURE__ */ React.createElement(CourseNavigationChapter, {
    key: chapter.slug,
    course,
    chapter
  }))), /* @__PURE__ */ React.createElement("div", {
    className: cssStack({space: "var(--s1)"})
  }, /* @__PURE__ */ React.createElement(LinkButton, {
    color: "danger",
    label: "Cursusvoortgang wissen",
    onClick: handleOpenRemoveDialog,
    icon: MdDelete
  }), /* @__PURE__ */ React.createElement(LinkButton, {
    color: "primary",
    label: "Wat betekenen de iconen?",
    onClick: handleLegend,
    icon: MdHelp
  })), openLegend && /* @__PURE__ */ React.createElement(CourseProgressLegend, null));
};
