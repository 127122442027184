import React, {useState} from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {MdAccountCircle, MdPersonRemove, MdVpnKey} from "../../_snowpack/pkg/react-icons/md.js";
import {
  BannerPrimary,
  BannerSecondary,
  Breadcrumbs,
  cssRule,
  cssSidebar,
  Heading,
  LocationForm,
  LoginDataForm,
  NavigationList,
  Section,
  Text
} from "../components/index.js";
import {UserType} from "../constants/ektron.js";
import {useContentPageData} from "../resources/ockb/index.js";
import {useAppState, useGuardUserType} from "../state/index.js";
const card = cssRule({
  display: "flex",
  flexDirection: "column",
  gap: "var(--s1)",
  backgroundColor: "var(--ockb-teal-medium)",
  padding: "var(--s1)"
});
const sidebarWrapper = cssSidebar({
  contentMinimumWidth: 100 / 12 * 6,
  sideWidth: "calc(var(--section-width) / 12 * 4)",
  space: "calc(var(--section-width) / 12)"
});
const divider = cssRule({
  borderColor: "var(--ockb-input-border-color)",
  borderTop: "none"
});
const crumbs = [
  {name: "Home", path: "/"},
  {name: "Locatiegegevens", path: "/locatie"}
];
export const LocationPage = () => {
  useGuardUserType(UserType.Library);
  const user = useAppState(({user: user2}) => user2);
  const [tab, _setTab] = useState(sessionStorage.getItem("locationTab") || "location");
  const {data: contentPage} = useContentPageData();
  const setTab = (tab2) => (sessionStorage.setItem("locationTab", tab2), _setTab(tab2));
  const getTitle = (tab2) => navigationItems.find((item) => item.tab === tab2)?.label ?? "";
  const navigationItems = [
    {
      icon: /* @__PURE__ */ React.createElement(MdAccountCircle, {
        size: "1.25rem"
      }),
      label: "Mijn locatiegegevens",
      tab: "location"
    },
    {
      icon: /* @__PURE__ */ React.createElement(MdVpnKey, {
        size: "1.25rem"
      }),
      label: "Wachtwoord aanpassen",
      tab: "login"
    },
    {
      icon: /* @__PURE__ */ React.createElement(MdPersonRemove, {
        size: "1.25rem"
      }),
      label: contentPage?.profile?.deleteAccount.header ?? "",
      tab: "delete"
    }
  ];
  const getDescription = (tab2) => {
    switch (tab2) {
      case "location":
        return "Geef hieronder je wijzigingen door en houd je gegevens actueel.";
      case "login":
        return "Pas hieronder de gegevens aan waarmee je op deze website inlogt.";
      case "delete":
        return contentPage?.profile?.deleteAccount.body ?? "";
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, "Locatiegegevens | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, contentPage && /* @__PURE__ */ React.createElement(BannerPrimary, {
    source: contentPage.profile?.intro
  })), /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement(Breadcrumbs, {
    crumbs
  })), /* @__PURE__ */ React.createElement(Section, null, /* @__PURE__ */ React.createElement("div", {
    className: sidebarWrapper
  }, /* @__PURE__ */ React.createElement(NavigationList, {
    navigationItems,
    onClick: (tab2) => setTab(tab2),
    selected: tab
  }), /* @__PURE__ */ React.createElement("div", {
    className: card
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "3",
    ektronHTML: getTitle(tab)
  }), /* @__PURE__ */ React.createElement("hr", {
    className: divider
  }), /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: getDescription(tab)
  }), tab === "location" && /* @__PURE__ */ React.createElement(LocationForm, null), tab === "login" && /* @__PURE__ */ React.createElement(LoginDataForm, {
    email: user?.username
  })))), /* @__PURE__ */ React.createElement(Section, {
    color: user != null ? "mediumblue" : "mediumteal"
  }, contentPage?.profile?.footerCTA && /* @__PURE__ */ React.createElement(BannerSecondary, {
    source: contentPage.profile.footerCTA
  })));
};
