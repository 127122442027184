import React from "../../../_snowpack/pkg/react.js";
import {MdOpenInNew, MdChevronRight} from "../../../_snowpack/pkg/react-icons/md.js";
import {
  Card,
  LinkButton,
  Text,
  cssRule,
  Heading,
  Button as ButtonComponent
} from "../index.js";
const popOut = cssRule({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap"
});
const imageWrapper = cssRule({
  display: "flex",
  alignItems: "center",
  width: "12em",
  " img": {
    width: "100%",
    verticalAlign: "middle"
  }
});
const bodyText = cssRule({
  maxWidth: "none",
  " p:not(:last-child)": {
    marginBottom: "var(--s1)"
  },
  " ul": {
    listStyleType: "none"
  },
  " li::before": {
    content: "url(assets/icons-shared/icon-list-item.svg)",
    verticalAlign: "middle",
    display: "inline-block",
    marginRight: "var(--s0)"
  }
});
const buttonWrapper = cssRule({
  justifySelf: "center",
  " a": {
    textDecoration: "none",
    ":not(#linkbuttonWrapper)": {
      color: "var(--color-primary)"
    }
  }
});
const forceRow = cssRule({
  display: "flex",
  alignItems: "baseline"
});
const iconWrapper = cssRule({
  display: "inline-block",
  verticalAlign: "text-top",
  marginLeft: "var(--s-4)"
});
const maxButtons = (items) => {
  let maxButtons2 = 0;
  items.forEach((item) => {
    const amountOfButtons = item.buttons?.length ?? 0;
    if (amountOfButtons > maxButtons2)
      maxButtons2 = amountOfButtons;
  });
  return maxButtons2;
};
export const ContactActionItemPopOut = ({items}) => {
  const card = cssRule({
    display: "grid",
    gridTemplateRows: `4em auto repeat(${maxButtons(items)}, 2.5em)`,
    gap: "var(--s6)",
    flex: 1,
    minWidth: "25em",
    ":not(:first-child)": {
      borderLeft: "2px solid var(--ockb-teal-light)"
    }
  });
  return /* @__PURE__ */ React.createElement("div", {
    className: popOut
  }, items.map((item) => /* @__PURE__ */ React.createElement(Card, {
    key: item.title,
    className: card
  }, /* @__PURE__ */ React.createElement("div", {
    className: imageWrapper
  }, /* @__PURE__ */ React.createElement("img", {
    src: item.image,
    alt: "user"
  })), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    level: "3"
  }, item.title), /* @__PURE__ */ React.createElement(Text, {
    className: bodyText,
    ektronHTML: item.body
  })), item.buttons?.map((button) => /* @__PURE__ */ React.createElement("span", {
    key: button.text,
    className: buttonWrapper
  }, button.type == "LinkButton" ? /* @__PURE__ */ React.createElement("a", {
    id: "linkbuttonWrapper",
    className: forceRow,
    href: button.url,
    target: "_blank"
  }, /* @__PURE__ */ React.createElement(LinkButton, {
    color: "primary",
    icon: MdChevronRight,
    label: button.text
  }), /* @__PURE__ */ React.createElement("span", {
    className: iconWrapper
  }, /* @__PURE__ */ React.createElement(MdOpenInNew, null))) : /* @__PURE__ */ React.createElement(ButtonComponent, {
    primary: button.type === "ButtonPrimary"
  }, /* @__PURE__ */ React.createElement("a", {
    href: button.url,
    target: "_blank"
  }, button.text, /* @__PURE__ */ React.createElement("span", {
    className: iconWrapper
  }, /* @__PURE__ */ React.createElement(MdOpenInNew, null)))))))));
};
