import React, {useRef, useState} from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {useForm} from "../../_snowpack/pkg/react-hook-form.js";
import {MdChevronLeft} from "../../_snowpack/pkg/react-icons/md.js";
import {useHistory} from "../../_snowpack/pkg/react-router.js";
import {useLocation} from "../../_snowpack/pkg/react-router-dom.js";
import {AuthLayout, Button, cssRule, cssStack, Input, LinkButton, Spinner} from "../components/index.js";
import {resetPassword, useUpdateCredentialsAPI} from "../resources/ockb/index.js";
import {session} from "../resources/ockb/session.js";
import {useAlert} from "../state/alert.js";
import {errorMessage, minMax, required} from "../utils/formValidation.js";
const wrapper = cssRule({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "var(--ockb-teal-light)",
  paddingTop: "calc(var(--s5) * 2)",
  paddingBottom: "calc(var(--s5) * 2)",
  "@media (max-width: 600px)": {
    paddingLeft: "var(--s0)",
    paddingRight: "var(--s0)"
  }
});
const card = cssRule({
  maxWidth: "410px",
  width: "100%"
});
const displayCenter = cssRule({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "var(--s3)"
});
const list = cssRule({
  paddingLeft: "var(--s0)"
});
export const ResetPasswordPage = () => {
  const {
    register,
    formState: {errors},
    handleSubmit,
    watch
  } = useForm({});
  const {search} = useLocation();
  const passwordForgetKey = search.split("?key=")[1];
  const history = useHistory();
  const password = useRef({});
  const [loading, setLoading] = useState(false);
  const {setAlert} = useAlert();
  const {data: keyInfo, error: keyError} = useUpdateCredentialsAPI(passwordForgetKey);
  React.useEffect(() => {
    session.logout();
  }, []);
  password.current = watch("password", "");
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await resetPassword({newPassword: data.password, passwordForgetKey});
      history.push("/inloggen?passwordResetSuccess=true");
    } catch (error) {
      setAlert({open: true, message: "Er is een onbekende fout opgetreden.", variant: "error"});
    } finally {
      setLoading(false);
    }
  };
  return /* @__PURE__ */ React.createElement(AuthLayout, null, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, "Wachtwoord resetten | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement("div", {
    className: wrapper
  }, /* @__PURE__ */ React.createElement("div", {
    className: card
  }, /* @__PURE__ */ React.createElement("form", {
    className: cssStack(),
    onSubmit: handleSubmit(onSubmit)
  }, /* @__PURE__ */ React.createElement("h2", null, "Wachtwoord resetten"), keyInfo ? keyInfo.validUntil < keyInfo.currentDateTime ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", null, "Deze link om je inloggegevens aan te passen is verlopen. Je kunt een nieuwe resetlink aanvragen met de knop hieronder."), /* @__PURE__ */ React.createElement(Button, {
    withBorder: true,
    type: "button",
    onClick: () => history.push("/inlog-vergeten")
  }, "Resetlink opnieuw aanvragen")) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", null, "U kunt uw wachtwoord nog resetten tot", " ", new Date(keyInfo.validUntil).toLocaleTimeString(), " uur."), /* @__PURE__ */ React.createElement("p", null, "Geef hieronder een nieuw wachtwoord op. Deze moet voldoen aan de volgende eisen:"), /* @__PURE__ */ React.createElement("ul", {
    className: list
  }, /* @__PURE__ */ React.createElement("li", null, "Tenminste 8 karakters lang"), /* @__PURE__ */ React.createElement("li", null, "Bevat kleine letters, hoofdletters, cijfers en speciale karakters")), /* @__PURE__ */ React.createElement(Input, {
    type: "password",
    placeholder: "Wachtwoord *",
    ...register("password", {
      required: {message: "Dit veld is vereist", value: true},
      minLength: {message: "Minimale lengte is 6 karakters", value: 6}
    })
  }), errors.password && /* @__PURE__ */ React.createElement("span", null, errors.password.type === "required" && errorMessage(required("password")), errors.password.type === "minLength" && errorMessage(minMax("min", 6))), /* @__PURE__ */ React.createElement(Input, {
    type: "password",
    placeholder: "Herhaal wachtwoord *",
    ...register("passwordRepeated", {
      required: {message: "Dit veld is vereist", value: true},
      validate: (value) => value === password.current || "De wachtwoorden komen niet overeen"
    })
  }), errors.passwordRepeated && /* @__PURE__ */ React.createElement("div", {
    className: "error-message"
  }, errors.passwordRepeated.message), /* @__PURE__ */ React.createElement(Button, {
    primary: true,
    loading
  }, "Wachtwoord wijzigen"), /* @__PURE__ */ React.createElement("div", {
    className: displayCenter
  }, /* @__PURE__ */ React.createElement(LinkButton, {
    label: "Naar inloggen",
    color: "primary",
    icon: MdChevronLeft,
    onClick: () => history.push("/inloggen")
  }), /* @__PURE__ */ React.createElement("i", null, "Velden met een * zijn verplicht"))) : /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement(Spinner, null), " Uw sleutel wordt gecontroleerd..."))))));
};
