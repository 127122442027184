import React from "../../../_snowpack/pkg/react.js";
import {Heading, Text} from "./index.js";
import {cssStack} from "../index.js";
const main = cssStack({}, {
  padding: "var(--s1)",
  backgroundColor: "var(--ockb-teal-light)"
});
export const Explanation = ({explanation}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: main
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "4"
  }, "Toelichting"), /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: explanation
  }));
};
