import * as React from "../../../_snowpack/pkg/react.js";
import {Card, Heading, Text} from "./index.js";
import {cssRule} from "../css.js";
const card = cssRule({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: "var(--s6)",
  paddingTop: "var(--s7)",
  textAlign: "center",
  "> div, img, h4, a": {
    padding: "0.2em 0"
  }
});
const stickOutImage = cssRule({
  alignSelf: "center",
  position: "absolute",
  top: "calc(-1 * var(--s7))",
  height: "6em"
});
export const HeaderExtraBlock = ({content}) => {
  const {header, body, image} = content;
  return /* @__PURE__ */ React.createElement(Card, {
    className: `${card} scale-small`,
    color: "blue"
  }, /* @__PURE__ */ React.createElement("img", {
    className: stickOutImage,
    src: image
  }), /* @__PURE__ */ React.createElement(Heading, {
    level: "3"
  }, header), /* @__PURE__ */ React.createElement(Text, {
    className: card,
    ektronHTML: body
  }));
};
