import React, {useState} from "../../../_snowpack/pkg/react.js";
import {useForm} from "../../../_snowpack/pkg/react-hook-form.js";
import {Button, cssRule, Heading, Input, TextArea} from "../index.js";
import {postMailSendCourseQuestion} from "../../resources/ockb/index.js";
import {useAppState} from "../../state/index.js";
import {errorMessage, minMax, required} from "../../utils/formValidation.js";
import {lineBreakToBr} from "../../utils/string.js";
const courseQuestionPopout = cssRule({
  display: "flex",
  flexDirection: "column",
  gap: "var(--s1)"
});
const heading = cssRule({
  fontSize: "var(--font-size-copy)"
});
const form = cssRule({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "var(--s1)",
  " >:first-child": {
    width: "66%"
  }
});
const buttonWrapper = cssRule({
  alignSelf: "flex-end",
  " input": {
    fontSize: "var(--font-size-copy)"
  }
});
export const CourseQuestion = ({courseId, courseItemId, handleAfterSend: showFeedback}) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const user = useAppState(({user: user2}) => user2);
  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm();
  const onSubmit = async (data) => {
    const modifiedData = {...data, text: lineBreakToBr(data.text)};
    setIsRequesting(true);
    try {
      await postMailSendCourseQuestion(modifiedData);
      showFeedback("success");
    } catch (error) {
      showFeedback("error");
    } finally {
      setIsRequesting(false);
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: courseQuestionPopout
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "3",
    className: heading
  }, /* @__PURE__ */ React.createElement("p", null, "Stel je vraag")), /* @__PURE__ */ React.createElement("p", null, "Vul hieronder je vraag in. Wij streven ernaar binnen drie werkdagen te reageren."), /* @__PURE__ */ React.createElement("form", {
    className: form,
    onSubmit: handleSubmit(onSubmit)
  }, /* @__PURE__ */ React.createElement(Input, {
    placeholder: "Naam",
    type: "text",
    required: true,
    value: user?.fullName,
    disabled: true
  }), /* @__PURE__ */ React.createElement(TextArea, {
    placeholder: "Je vraag",
    required: true,
    ...register("text", {
      required: true,
      minLength: 10,
      maxLength: 1e3
    })
  }), errors.text && /* @__PURE__ */ React.createElement("span", null, errors.text.type === "required" && errorMessage(required("vraag")), errors.text.type === "minLength" && errorMessage(minMax("min", 10)), errors.text.type === "maxLength" && errorMessage(minMax("max", 1e3))), /* @__PURE__ */ React.createElement("input", {
    hidden: true,
    ...register("courseId"),
    value: courseId
  }), /* @__PURE__ */ React.createElement("input", {
    hidden: true,
    ...register("courseItemId"),
    name: "courseItemId",
    value: courseItemId
  }), /* @__PURE__ */ React.createElement("span", {
    className: buttonWrapper
  }, /* @__PURE__ */ React.createElement(Button, {
    primary: true,
    inverted: isRequesting,
    loading: isRequesting
  }, /* @__PURE__ */ React.createElement("input", {
    disabled: isRequesting,
    type: "submit",
    value: "Versturen"
  })))));
};
