import React from "../../../_snowpack/pkg/react.js";
import {Helmet} from "../../../_snowpack/pkg/react-helmet.js";
import {Breadcrumbs, cssRule, cssStack, Heading, Section, Text} from "../index.js";
import {useScrollMargin} from "../../hooks/useScrollMargin.js";
import {generateSidebar} from "../../styling/every-layout/sidebar.js";
import {BannerPrimary} from "./index.js";
const wrapper = cssStack({space: "var(--s6)"});
const content = cssRule({
  paddingBottom: "var(--s6)",
  borderBottom: "var(--border-thin) solid var(--color-line-light)",
  ...generateSidebar({
    contentMinimumWidth: 100 / 12 * 6,
    sideWidth: "calc(var(--section-width) / 12 * 4)",
    space: "calc(var(--section-width) / 12)"
  })
});
const inhoudsopgaveLink = cssRule({
  color: "var(--ockb-text-light)",
  textDecoration: "none",
  ":hover": {
    textDecoration: "underline"
  }
});
const kebabize = (str) => {
  return str.split("").map((letter, idx) => {
    return letter.toUpperCase() === letter ? `${idx !== 0 ? "-" : ""}${letter.toLowerCase()}` : letter;
  }).join("");
};
export const ContentModelPage = ({model}) => {
  const source = model.body ?? "";
  const elt = document.createElement("div");
  elt.innerHTML = source;
  const texts = Array.from(elt.children).filter((child) => child.tagName === "H2").map((child) => {
    return [child.id, child.innerHTML];
  });
  const scrollMargin = useScrollMargin();
  const contentRef = React.useRef(null);
  React.useEffect(() => {
    for (const e of contentRef.current?.getElementsByTagName("h2") ?? []) {
      for (const [prop, value] of Object.entries(scrollMargin)) {
        e.style[kebabize(prop)] = value;
      }
    }
  }, [contentRef.current, scrollMargin]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, model.header, " | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, /* @__PURE__ */ React.createElement(BannerPrimary, {
    source: {
      title: model.header,
      body: model.intro,
      image: model.image
    }
  })), /* @__PURE__ */ React.createElement(Section, {
    className: wrapper
  }, /* @__PURE__ */ React.createElement(Breadcrumbs, {
    crumbs: [
      {name: "Home", path: "/"},
      {name: model.header || "", path: ""}
    ]
  }), /* @__PURE__ */ React.createElement("div", {
    className: content
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    style: {
      position: "sticky",
      top: scrollMargin.scrollMarginTop
    }
  }, /* @__PURE__ */ React.createElement(Heading, {
    level: "4",
    style: {
      color: "var(--sw-teal)"
    }
  }, "Inhoudsopgave"), /* @__PURE__ */ React.createElement("div", null, texts.map(([id, kopje]) => kopje ? /* @__PURE__ */ React.createElement("a", {
    href: `#${id}`,
    key: kopje,
    className: inhoudsopgaveLink
  }, /* @__PURE__ */ React.createElement(Text, null, kopje.replace(/^\s*<b>/, "").replace(/<\/b>$/, ""))) : void 0)))), /* @__PURE__ */ React.createElement("div", {
    ref: contentRef
  }, /* @__PURE__ */ React.createElement(Text, {
    ektronHTML: source
  })))));
};
