import React, {useState} from "../../../_snowpack/pkg/react.js";
import {cssRule, ContactActionCard, ContactQuestionPopout, ContactActionItemPopOut} from "../index.js";
import {useAppState} from "../../state/index.js";
import {useAlert} from "../../state/alert.js";
const itemCards = cssRule({
  display: "flex",
  gap: "var(--s0)",
  flexWrap: "wrap",
  "@media (max-width: 600px)": {
    flexDirection: "column"
  }
});
const popOutContainer = cssRule({
  flexBasis: "100%",
  margin: "var(--s1) 0",
  padding: "var(--s3)",
  order: 0,
  backgroundColor: "var(--color-default-bg)"
});
const popOutContainerSelected = (order) => cssRule({
  order: 4,
  "@media (max-width: 600px)": {
    order
  }
});
export const ContactActionItems = ({items}) => {
  const user = useAppState(({user: user2}) => user2);
  const {setAlert} = useAlert();
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [questionInput, setQuestionInput] = useState({
    name: user?.fullName ?? "",
    email: user?.username ?? "",
    question: ""
  });
  const handleAfterSend = (feedback) => {
    setAlert({open: true, message: feedback.text, variant: feedback.type});
    feedback.type == "success" && setQuestionInput({name: "", email: "", question: ""});
    setSelectedItemId(null);
  };
  const selectedIndex = items.findIndex((item) => item.id === selectedItemId);
  const selectedItem = items[selectedIndex];
  const handleClickActionItem = (id) => {
    if (id === selectedItemId) {
      setSelectedItemId(null);
      return;
    }
    setSelectedItemId(id);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: itemCards
  }, items.map((contactActionItem, index) => /* @__PURE__ */ React.createElement(ContactActionCard, {
    key: contactActionItem.id,
    source: {
      ...contactActionItem,
      selected: contactActionItem.id === selectedItemId
    },
    order: index,
    handleClick: handleClickActionItem
  })), selectedItem && /* @__PURE__ */ React.createElement("div", {
    className: `${popOutContainer} ${popOutContainerSelected(selectedIndex)}`
  }, selectedItem.connectedContent != null && selectedItem.connectedContent.length != 0 ? /* @__PURE__ */ React.createElement(ContactActionItemPopOut, {
    items: selectedItem.connectedContent
  }) : /* @__PURE__ */ React.createElement(ContactQuestionPopout, {
    input: questionInput,
    handleInputChange: (formInput) => setQuestionInput(formInput),
    handleAfterSend: (feedback) => handleAfterSend(feedback)
  }))));
};
