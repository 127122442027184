import React, {useEffect, useRef, useState} from "../../../_snowpack/pkg/react.js";
import {MdAdd, MdRemove} from "../../../_snowpack/pkg/react-icons/md.js";
import {useLocation} from "../../../_snowpack/pkg/react-router-dom.js";
import {cssRule, Heading, Text} from "../index.js";
const faqCard = cssRule({
  minWidth: "320px",
  backgroundColor: "var(--ockb-teal-light)"
});
const sharedPadding = "var(--s2)";
const iconSize = "1.5em";
const heading = cssRule({
  position: "relative",
  padding: sharedPadding,
  paddingRight: `calc(${sharedPadding} + ${iconSize})`,
  lineWrap: "normal",
  cursor: "pointer"
});
const count = cssRule({
  marginRight: "var(--s-3)",
  color: "var(--ockb-text-light)"
});
const iconHolder = cssRule({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "0",
  right: sharedPadding,
  height: "100%"
});
const iconToggle = cssRule({
  width: iconSize,
  height: "auto",
  color: "var(--sw-blauw)"
});
const answer = cssRule({
  padding: sharedPadding
});
export const FAQ = ({number, faq}) => {
  const [isFolded, setIsFolded] = useState(true);
  const location = useLocation();
  const ref = useRef(null);
  const faqQuery = new URLSearchParams(location.search).get("faq");
  useEffect(() => {
    if (!faqQuery || faq.title !== faqQuery || !ref.current)
      return;
    setIsFolded(false);
    ref.current.scrollIntoView({block: "center"});
  }, [faqQuery, faq, ref]);
  return /* @__PURE__ */ React.createElement("div", {
    className: faqCard,
    ref
  }, /* @__PURE__ */ React.createElement(Heading, {
    className: heading,
    level: "4",
    onClick: () => setIsFolded(!isFolded)
  }, /* @__PURE__ */ React.createElement("span", {
    className: count
  }, number, "."), faq.title, /* @__PURE__ */ React.createElement("span", {
    className: iconHolder
  }, isFolded ? /* @__PURE__ */ React.createElement(MdAdd, {
    className: iconToggle,
    viewBox: "0 0 24 24"
  }) : /* @__PURE__ */ React.createElement(MdRemove, {
    className: iconToggle,
    viewBox: "0 0 24 24"
  }))), !isFolded && /* @__PURE__ */ React.createElement(Text, {
    className: answer,
    ektronHTML: faq.html
  }));
};
