import React from "../../_snowpack/pkg/react.js";
import {Helmet} from "../../_snowpack/pkg/react-helmet.js";
import {
  BannerPrimary,
  BannerSecondary,
  Breadcrumbs,
  CourseList,
  DefaultError,
  DefaultLoading,
  FeaturedCourses,
  Section,
  ZoekButton
} from "../components/index.js";
import {UserType} from "../constants/ektron.js";
import {coursesURL} from "../constants/routing.js";
import {useContentPageData} from "../resources/ockb/index.js";
import {useUserType} from "../state/index.js";
import {useCourseFilterState} from "../state/course-filter.js";
import {getBackgroundColor} from "../utils/getBackgroundColor.js";
export const CoursesPage = () => {
  const {text, setText} = useCourseFilterState((s) => ({
    text: s.text,
    setText: s.setText
  }));
  const {data, error} = useContentPageData();
  const isParticipant = useUserType() === UserType.Participant;
  if (error)
    /* @__PURE__ */ React.createElement(DefaultError, null);
  if (!data)
    return /* @__PURE__ */ React.createElement(DefaultLoading, null);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, "Cursusoverzicht | SeniorWeb cursussen")), /* @__PURE__ */ React.createElement(Section, {
    color: "white"
  }, /* @__PURE__ */ React.createElement(BannerPrimary, {
    source: data.courses.intro
  }, /* @__PURE__ */ React.createElement(ZoekButton, {
    placeholder: "Wat wil je leren?",
    text,
    setText
  }))), /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, /* @__PURE__ */ React.createElement(Breadcrumbs, {
    crumbs: [
      {name: "Home", path: "/"},
      {name: "Cursussen", path: coursesURL()}
    ]
  })), /* @__PURE__ */ React.createElement(Section, {
    color: "light"
  }, isParticipant && text === "" && /* @__PURE__ */ React.createElement("div", {
    style: {marginBottom: "var(--smax)"}
  }, /* @__PURE__ */ React.createElement(FeaturedCourses, null)), /* @__PURE__ */ React.createElement(CourseList, null)), /* @__PURE__ */ React.createElement(Section, {
    color: getBackgroundColor(data.courses.footerCTA.background)
  }, /* @__PURE__ */ React.createElement(BannerSecondary, {
    source: data.courses.footerCTA
  })));
};
